/* global alert */
import { observable, computed, action } from 'mobx';
import DynamicStructure from '../../shared/DynamicStructure';
import DataSource, { STATES } from '../../shared/DataSource';
import { ObjectService } from '../../../services/operator';
import RelatedObjectList from './RelatedObjectList';
import {
    FlatStrategy,
    HouseStrategy,
    SteadStrategy,
    CommercialStrategy,
} from '../../shared/RefactoredObjectList/strategy';
import {
    CommercialDetailed,
    FlatDetailed,
    HouseDetailed,
    SteadDetailed,
} from '../RefactoredObjectDetailed';
import { withSaleDetailed, withRentDetailed } from '../../shared/RefactoredObjectDetailed/mixins';
import { withParserDetailed } from '../RefactoredObjectDetailed/mixins';
import { DEAL_TYPE, OBJECT_TYPE } from '../../../constants/shared';
import { withParserStrategy } from './strategy/mixins';

class Related extends DynamicStructure {
    @observable $id;

    @observable $phone;

    @observable $availableObjectTypes = [];

    @observable $activeType = '';

    @observable $list;

    constructor(id, phone, requestType) {
        super();
        this.dataSource = new DataSource(ObjectService, STATES.INITIALLY_LOADING);
        this.requestType = requestType;
        this.setData({ id, phone });
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    @computed
    get availableObjectTypes() {
        const { $availableObjectTypes } = this;
        return $availableObjectTypes;
    }

    @computed
    get activeType() {
        const { $activeType } = this;
        return $activeType;
    }

    @computed
    get list() {
        const { $list } = this;
        return $list;
    }

    load() {
        const { dataSource, $id: id, $phone: phone } = this;
        return dataSource.getRelatedGroups(id, phone)
            .then((objectTypes) => {
                this.setAvailableObjectTypes(objectTypes);
            })
            .catch((e) => {
                alert(e);
            });
    }

    @action
    setAvailableObjectTypes(types) {
        if (!types || !types.length) {
            return;
        }
        this.$availableObjectTypes = types;
        this.setActiveTab(types[0]);
    }

    @action
    setActiveTab(type) {
        const { $id: id, $phone: phone, requestType } = this;
        let Strategy;
        switch (type) {
            case OBJECT_TYPE.FLAT:
                Strategy = withParserStrategy(FlatStrategy);
                break;
            case OBJECT_TYPE.HOUSE:
                Strategy = withParserStrategy(HouseStrategy);
                break;
            case OBJECT_TYPE.STEAD:
                Strategy = withParserStrategy(SteadStrategy);
                break;
            case OBJECT_TYPE.COMMERCIAL:
                Strategy = withParserStrategy(CommercialStrategy);
                break;
            default:
                throw new Error(`Unexpected object type: ${type}`);
        }
        this.$activeType = type;
        this.$list = new RelatedObjectList(new Strategy(), id, phone, requestType);
        this.$list.load();
    }

    createDetailed(dealType) {
        const { $activeType: type } = this;
        let DetailedObjectType;
        let withDealType;
        switch (type) {
            case OBJECT_TYPE.FLAT:
                DetailedObjectType = FlatDetailed;
                break;
            case OBJECT_TYPE.HOUSE:
                DetailedObjectType = HouseDetailed;
                break;
            case OBJECT_TYPE.STEAD:
                DetailedObjectType = SteadDetailed;
                break;
            case OBJECT_TYPE.COMMERCIAL:
                DetailedObjectType = CommercialDetailed;
                break;
            default:
                throw new Error(`Unexpected object type: ${type}`);
        }
        switch (dealType) {
            case DEAL_TYPE.RENT:
                withDealType = withRentDetailed;
                break;
            case DEAL_TYPE.SALE:
                withDealType = withSaleDetailed;
                break;
            default:
                throw new Error(`Unexpected deal type: ${dealType}`);
        }
        const Detailed = withParserDetailed(withDealType(DetailedObjectType));
        return new Detailed();
    }
}

export default Related;
