import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStores from '../../../hooks/operator/use-stores';
import ObjectListPage from '../../../stores/shared/ObjectListPage';
import PresetList from '../../../stores/operator/PresetList';
import storesContext from '../../../contexts/operator';
import { PagesRootNamesMap, PagesRouteInfo } from '../../../routes/utils/pagesRouteInfo';

const ObjectListWrapper = ({ children, ...props }) => {
    const { routes: [{ extra: { requestType } }] } = props;
    const [stores, setStores] = useState(useStores());
    useEffect(() => {
        const presetListStore = requestType === PagesRouteInfo.Base.RequestType
            ? new PresetList()
            : null;
        const baseHref = PagesRootNamesMap.get(requestType);
        const objectListPageStore = new ObjectListPage(baseHref);

        setStores({
            ...stores,
            presetListStore,
            objectListPageStore,
        });
    }, []);
    return (
        <storesContext.Provider value={stores}>
            {children}
        </storesContext.Provider>
    );
};

ObjectListWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
    ]).isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape),
};

ObjectListWrapper.defaultProps = {
    routes: [],
};

export default ObjectListWrapper;
