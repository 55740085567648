import React, { forwardRef } from 'react';
import { Table } from '../../shared/Table';
import useStores from '../../../hooks/operator/use-stores';

const TableWithStore = forwardRef((props, ref) => (
    <Table
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
        useStores={useStores}
    />
));

export default TableWithStore;
