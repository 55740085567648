import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import {
    SelectedHighlight,
    SelectedRow,
    SelectedTitle,
    SelectedWrapper,
} from '../../../components/TableSelected';
import { IconButton } from '../../../components/Button';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { DoubleArrowGreenIcon, UserBlueIcon, BanIcon } from '../../../components/Icons/icons';

import styles from './BillingCompaniesList.module.scss';

const BillingCompanySelected = ({
    store,
    style,
    onClick,
    onSubscriptionClick,
}) => {
    const handleClick = () => {
        onClick({ original: { id: store.id } });
    };
    const handleSubscriptionClick = () => {
        onSubscriptionClick();
    };

    return (
        <Observer>
            {() => (
                <SelectedWrapper style={style} loading={store.loading}>
                    <Observer>
                        {() => (
                            <div key={store.memoKey}>
                                <SelectedTitle>
                                    Инофрмация по компании
                                    &nbsp;
                                    <SelectedHighlight color={SelectedHighlight.COLOR.GREEN}>
                                        {store.name}
                                    </SelectedHighlight>
                                    <IconButton
                                        className={styles.selected__detailed}
                                        color={IconButton.COLOR.WHITE_BLUE}
                                        size={IconButton.SIZE.SMALL}
                                        icon={<SVGIcon SVGElement={UserBlueIcon} />}
                                        onClick={handleClick}
                                    >
                                        Полный профиль
                                    </IconButton>
                                    <span className={`${styles.selected__ban} ${store.statusKey === 'banned' && styles['selected__ban--banned']}`}>
                                        <SVGIcon
                                            SVGElement={BanIcon}
                                        />
                                    </span>
                                </SelectedTitle>
                                <div className={styles.selected}>
                                    <div className={styles.selected__col}>
                                        <SelectedRow name="Название фирмы" classNames={{ row: styles.selected__row }}>
                                            {store.name}
                                        </SelectedRow>
                                        <SelectedRow name="Зарегистрирована" classNames={{ row: styles.selected__row }}>
                                            {store.registeredAt}
                                        </SelectedRow>
                                        <SelectedRow name="Почта" classNames={{ row: styles.selected__row }}>
                                            <a href={`mailto:${store.email}`} className={styles.selected__email}>
                                                {store.email}
                                            </a>
                                        </SelectedRow>
                                        <SelectedRow name="Адрес" classNames={{ row: styles.selected__row }}>
                                            {store.address}
                                        </SelectedRow>
                                    </div>
                                    <div className={`${styles.selected__col} ${styles['selected__col--wide']}`}>
                                        <SelectedRow
                                            name="ИНН"
                                            classNames={{
                                                row: `${styles.selected__row} ${styles['selected__row--wide']}`,
                                                name: styles['selected__name--wide'],
                                                value: styles['selected__value--wide'],
                                            }}
                                        >
                                            {store.inn}
                                        </SelectedRow>
                                        <SelectedRow
                                            name="Комментарий"
                                            classNames={{
                                                row: `${styles.selected__row} ${styles['selected__row--wide']}`,
                                                name: styles['selected__name--wide'],
                                                value: `${styles['selected__value--wide']} ${styles['selected__value--comment']}`,
                                            }}
                                        >
                                            {store.note}
                                        </SelectedRow>
                                    </div>
                                    <div className={`${styles.selected__col} ${styles['selected__col--action']}`}>
                                        <IconButton
                                            className={styles.selected__approve}
                                            color={IconButton.COLOR.WHITE_GREEN}
                                            size={IconButton.SIZE.MEDIUM}
                                            icon={<SVGIcon SVGElement={DoubleArrowGreenIcon} />}
                                            onClick={handleSubscriptionClick}
                                        >
                                            Продлить подписку
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Observer>
                </SelectedWrapper>
            )}
        </Observer>
    );
};

BillingCompanySelected.propTypes = {
    store: PropTypes.shape({
        memoKey: PropTypes.number,
        loading: PropTypes.bool,
        id: PropTypes.number,
        name: PropTypes.string,
        address: PropTypes.string,
        email: PropTypes.string,
        inn: PropTypes.string,
        note: PropTypes.string,
        registeredAt: PropTypes.string,
        statusKey: PropTypes.string,
    }).isRequired,
    style: PropTypes.shape({}),
    onClick: PropTypes.func,
    onSubscriptionClick: PropTypes.func,
};

BillingCompanySelected.defaultProps = {
    style: {},
    onClick: () => null,
    onSubscriptionClick: () => null,
};

export default BillingCompanySelected;
