/* global alert */
import { computed, observable } from 'mobx';
import createForm from '../../../helpers/Form';
import { BillingService } from '../../../services/operator';
import DataSource from '../../shared/DataSource';
import { subscribe } from '../../../forms/operator';

class BillingSubscribe {
    id;

    @observable
    days;

    @observable
    form;

    constructor(id) {
        this.id = id;
        this.dataSource = new DataSource(BillingService);
        this.createForm();
        this.updateMinPayment();
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    createForm() {
        this.form = createForm(subscribe);
        this.initFormListeners();
    }

    initFormListeners() {
        const { form } = this;
        const amountField = form.$('amount');
        let timeoutId;

        amountField.observe(() => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                amountField.validate({ showErrors: true })
                    .then(({ isValid }) => {
                        if (isValid) {
                            this.updateDays();
                        }
                    });
                clearTimeout(timeoutId);
            }, 750);
        });

        form.on('success', this.setPayment.bind(this));
    }

    updateDays() {
        const { id, form } = this;
        const amountField = form.$('amount');
        const sum = amountField.get('value');
        this.dataSource.getSubscriptionDays(id, sum)
            .then(({ days }) => {
                if (days) {
                    amountField.set('extra', { ...amountField.get('extra'), days });
                }
                return days;
            })
            .catch((e) => alert(e.message));
    }

    updateMinPayment() {
        const { form } = this;
        const amountField = form.$('amount');
        this.dataSource.getMinPayment()
            .then((min) => {
                amountField.set('rules', `required|min:${min}`);
            });
    }

    setPayment(values) {
        const { id } = this;
        return this.dataSource.setPayment(id, values)
            .catch((e) => {
                const { response } = e;
                alert(response?.data?.error?.message || e.message);
            });
    }
}

export default BillingSubscribe;
