import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.ROOMS_COUNT,
    width: 5,
    textAlign: 'center',
    sortName: undefined,
}, {
    ...TABLE_CELL.STREET_NAME,
    width: 20,
    textAlign: 'left',
    sortName: undefined,
}, {
    ...TABLE_CELL.HOUSE_NUMBER,
    title: '№',
    width: 11,
    sortName: undefined,
}, {
    ...TABLE_CELL.FLOOR_WITH_FLOOR_COUNT_WITH_WALL_MATERIAL,
    width: 10,
}, {
    ...TABLE_CELL.SQUARE_TOTAL,
    width: 10,
    sortName: undefined,
}, {
    ...TABLE_CELL.CONTACT_PHONE,
    width: 13,
    sortName: undefined,
}, {
    ...TABLE_CELL.IMAGES,
    width: 6,
    sortName: undefined,
}, {
    ...TABLE_CELL.SIMILARITY,
    width: 14,
    textAlign: 'left',
    sortName: undefined,
}, {
    ...TABLE_CELL.OBJECT_STATUS_UPDATE_WITH_CONTACT,
    width: 10,
    textAlign: 'left',
    sortName: undefined,
}, {
    ...TABLE_CELL.ROW_SELECT,
    sortName: null,
}];
