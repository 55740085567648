/* global alert */
import { computed, observable, toJS } from 'mobx';
import { BillingService, StorageService } from '../../../services/operator';
import DataSource from '../../shared/DataSource';
import Pagination from '../../shared/DataSource/entities/Pagination';
import { ListWithDataSource } from '../../shared/List';
import UserItem from './item';
import { billingUsers as billingUsersFields } from '../../../forms/operator';
import createForm from '../../../helpers/Form';
import tableHeaders from './table';
import listWithCursor from '../../shared/List/mixins/listWithCursor';
import isEmpty from '../../../utils/is-empty';

class BillingUserList extends ListWithDataSource {
    filterForm;

    filterData;

    storageName = 'billingUsers';

    @observable
    sortData = {};

    constructor() {
        super(
            UserItem,
            new DataSource(BillingService),
            new Pagination(),
        );
        this.createFilterForm();
        this.restoreSort();
    }

    get $tableHeaders() {
        const { sortData } = this;
        return tableHeaders.map((header) => ({
            ...header,
            sortDirection: sortData.field === header.sortName ? sortData.direction : undefined,
        }));
    }

    @computed
    get tableHeaders() {
        return this.$tableHeaders;
    }

    @computed
    get tableData() {
        return this.list;
    }

    @computed
    get loading() {
        return this.dataSource.loading;
    }

    getList(page = 1, filter) {
        return this.dataSource.getUsers(filter, page);
    }

    load(page = 1) {
        return super.load(page, this.prepareFilterData())
            .then((list) => {
                this.setList(list);
                return Promise.resolve();
            })
            .catch((e) => alert(e));
    }

    createFilterForm() {
        const { filterForm } = this;
        if (filterForm) {
            return filterForm;
        }
        this.filterForm = createForm(billingUsersFields);
        this.setFilterData(this.filterForm.clearValues(this.filterForm.values()));
        this.initFilterFormListeners();
        return this.filterForm;
    }

    setFilterData(filterData) {
        this.filterData = filterData;
    }

    prepareFilterData() {
        const { filterData: { status, companyIds, ...filterData }, sortData } = this;
        const preparedFilterData = {
            companyIds: companyIds ? [companyIds] : [],
            status: status || [],
        };
        if (!isEmpty(sortData)) {
            const { field, direction } = sortData;
            return {
                ...filterData,
                ...preparedFilterData,
                sort: [{ name: field, order: direction }],
            };
        }
        return { ...filterData, ...preparedFilterData };
    }

    initFilterFormListeners() {
        const { filterForm } = this;
        if (!filterForm) {
            return;
        }
        filterForm.on('success', (values) => {
            this.setFilterData(filterForm.clearValues(values));
            return this.load();
        });
    }

    approveFilterData() {
        const { filterForm } = this;
        if (!filterForm) {
            return;
        }
        this.setFilterData(filterForm.clearValues(filterForm.values()));
    }

    storeSort() {
        const { sortData, storageName } = this;
        StorageService.add('sortData', { [storageName]: toJS(sortData) });
    }

    restoreSort() {
        const { storageName } = this;
        const savedSortData = StorageService('sortData');
        if (savedSortData && savedSortData[storageName] && !isEmpty(savedSortData[storageName])) {
            const { field, direction } = savedSortData[storageName];
            this.setSort(field, direction);
        }
    }

    setSort(field, direction) {
        if (direction === null) {
            this.sortData = {};
        } else {
            this.sortData = { field, direction };
        }
        this.storeSort();
    }
}

export default listWithCursor(BillingUserList);
