import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStores from '../../../hooks/operator/use-stores';
import BillingStats from '../../../stores/operator/BillingStats';
import storesContext from '../../../contexts/operator';

const BillingWrapper = ({ children }) => {
    const [stores, setStores] = useState(useStores());
    useEffect(() => {
        const billingStatsStore = new BillingStats();
        billingStatsStore.load();

        setStores({
            ...stores,
            billingStatsStore,
        });
    }, []);
    return (
        <storesContext.Provider value={stores}>
            {children}
        </storesContext.Provider>
    );
};

BillingWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
    ]).isRequired,
};

export default BillingWrapper;
