/* global alert */
import { computed, observable, runInAction } from 'mobx';
import { format } from 'date-fns';
import createForm from '../../../../helpers/Form';
import extraAdsFormFields from '../../../../forms/operator/objects-detailed/extra-ads';
import { EXCLUSIVE_OBJECT_CONTENT_MAP, OBJECT_CONTENT_MAP, DEFAULT_DATE_FORMAT } from '../../../../constants/operator';

const withOperator = (Store) => (
    class extends Store {
        @observable $user;

        @observable $extraAds = [];

        $objectContentMap = OBJECT_CONTENT_MAP;

        $exclusiveObjectContentMap = EXCLUSIVE_OBJECT_CONTENT_MAP;

        extraAdsForm;

        constructor(...rest) {
            super(...rest);
            const extraAdsForm = createForm(extraAdsFormFields);
            extraAdsForm.on('success', ({ ad: url }) => {
                runInAction(() => {
                    if (!url) {
                        return;
                    }
                    const id = `extra_${Math.random().toString(36).substring(2)}`;
                    this.$extraAds.push({ id, url: url.trim() });
                    extraAdsForm.clear();
                    extraAdsForm.$('ad').resetValidation();
                });
            });
            this.extraAdsForm = extraAdsForm;
        }

        @computed
        get objectContentMap() {
            const { isNew, isExclusive, requestType } = this;
            let contentMap = isExclusive
                ? this.$exclusiveObjectContentMap
                : this.$objectContentMap;
            if (requestType === 'parser' || isNew) {
                contentMap = contentMap.filter((item) => item.name !== 'incorrect');
            }
            return contentMap;
        }

        @computed
        get user() {
            const { $user, company } = this;
            return $user
                ? { value: $user.id, label: `${company}: ${$user.login}` }
                : null;
        }

        @computed
        get extraAds() {
            const { $extraAds } = this;
            return $extraAds || [];
        }

        @computed
        get sources() {
            const { $ads, $extraAds } = this;
            if (!$ads?.length && !$extraAds.length) {
                return [];
            }
            return [...$ads, ...$extraAds].filter((e) => e.id).map(({
                id,
                board,
                url,
                createdAt,
                updatedAt,
            }) => ({
                id,
                src: board && board.icon,
                name: (board && board.name) || new URL(url).hostname,
                url,
                posted: createdAt ? format(new Date(createdAt), DEFAULT_DATE_FORMAT) : '-/-',
                updated: updatedAt ? format(new Date(updatedAt), DEFAULT_DATE_FORMAT) : '-/-',
            }));
        }

        prepareRequest({ user, ...values }) {
            const { $extraAds } = this;
            const prepared = super.prepareRequest(values);
            return {
                ...prepared,
                ...($extraAds?.length ? { ads: $extraAds } : {}),
                userId: user,
            };
        }

        deleteAd(adId) {
            const { objectType } = this;
            const isExtra = adId.indexOf('extra_') === 0;
            return (isExtra ? Promise.resolve() : this.dataSource.deleteAd(
                this.id,
                adId,
                { objectType },
            ))
                .then(() => {
                    if (isExtra) {
                        const index = this.$extraAds.findIndex((x) => x.id === adId);
                        this.$extraAds.splice(index, 1);
                    } else {
                        const index = this.$ads.findIndex((x) => x.id === adId);
                        this.$ads.splice(index, 1);
                    }
                    return true;
                })
                .catch((e) => {
                    alert(e);
                    return false;
                });
        }
    }
);

export default withOperator;
