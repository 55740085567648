import { computed } from 'mobx';
import Pagination from './Pagination';

class PaginationAsCursor extends Pagination {
    @computed
    get isEnd() {
        return this.currentPage === this.totalPages;
    }

    prepareRequest(...request) {
        const { currentPage: page } = this;
        return Promise.resolve([(page || 0) + 1, ...request]);
    }
}

export default PaginationAsCursor;
