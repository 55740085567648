import { computed } from 'mobx';
import BaseItem from './BaseItem';

class CityDistrictItem extends BaseItem {
    @computed
    get cityName() {
        const { $city: $val } = this;
        return $val
            ? `${$val.cityType.shortname} ${$val.name}`
            : null;
    }

    @computed
    get cityId() {
        const { $city: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }
}

export default CityDistrictItem;
