import BaseStrategy from './BaseStrategy';
import { CityItem } from '../item';
import { DIRECTORIES } from '../../../../constants/operator';

class CityStrategy extends BaseStrategy {
    constructor(options) {
        super(DIRECTORIES.CITY, options);
        this.Item = CityItem;
    }

    // eslint-disable-next-line class-methods-use-this
    prepareItemData(data) {
        const modified = data;
        if (modified.areaId && modified.regionId) {
            delete modified.regionId;
        }
        return modified;
    }
}

export default CityStrategy;
