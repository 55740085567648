import { TABLE_CELL } from '../../../../constants/operator';

const tableHeaders = [
    TABLE_CELL.ROW_SELECT,
    {
        ...TABLE_CELL.CONTACT_PHONE,
        width: 30,
        textAlign: 'left',

    }, {
        ...TABLE_CELL.AGENCY_NAME,
        textAlign: 'left',
    },
];

export default tableHeaders;
