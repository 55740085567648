import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.HARDWARE_NAME,
    width: 20,
    textAlign: 'left',
}, {
    ...TABLE_CELL.ACTIVATION_KEY_VALUE,
    width: 26,
    name: 'activationKey',
    textAlign: 'left',
}, {
    ...TABLE_CELL.USER_REGISTERED,
    width: 16,
    textAlign: 'left',
}, {
    ...TABLE_CELL.BILLING_STATUS,
    width: 20,
    name: 'statusName',
    textAlign: 'left',
}, {
    ...TABLE_CELL.USER_LAST_LOGIN,
    textAlign: 'left',
}];
