import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.ACTIVATION_KEY_VALUE,
    width: 30,
    textAlign: 'left',
}, {
    ...TABLE_CELL.ACTIVATION_KEY_MAX_COMPANY_COUNT,
    width: 10,
    textAlign: 'left',
}, {
    ...TABLE_CELL.ACTIVATION_KEY_MAX_USER_COUNT,
    width: 10,
    textAlign: 'left',
}, {
    ...TABLE_CELL.BILLING_COMPANY_NAME,
    width: 24,
    textAlign: 'left',
}, {
    ...TABLE_CELL.CREATED,
    title: 'Создан',
    width: 13,
    textAlign: 'left',
}, {
    ...TABLE_CELL.ACTIVATION_KEY_STATUS_VALUE,
    textAlign: 'left',
}];
