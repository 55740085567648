import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import Directory from '../../../stores/operator/Directory/Directory';
import PageView from '../../shared/PageView';
import Filter from './DirectoryFilter';
import useStores from '../../../hooks/operator/use-stores';
import { IconButton } from '../../../components/Button';
import { CrossIcon, PlusIcon } from '../../../components/Icons/icons';
import SVGIcon from '../../../components/Icons/SVGIcon';

import styles from './DirectoryPage.module.scss';

const DirectoryPage = ({
    store,
    children,
    meta,
    onCreateNewClick,
    onRemoveClick,
}) => {
    const { headerActionsStore, headerToolsStore } = useStores();
    useEffect(() => {
        headerToolsStore.setTarget(null);
        headerActionsStore.setActions(null);
    }, []);
    return (
        <PageView
            meta={meta}
        >
            {({ setCollapsed, collapsed }) => (
                <>
                    <Observer>
                        {() => (
                            store.filterForm
                                ? (
                                    <Filter
                                        filterForm={store.filterForm}
                                        title="Поиск"
                                        onCollapse={setCollapsed}
                                    />
                                )
                                : null
                        )}
                    </Observer>
                    {
                        collapsed
                            ? null
                            : (
                                <div className={styles['directory-action-list']}>
                                    <div className={styles['directory-action']}>
                                        <IconButton
                                            size={IconButton.SIZE.MEDIUM}
                                            color={IconButton.COLOR.GREEN}
                                            icon={(
                                                <SVGIcon
                                                    SVGElement={PlusIcon}
                                                />
                                            )}
                                            block
                                            onClick={onCreateNewClick}
                                            disabled={store.strategy?.hideCreate}
                                        >
                                            Добавить
                                        </IconButton>
                                    </div>
                                    <div className={styles['directory-action']}>
                                        <Observer>
                                            {() => (
                                                <IconButton
                                                    size={IconButton.SIZE.MEDIUM}
                                                    color={IconButton.COLOR.WHITE_RED}
                                                    icon={(
                                                        <SVGIcon
                                                            SVGElement={CrossIcon}
                                                        />
                                                    )}
                                                    block
                                                    onClick={onRemoveClick}
                                                    disabled={!store.selected.length}
                                                >
                                                    Удалить
                                                </IconButton>
                                            )}
                                        </Observer>
                                    </div>
                                </div>
                            )
                    }
                </>
            )}
            <div className={styles['page-view__wrapper']}>
                {children}
            </div>
        </PageView>
    );
};

DirectoryPage.propTypes = {
    store: PropTypes.instanceOf(Directory).isRequired,
    meta: PropTypes.shape({
        pageTitle: PropTypes.string,
        icon: PropTypes.func,
        sisterRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
        parentRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
    }),
    onCreateNewClick: PropTypes.func,
    onRemoveClick: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]).isRequired,
};

DirectoryPage.defaultProps = {
    meta: {
        title: null,
        icon: null,
        sisterRoutes: [],
        parentRoutes: [],
    },
    onCreateNewClick: () => null,
    onRemoveClick: () => null,
};

export default DirectoryPage;
