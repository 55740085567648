import React from 'react';
import PropTypes from 'prop-types';
import Preloader from '../../../components/Preloader';

import styles from './BillingStats.module.scss';

const BillingStatsItem = ({
    type,
    value,
    loading,
    onClick,
}) => {
    const { key, name } = type;
    return (
        <div className={styles['billing-stats__item-wrapper']}>
            <button
                type="button"
                onClick={onClick}
                className={`
                    ${styles['billing-stats__item']}
                    ${styles[`billing-stats__item--${key}`]}
                `}
            >
                <div className={styles['billing-stats__label']}>
                    {name}
                </div>
                <div className={styles['billing-stats__value']}>
                    {
                        loading
                            ? (
                                <div className={styles['billing-stats__preloader']}>
                                    <Preloader width={33} />
                                </div>
                            )
                            : value
                    }
                </div>
            </button>
        </div>
    );
};

BillingStatsItem.TYPE = {
    ACTIVE: {
        key: 'active',
        name: 'Активно компаний',
    },
    EXPIRING: {
        key: 'expiring',
        name: 'Скоро истечет',
    },
    UNPAID: {
        key: 'unpaid',
        name: 'Не оплачено',
    },
};

BillingStatsItem.propTypes = {
    type: PropTypes.shape({ key: PropTypes.string, name: PropTypes.string }),
    value: PropTypes.number,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
};

BillingStatsItem.defaultProps = {
    type: BillingStatsItem.TYPE.ACTIVE,
    value: 0,
    onClick: () => null,
    loading: true,
};

export default BillingStatsItem;
