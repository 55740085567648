import { computed } from 'mobx';
import SimilarObjectList from '../../RefactoredObjectList/SimilarObjectList';
import BaseStrategy, {
    FlatStrategy,
    HouseStrategy,
    SteadStrategy,
    CommercialStrategy,
} from '../../../shared/RefactoredObjectList/strategy';
import {
    withSaleStrategy,
    withRentStrategy,
} from '../../../shared/RefactoredObjectList/strategy/mixins';
import { withParserStrategy } from '../../RefactoredObjectList/strategy/mixins';

const withSimilarObjects = (Store) => (
    class extends Store {
        similar;

        @computed
        get hasSimilarObjects() {
            const { similar } = this;
            return similar && similar.list.length > 0;
        }

        load(id, withSimilar = true) {
            const result = super.load(id);
            if (withSimilar) {
                this.initializeSimilarObjectList()
                    .catch(() => null);
            }
            return result;
        }

        initializeSimilarObjectList() {
            const {
                id,
                objectType,
                dealType,
                requestType,
            } = this;
            let Strategy;
            let withDealTypeStrategy;
            switch (objectType) {
                case 'flats':
                    Strategy = FlatStrategy;
                    break;
                case 'houses':
                    Strategy = HouseStrategy;
                    break;
                case 'steads':
                    Strategy = SteadStrategy;
                    break;
                case 'commercial':
                    Strategy = CommercialStrategy;
                    break;
                default:
                    Strategy = BaseStrategy;
            }
            switch (dealType) {
                case 'sale':
                    withDealTypeStrategy = withSaleStrategy;
                    break;
                case 'rent':
                default:
                    withDealTypeStrategy = withRentStrategy;
            }
            Strategy = withParserStrategy(withDealTypeStrategy(Strategy));
            this.similar = new SimilarObjectList(new Strategy(), id, this, requestType);
            return this.similar.load();
        }
    }
);

export default withSimilarObjects;
