import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';
import { POSITION } from '../../../constants/shared';

import styles from './BillingUserDetailed.module.scss';

const DetailedViewField = ({
    name,
    label,
    id,
    type,
    hidden,
    children,
    tooltip,
    form,
}) => {
    if (hidden) return null;
    const { thisMonth } = form.$(name).get('extra');
    return (
        <div className={`${styles.field} ${styles[`field--${name}`]} ${styles[`field--${type}`]}`}>
            {
                label && (
                    <Label
                        className={styles['field-label']}
                        htmlFor={id}
                    >
                        {
                            tooltip
                                ? (
                                    <div
                                        className={styles.tooltip}
                                        data-tip={tooltip}
                                        data-place={POSITION.RIGHT}
                                        data-class={styles.tooltip__content}
                                        data-multiline
                                    >
                                        ?
                                    </div>
                                )
                                : null
                        }
                        {label}
                    </Label>
                )
            }
            {
                typeof thisMonth !== 'undefined'
                    ? (
                        <>
                            {children}
                            <div className={styles.devices}>
                                В этом месяце
                                {' '}
                                {thisMonth}
                            </div>
                        </>
                    )
                    : children
            }
        </div>
    );
};

DetailedViewField.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    hidden: PropTypes.bool,
    children: PropTypes.element.isRequired,
    tooltip: PropTypes.string,
    form: PropTypes.shape({
        $: PropTypes.func,
    }),
};

DetailedViewField.defaultProps = {
    type: '',
    name: '',
    label: '',
    hidden: false,
    tooltip: '',
    form: {
        $: () => null,
    },
};

export default DetailedViewField;
