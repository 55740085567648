/* global alert */
import { computed, observable, toJS } from 'mobx';
import { OperatorService } from '../../../services/operator';
import DataSource from '../../shared/DataSource';
import Pagination from '../../shared/DataSource/entities/Pagination';
import { ListWithDataSource } from '../../shared/List';
import listWithCursor from '../../shared/List/mixins/listWithCursor';
import OperatorItem from './item';
import { operatorList } from '../../../forms/operator';
import createForm from '../../../helpers/Form';
import tableHeaders from './table';

class OperatorList extends ListWithDataSource {
    filterForm;

    filterData;

    @observable
    sortData = {};

    constructor() {
        super(
            OperatorItem,
            new DataSource(OperatorService),
            new Pagination(),
        );
        this.createFilterForm();
    }

    @computed
    get tableHeaders() {
        const {
            sortData,
        } = this;
        const headers = tableHeaders;
        return headers.map((header) => ({
            ...header,
            sortDirection: sortData[header.sortName],
        }));
    }

    @computed
    get tableData() {
        return this.list;
    }

    // eslint-disable-next-line class-methods-use-this
    get sortOrder() {
        return [];
    }

    getList(page = 1, filter) {
        return this.dataSource.getAll(filter, page);
    }

    load(page = 1) {
        return super.load(page, this.prepareFilterData())
            .then((list) => {
                this.setList(list);
                return Promise.resolve();
            })
            .catch((e) => alert(e));
    }

    ban(id) {
        const operator = this.getItem(id);
        return this.dataSource.banOperator(id)
            .then((response) => {
                operator.setData(response);
            })
            .catch((e) => alert(e));
    }

    createFilterForm() {
        const { filterForm } = this;
        if (filterForm) {
            return filterForm;
        }
        this.filterForm = createForm(operatorList);
        this.setFilterData(this.filterForm.clearValues(this.filterForm.values()));
        this.initFilterFormListeners();
        return this.filterForm;
    }

    setFilterData(filterData) {
        this.filterData = filterData;
    }

    setSort(field, direction) {
        const { sortData, filterForm } = this;
        sortData[field] = direction;
        this.sortData = filterForm.clearValues(sortData);
    }

    prepareFilterData() {
        const { filterData: { statusIds, ...filterData }, sortData } = this;
        const sort = Object.keys(sortData).map((key) => ({
            name: key,
            order: sortData[key],
        }));
        return {
            ...filterData,
            status: statusIds ? statusIds.map((statusName) => statusName) : [],
            sort,
        };
    }

    initFilterFormListeners() {
        const { filterForm } = this;
        if (!filterForm) return;
        filterForm.on('success', (values) => {
            this.setFilterData(filterForm.clearValues(values));
            return this.load();
        });
        const statusFormField = filterForm.$('statusIds');
        statusFormField.observe(({ change }) => {
            const { newValue } = change;
            const newValueJS = toJS(newValue);
            const extra = statusFormField.get('extra');
            if (newValueJS.length === 0) {
                statusFormField.set('value', extra.options);
            }
        });
    }
}

export default listWithCursor(OperatorList);
