import hightlightColor from './highlight-color';
import { DIRECTORIES } from '../../../constants/operator';

export default {
    type: 'fieldset',
    name: 'baseStatusGroups',
    fields: [{
        type: 'dropdownSelect',
        name: 'statusIds',
        size: 'sm',
        label: 'Группы',
        multiple: true,
        value: [{
            id: 1,
            value: 'mainGroup',
            label: 'В базе',
        }],
        options: [{
            id: 1,
            value: 'mainGroup',
            label: 'В базе',
            disabled: true,
        }, {
            id: 2,
            value: 'archiveGroup',
            label: 'Архив',
        }, {
            id: 3,
            value: 'inProgressGroup',
            label: 'В обработке',
        }, {
            id: 4,
            value: 'agencyGroup',
            label: 'Агенства',
        }, {
            id: 5,
            value: 'trashGroup',
            label: 'В корзине',
        }],
    }, {
        name: 'objectStatusReasonIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_STATUS_REASON,
        label: 'Причина',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        ...hightlightColor,
        directory: DIRECTORIES.BASE_HIGHLIGHT_COLOR,
    }],
};
