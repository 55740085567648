import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.BILLING_COMPANY_NAME,
    width: 22,
    textAlign: 'left',
}, {
    ...TABLE_CELL.BILLING_COMPANY_ADDRESS,
    width: 14,
    textAlign: 'left',
    sortName: null,
}, {
    ...TABLE_CELL.BILLING_INSTANCES,
    width: 6,
    textAlign: 'left',
    sortName: null,
}, {
    ...TABLE_CELL.BILLING_PRICE,
    width: 8,
    textAlign: 'left',
    sortName: null,
}, {
    ...TABLE_CELL.BILLING_STATUS,
    width: 16,
    textAlign: 'left',
}, {
    ...TABLE_CELL.BILLING_EXPIRES_AT,
    width: 10,
    textAlign: 'left',
}, {
    ...TABLE_CELL.CONTACT_PHONE,
    width: 14,
    textAlign: 'center',
    sortName: null,
}, {
    ...TABLE_CELL.BILLING_USERS,
    textAlign: 'left',
    sortName: null,
}];
