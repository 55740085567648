import React from 'react';
import PropTypes from 'prop-types';
import { EMPTY_DATA_STRING, POSITION } from '../../../constants/shared';

import styles from './BillingCompaniesList.module.scss';
import tableStyles from '../../shared/Table/Table.module.scss';

const BillingCompaniesListInstancesCell = ({ value }) => {
    if (!value) {
        return EMPTY_DATA_STRING;
    }
    const { current, changed } = value;
    return (
        <div
            className={styles.instances}
            data-tip={
                changed
                    ? `
                        <b>Изменилось количество копий</b>
                        <br />
                        Отредактируйте стоимость подписки
                        <br />
                        Чтобы оставить прежнюю стоимость и убрать уведомление – перейдите в редактирование и сохраните прежнюю цену
                    `
                    : null
            }
            data-html
            data-effect="solid"
            data-place={POSITION.RIGHT}
            data-class={`${tableStyles.table__hint} ${styles.instances__hint}`}
        >
            {current}
            {
                changed
                    ? (
                        <>
                            {'\u00a0'}
                            <span
                                className={`
                                    ${styles.instances__changed}
                                    ${styles[`instances__changed--${changed > 0 ? 'positive' : 'negative'}`]}
                                `}
                            >
                                (
                                {changed > 0 ? '+' : ''}
                                {changed}
                                )
                            </span>
                        </>
                    )
                    : null
            }
        </div>
    );
};

BillingCompaniesListInstancesCell.propTypes = {
    value: PropTypes.shape({
        current: PropTypes.number,
        changed: PropTypes.number,
    }).isRequired,
};

export default BillingCompaniesListInstancesCell;
