/* global alert */
import { computed } from 'mobx';
import { BillingService } from '../../../services/operator';
import DataSource from '../../shared/DataSource';
import Pagination from '../../shared/DataSource/entities/Pagination';
import { ListWithDataSource } from '../../shared/List';
import LogItem from './item';
import { paymentsLog as paymentsLogFields } from '../../../forms/operator';
import createForm from '../../../helpers/Form';
import tableHeaders from './table';
import listWithCursor from '../../shared/List/mixins/listWithCursor';

class BillingPaymentsLog extends ListWithDataSource {
    filterForm;

    filterData;

    constructor() {
        super(
            LogItem,
            new DataSource(BillingService),
            new Pagination(),
        );
        this.createFilterForm();
    }

    // eslint-disable-next-line class-methods-use-this
    get tableHeaders() {
        return tableHeaders;
    }

    @computed
    get tableData() {
        return this.list;
    }

    getList(page = 1, filter) {
        return this.dataSource.getPaymentsLog(filter, page);
    }

    load(page = 1) {
        return super.load(page, this.prepareFilterData())
            .then((list) => {
                this.setList(list);
                return Promise.resolve();
            })
            .catch((e) => alert(e));
    }

    createFilterForm() {
        const { filterForm } = this;
        if (filterForm) {
            return filterForm;
        }
        this.filterForm = createForm(paymentsLogFields);
        this.setFilterData(this.filterForm.clearValues(this.filterForm.values()));
        this.initFilterFormListeners();
        return this.filterForm;
    }

    setFilterData(filterData) {
        this.filterData = filterData;
    }

    prepareFilterData() {
        const { filterData } = this;
        return filterData;
    }

    initFilterFormListeners() {
        const { filterForm } = this;
        if (!filterForm) return;
        filterForm.on('success', (values) => {
            this.setFilterData(filterForm.clearValues(values));
            return this.load();
        });
    }
}

export default listWithCursor(BillingPaymentsLog);
