import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from '../../../lib/react-tooltip';
import useStores from '../../../hooks/operator/use-stores';
import ActivationKeyList from '../../../stores/operator/ActivationKeyList';
import ActivationKeyDetailedStore from '../../../stores/operator/ActivationKeyDetailed';
import { Table } from '../Table';
import ActivationKeyListPage from './ActivationKeyListPage';
import ActivationKeyStatus from './ActivationKeyStatus';
import ActivationKeyStatusCell from './ActivationKeyStatusCell';
import ActivationKeyValueCell from './ActivationKeyValueCell';
import ActivationKeyDetailed from '../ActivationKeyDetailed';
import Pagination from '../../../components/Pagination';
import KeyboardHandler from '../../../components/KeyboardHandler';
import { KeyAddIcon } from '../../../components/Icons/icons';
import Dialog from '../Dialog';
import { Button } from '../../../components/Button';
import { SIZE, SORT_ORDER, STATUS_FORM } from '../../../constants/shared';
import { TABLE_CELL } from '../../../constants/operator';

import styles from './ActivationKeyList.module.scss';

const ActivationKeyListContent = ({
    store,
    meta,
}) => {
    const [detailed, setDetailed] = useState(false);
    const listRef = useRef();
    const keyboardRef = useRef();
    const { page } = useParams();
    const history = useHistory();
    const { headerActionsStore, headerToolsStore, dialogStore } = useStores();
    const { link: currentPage } = meta;
    // effects
    useEffect(() => {
        if (store.filterForm) {
            headerActionsStore.setActions([{
                actionText: 'Создать ключ',
                icon: KeyAddIcon,
                actionFunction: () => {
                    const key = new ActivationKeyDetailedStore();
                    const form = key.createForm();
                    setDetailed(key);
                    form.on('done', (response) => {
                        if (!response.filter((res) => Boolean(res)).length) {
                            return;
                        }
                        dialogStore.show('new-activation-key');
                        store.load(page);
                    });
                    dialogStore.show('create-activation-key');
                },
            }]);
            headerToolsStore.setTarget(null);
            return store.filterForm.on('success', () => {
                history.replace(`${currentPage}/1`, { inner: true });
                keyboardRef.current.focus();
            });
        }
        return undefined;
    }, [store]);
    useEffect(() => {
        const { location: { state }, action } = history;
        if (!state || !state.inner || action === 'POP') {
            store.load(page || 1);
            keyboardRef.current.focus();
        }
        return undefined;
    }, [page, store]);
    // Interactions
    const handlePaginationClick = ({ page: nextPage }) => {
        history.push(`${currentPage}/${nextPage}`);
        keyboardRef.current.focus();
    };
    const handleSortChange = ({ sortName, sortDirection }) => {
        let nextDirection;
        switch (sortDirection) {
            case SORT_ORDER.ASC:
                nextDirection = SORT_ORDER.DESC;
                break;
            case SORT_ORDER.DESC:
                nextDirection = null;
                break;
            default:
                nextDirection = SORT_ORDER.ASC;
        }
        store.setSort(sortName, nextDirection);
        store.load(page);
    };
    const handleArrowKey = (direction) => {
        if (direction === -1) {
            store.setPrevCursor();
        } else {
            store.setNextCursor();
        }
        const currentId = store.cursor;
        const index = store.tableData.findIndex(({ id }) => id === currentId);
        listRef.current?.scrollToItem(index);
    };
    const handleDownKey = () => {
        handleArrowKey(1);
    };
    const handleUpKey = () => {
        handleArrowKey(-1);
    };
    const handleEnterKey = () => {
        console.log('enter');
    };
    const handleKeyCreatedClick = () => {
        dialogStore.hide();
    };
    // Table headers
    const modifyHeaders = (headers) => headers.map((header) => {
        switch (header.name) {
            case TABLE_CELL.ACTIVATION_KEY_STATUS_VALUE.name:
                return { ...header, Cell: ActivationKeyStatusCell };
            case TABLE_CELL.ACTIVATION_KEY_VALUE.name:
                return { ...header, Cell: ActivationKeyValueCell };
            default:
                return header;
        }
    });
    // Component
    return (
        <>
            <ActivationKeyListPage
                meta={meta}
                store={store}
            >
                <KeyboardHandler
                    ref={keyboardRef}
                    onDown={handleDownKey}
                    onUp={handleUpKey}
                    onEnter={handleEnterKey}
                >
                    <Observer>
                        {() => (
                            <Table
                                ref={listRef}
                                headers={modifyHeaders(store.tableHeaders)}
                                store={store}
                                onTableHeaderClick={handleSortChange}
                                wrapperStyles={{
                                    height: 'calc(100vh - 126px)',
                                }}
                            />
                        )}
                    </Observer>
                </KeyboardHandler>
                <Observer>
                    {() => {
                        const pagination = store.paginationHandler.data;
                        return (
                            <Pagination
                                totalItems={pagination.totalItems}
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                pageNeighbours={pagination.pageNeighbours}
                                onPageChange={handlePaginationClick}
                                className={styles.pagination}
                            />
                        );
                    }}
                </Observer>
            </ActivationKeyListPage>
            <ReactTooltip
                id={STATUS_FORM}
                clickable
                delayHide={100}
                event="click"
                globalEventOff="click"
                bodyMode
                getContent={(id) => {
                    const key = store.getItem(Number(id));
                    if (!id || !key) {
                        return null;
                    }
                    const statusForm = key.createStatusUpdateForm();
                    return (
                        <Observer>
                            {() => (
                                <ActivationKeyStatus
                                    loading={store.loading}
                                    form={statusForm}
                                />
                            )}
                        </Observer>
                    );
                }}
            />
            <Dialog
                name="create-activation-key"
                className={styles['new-activation-key']}
                size={SIZE.SMALL}
                onClose={() => keyboardRef.current.focus()}
                header={(
                    <div className={styles['new-activation-key__header']}>
                        <div className={styles['new-activation-key__title']}>
                            Создание ключа
                        </div>
                        <div className={styles['new-activation-key__description']}>
                            Нажмите на кнопку ниже, чтобы создать ключ активации продукта
                        </div>
                    </div>
                )}
            >
                <ActivationKeyDetailed
                    store={detailed}
                />
            </Dialog>
            <Dialog
                name="new-activation-key"
                className={styles['new-activation-key']}
                size={SIZE.SMALL}
                onClose={() => keyboardRef.current.focus()}
                header={<>Ключ создан!</>}
            >
                <Observer>
                    {() => (
                        <>
                            <div className={styles['new-activation-key__created']}>
                                Ключ
                                <div className={styles['new-activation-key__value']}>
                                    {detailed ? detailed.key : null}
                                </div>
                            </div>
                            <div className={styles['new-activation-key__submit']}>
                                <Button
                                    type={Button.TYPE.BUTTON}
                                    color={Button.COLOR.GREEN}
                                    size={Button.SIZE.LARGE}
                                    block
                                    onClick={handleKeyCreatedClick}
                                >
                                    Ок
                                </Button>
                            </div>
                        </>
                    )}
                </Observer>
            </Dialog>
        </>
    );
};

ActivationKeyListContent.propTypes = {
    store: PropTypes.oneOfType([
        PropTypes.instanceOf(ActivationKeyList),
        PropTypes.shape({}),
    ]),
    meta: ActivationKeyListPage.propTypes.meta,
};

ActivationKeyListContent.defaultProps = {
    meta: ActivationKeyListPage.defaultProps.meta,
    store: {
        tableHeaders: [],
        tableData: [],
        paginationHandler: { data: {} },
        load: () => null,
        getItem: () => null,
    },
};

export default ActivationKeyListContent;
