import parserFlats from './parser-flats';
import baseFlats from './base-flats';
import parserHouses from './parser-houses';
import baseHouses from './base-houses';
import parserSteads from './parser-steads';
import baseSteads from './base-steads';
import parserCommercial from './parser-commercial';
import baseCommercial from './base-commercial';

export default {
    parserFlats,
    baseFlats,
    baseHouses,
    parserHouses,
    parserSteads,
    baseSteads,
    parserCommercial,
    baseCommercial,
};
