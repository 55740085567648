import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import OperatorList from '../../../stores/operator/OperatorList';
import PageView from '../../shared/PageView';
import Filter from './OperatorListFilter';

import styles from './OperatorList.module.scss';

const OperatorListPage = ({
    store,
    children,
    meta,
}) => (
    <PageView
        meta={meta}
    >
        {({ setCollapsed }) => (
            <>
                <Observer>
                    {() => (
                        store.filterForm
                            ? (
                                <Filter
                                    filterForm={store.filterForm}
                                    title="Найти аккаунт"
                                    onCollapse={setCollapsed}
                                />
                            )
                            : null
                    )}
                </Observer>
            </>
        )}
        <div className={styles['page-view__wrapper']}>
            {children}
        </div>
    </PageView>
);

OperatorListPage.propTypes = {
    store: PropTypes.oneOfType([
        PropTypes.instanceOf(OperatorList),
        PropTypes.shape({}),
    ]).isRequired,
    meta: PropTypes.shape({
        pageTitle: PropTypes.string,
        icon: PropTypes.func,
        sisterRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
        parentRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
    }),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]).isRequired,
};

OperatorListPage.defaultProps = {
    meta: {
        title: null,
        icon: null,
        sisterRoutes: [],
        parentRoutes: [],
    },
};

export default OperatorListPage;
