export default [{
    type: 'fieldset', // ad
    name: 'location',
    fields: [{
        name: 'query',
        type: 'text',
        label: 'Ориентир',
        icon: 'search',
        rules: 'min:3',
    }],
}];
