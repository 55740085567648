import { action, observable } from 'mobx';
import createForm from '../../../../helpers/Form';
import { directory as forms } from '../../../../forms/operator';
import BaseItem from '../item/BaseItem';

class BaseStrategy {
    @observable itemForm;

    filterForm;

    constructor(directoryName, options = {}) {
        this.directoryName = directoryName;
        this.options = options;
        this.Item = BaseItem;
    }

    get filterFormName() {
        const { directoryName } = this;
        return forms[directoryName]
            ? directoryName
            : 'base';
    }

    get itemFormName() {
        const { directoryName } = this;
        const firstLetter = directoryName.charAt(0).toUpperCase();
        const formName = `edit${firstLetter}${directoryName.slice(1)}`;
        return forms[formName]
            ? formName
            : 'editBase';
    }

    toItem(data) {
        const { parent } = this;
        return new parent.Item(data, parent);
    }

    setParent(parent) {
        this.parent = parent;
    }

    setFilterData(filterData) {
        this.filterData = filterData;
    }

    // eslint-disable-next-line class-methods-use-this
    prepareItemData(data) {
        return {
            shortname: '',
            name: '',
            ...data,
        };
    }

    createFilterForm() {
        const { filterFormName, filterForm, options: { localeName } } = this;
        const form = forms[filterFormName];
        if (filterForm) return filterForm;
        this.filterForm = createForm(form);
        if (localeName) {
            this.filterForm.$('query').set('label', localeName);
        }
        return this.filterForm;
    }

    @action
    createItemForm() {
        const {
            itemFormName,
            itemForm,
            options: { localeName, maxNameLength, maxShortNameLength },
        } = this;
        const form = forms[itemFormName];
        if (itemForm) return itemForm;
        this.itemForm = createForm(form);
        const nameField = this.itemForm.has('name') ? this.itemForm.$('name') : null;
        const shortNameField = this.itemForm.has('shortname') ? this.itemForm.$('shortname') : null;
        if (nameField && localeName) {
            nameField.set('label', localeName);
        }
        if (nameField && maxNameLength) {
            nameField.set('rules', `required|max:${maxNameLength}`);
        }
        if (shortNameField && maxShortNameLength) {
            shortNameField.set('extra', {
                ...shortNameField.extra,
                hidden: false,
            });
            shortNameField.set('rules', `max:${maxShortNameLength}`);
        }
        return this.itemForm;
    }
}

export default BaseStrategy;
