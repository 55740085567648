import { TABLE_CELL } from '../../../../constants/operator';

const tableHeaders = [
    TABLE_CELL.ROW_SELECT,
    {
        ...TABLE_CELL.REGION_NAME,
        width: 29,
        textAlign: 'left',
    }, {
        ...TABLE_CELL.AREA_NAME,
        width: 29,
        textAlign: 'left',
    }, {
        ...TABLE_CELL.CITY_NAME,
        width: 29,
        textAlign: 'left',
    }, {
        ...TABLE_CELL.SUBURB_OF_CITY,
        width: 10,
        textAlign: 'left',
    },
];

export default tableHeaders;
