import { ObjectService, StorageService } from '../../../services/operator';
import ExportObjects from '../../shared/ExportObjects';

class OperatorExportObjects extends ExportObjects {
    constructor(list, notepads) {
        super(ObjectService, StorageService, list, notepads);
        this.storagePrefix = 'operator';
    }
}

export default OperatorExportObjects;
