import { DIRECTORIES } from '../../../constants/operator';
import forms from '../../shared/objects-filter';
import statusGroups from './status-groups';
import ownerType from './owner-type';
import incorrectOptions from './incorrect-options';
import { OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT } from '../../../config/operator';

const dateField = OBJECT_FILTER_FOR_BASE_FILTER_BY_ADDED_AT
    ? forms.dateAdded
    : forms.dateCreated;

export default [
    forms.sourceBoard,
    ...forms.houses.slice(0, 1),
    statusGroups,
    incorrectOptions,
    {
        type: 'fieldset',
        name: 'exclusive',
        fields: [{
            name: 'companiesIds',
            type: 'directorySelect',
            directory: DIRECTORIES.COMPANIES,
            multiple: true,
            searchable: true,
            isDropdown: true,
            clearable: true,
            withSelectAll: true,
            label: 'Эксклюзивы',
        }],
    },
    ...ownerType,
    ...forms.houses.slice(1),
    dateField,
    {
        type: 'fieldset', // comment
        name: 'comment',
        fields: [{
            name: 'note',
            type: 'textarea',
            label: 'Комментарий',
            rows: 3,
        }],
    },
];
