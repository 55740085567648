/* eslint linebreak-style: 0 */
import { toJS } from 'mobx';

/**
 * Mixin для логики смены статусов объекта при добавлении номера из справочника агенств
 * @param {Object} props
 * @param {Object} agencySelectorForm форма выбора агенств из справочника
 * @param {number} fallbackStatusValue значение статуса, на которое нужно сбрасывать
 * @returns {Function} функция для расширения класса стора
 */
const withStatusAndAgencySelector = (props) => (Store) => (
    class extends Store {
        constructor(fields) {
            super(fields);
            const {
                agencySelectorForm,
            } = props;
            if (!this.$('statusId') || !this.$('reasonId')) {
                throw new Error('Both status and reason fields must be defined in form');
            }
            if (!agencySelectorForm) {
                throw new Error('agencyForm is required');
            }
            this.props = props;
            this.observeFields();
        }

        observeFields() {
            const form = this;
            const {
                props: {
                    fallbackStatusValue,
                    agencySelectorForm,
                },
            } = this;
            const statusField = form.$('statusId');
            const reasonField = form.$('reasonId');
            const agencyField = agencySelectorForm.$('agency');

            statusField.observe(({ change }) => {
                const { newValue } = change;
                const newValueJS = toJS(newValue);
                const isStatusMultiple = newValueJS instanceof Array;
                const isReasonMultiple = Boolean(reasonField.multiple);
                let statusIds = [];
                if (isStatusMultiple) {
                    statusIds = newValueJS.map(({ value }) => value);
                } else if (newValueJS) {
                    if (newValueJS instanceof Object) {
                        statusIds = [newValueJS.value];
                    } else {
                        statusIds = [newValueJS];
                    }
                }
                reasonField.set('extra', {
                    ...reasonField.extra,
                    ctx: { statusIds },
                });
                if (isReasonMultiple) {
                    reasonField.set(
                        'value',
                        reasonField.value && reasonField.value.filter(({ statusId }) => (
                            statusIds.includes(statusId)
                        )),
                    );
                } else {
                    reasonField.set(
                        'value',
                        reasonField.value && statusIds.includes(reasonField.value.statusId)
                            ? reasonField.value
                            : undefined,
                    );
                }
            });

            agencyField.observe(({ change }) => {
                if (!change.newValue) {
                    return;
                }

                const phones = form.$('phones').value;
                if (phones.some((phone) => phone.phone === change.newValue.extra.phone)) {
                    return;
                }

                if (change.newValue) {
                    form.$('statusId').set(10);
                    const newPhone = {
                        phone: change.newValue.extra.phone,
                        contact: change.newValue.label,
                        isAgent: 1,
                        hide: 0,
                    };
                    form.$('phones').set(phones.concat(newPhone));

                    return;
                }

                form.$('statusId').set(fallbackStatusValue);
            });
        }
    }
);

export default withStatusAndAgencySelector;
