import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import useStores from '../../../hooks/operator/use-stores';
import BillingUsersLog from '../../../stores/operator/BillingUsersLog';
import { Table } from '../Table';
import BillingUsersLogPage from './BillingUsersLogPage';
import Pagination from '../../../components/Pagination';

import styles from './BillingUsersLog.module.scss';

const BillingUsersLogContent = ({
    store,
    meta,
}) => {
    const listRef = useRef();
    const { page } = useParams();
    const history = useHistory();
    const { headerActionsStore, headerToolsStore } = useStores();
    const { link: currentPage } = meta;
    // effects
    useEffect(() => {
        if (store.filterForm) {
            headerActionsStore.setActions(null);
            headerToolsStore.setTarget(null);
            return store.filterForm.on('success', () => {
                history.replace(`${currentPage}/1`, { inner: true });
                return Promise.resolve();
            });
        }
        return undefined;
    }, [store]);
    useEffect(() => {
        const { location: { state }, action } = history;
        if (!state || !state.inner || action === 'POP') {
            store.load(page || 1);
        }
        return undefined;
    }, [page, store]);
    // Interactions
    const handlePaginationClick = ({ page: nextPage }) => (
        history.push(`${currentPage}/${nextPage}`)
    );

    // Component
    return (
        <>
            <BillingUsersLogPage
                meta={meta}
                store={store}
            >
                <Observer>
                    {() => (
                        <Table
                            ref={listRef}
                            store={store}
                            headers={store.tableHeaders}
                            wrapperStyles={{
                                height: 'calc(100vh - 126px)',
                            }}
                        />
                    )}
                </Observer>
                <Observer>
                    {() => {
                        const pagination = store.paginationHandler.data;
                        return (
                            <Pagination
                                totalItems={pagination.totalItems}
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                pageNeighbours={pagination.pageNeighbours}
                                onPageChange={handlePaginationClick}
                                className={styles.pagination}
                            />
                        );
                    }}
                </Observer>
            </BillingUsersLogPage>
        </>
    );
};

BillingUsersLogContent.propTypes = {
    store: PropTypes.oneOfType([
        PropTypes.instanceOf(BillingUsersLog),
        PropTypes.shape({}),
    ]),
    meta: BillingUsersLogPage.propTypes.meta,
};

BillingUsersLogContent.defaultProps = {
    meta: BillingUsersLogPage.defaultProps.meta,
    store: {
        tableHeaders: [],
        tableData: [],
        paginationHandler: { data: {} },
        load: () => null,
    },
};

export default BillingUsersLogContent;
