/* global document */
import { action, computed, observable } from 'mobx';
import { format } from 'date-fns';
import { ListItem } from '../../../shared/List';
import createForm from '../../../../helpers/Form';
import statusUpdateFields from '../../../../forms/operator/activation-key-status-update';
import { ACTIVATION_KEY_STATUS_NAME, DATE_TIME_FORMAT } from '../../../../constants/operator';

class ActivationKeyItem extends ListItem {
    @observable statusForm;

    @computed
    get keyValue() {
        const { $value } = this;
        return $value;
    }

    @computed
    get companyName() {
        const { $attachedCompanies: companies } = this;
        return companies?.length ? companies[0].name : null;
    }

    @computed
    get totalCompanyUseCount() {
        const { $companyUseCount, $maxСompanyUseCount } = this;
        return `${$companyUseCount || '0'}/${$maxСompanyUseCount || '∞'}`;
    }

    @computed
    get totalUserUseCount() {
        const { $userUseCount, $maxUserUseCount } = this;
        return `${$userUseCount || '0'}/${$maxUserUseCount || '∞'}`;
    }

    @computed
    get created() {
        const { $createdAt } = this;
        return format(new Date($createdAt), DATE_TIME_FORMAT);
    }

    @computed
    get statusName() {
        const { $status } = this;
        return ACTIVATION_KEY_STATUS_NAME[$status];
    }

    @computed
    get statusValue() {
        const { $status } = this;
        return {
            value: $status.toLowerCase(),
            text: ACTIVATION_KEY_STATUS_NAME[$status],
        };
    }

    @action
    createStatusUpdateForm() {
        const { statusForm: createdStatusForm } = this;
        if (createdStatusForm) {
            return createdStatusForm;
        }
        this.statusForm = createForm(statusUpdateFields);
        this.setStatusUpdateFormValues();
        this.initStatusUpdateFormListeners();
        return this.statusForm;
    }

    setStatusUpdateFormValues() {
        const {
            statusForm,
            $status,
        } = this;
        statusForm.$('status').set('value', $status);
    }

    initStatusUpdateFormListeners() {
        const { statusForm, parent, id } = this;
        statusForm.$('status').observe(() => {
            const values = statusForm.values();
            parent.updateStatus(id, statusForm.nullValues(values))
                .then(() => {
                    // DIRTY UGLY HACK
                    document
                        .querySelector('#status-form')
                        .classList
                        .remove('show');
                    document
                        .querySelector('header')
                        .click();
                    // END OF DIRTY UGLY HACK
                    this.setData({
                        status: values.status,
                    });
                });
        });
    }
}

export default ActivationKeyItem;
