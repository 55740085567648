/* eslint-disable import/prefer-default-export */
import parserFlats from './parser-flats';
import baseFlats from './base-flats';
import rentBaseFlats from './rent-base-flats';
import relatedFlats from './related-flats';
import parserHouses from './parser-houses';
import baseHouses from './base-houses';
import rentBaseHouses from './rent-base-houses';
import relatedHouses from './related-houses';
import parserSteads from './parser-steads';
import baseSteads from './base-steads';
import relatedSteads from './related-steads';
import parserCommercial from './parser-commercial';
import baseCommercial from './base-commercial';
import relatedCommercial from './related-commercial';
import similarFlats from './similar-flats';
import similarCommercial from './similar-commercial';
import similarHouses from './similar-houses';
import similarSteads from './similar-steads';
import notepadFlats from './notepad-flats';
import notepadCommercial from './notepad-commercial';
import notepadHouses from './notepad-houses';
import notepadSteads from './notepad-steads';

export default {
    parserFlats,
    baseFlats,
    rentBaseFlats,
    relatedFlats,
    parserHouses,
    baseHouses,
    rentBaseHouses,
    relatedHouses,
    parserSteads,
    baseSteads,
    relatedSteads,
    parserCommercial,
    baseCommercial,
    relatedCommercial,
    similarFlats,
    similarCommercial,
    similarHouses,
    similarSteads,
    notepadFlats,
    notepadCommercial,
    notepadHouses,
    notepadSteads,
};
