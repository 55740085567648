import { format } from 'date-fns';
import { computed } from 'mobx';
import { ListItem } from '../../../shared/List';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from '../../../../constants/shared';

class LogItem extends ListItem {
    @computed
    get companyName() {
        const { $companyName } = this;
        return $companyName;
    }

    @computed
    get hardwareName() {
        const { $hardwareName } = this;
        return $hardwareName;
    }

    @computed
    get action() {
        const { $action } = this;
        return $action;
    }

    @computed
    get createdAt() {
        const { $createdAt } = this;
        return $createdAt
            ? format(new Date($createdAt), DEFAULT_DATE_FORMAT)
            : null;
    }

    @computed
    get createdAtTime() {
        const { $createdAt } = this;
        return $createdAt
            ? format(new Date($createdAt), DEFAULT_TIME_FORMAT)
            : null;
    }
}

export default LogItem;
