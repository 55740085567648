import { ACTIVATION_KEY_STATUS, ACTIVATION_KEY_STATUS_NAME } from '../../constants/operator';

export default [{
    name: 'status',
    type: 'tagList',
    label: 'Изменить статус',
    rules: 'required',
    listType: 'radio',
    options: [{
        value: ACTIVATION_KEY_STATUS.ACTIVE,
        label: ACTIVATION_KEY_STATUS_NAME[ACTIVATION_KEY_STATUS.ACTIVE],
    }, {
        value: ACTIVATION_KEY_STATUS.BLOCKED,
        label: ACTIVATION_KEY_STATUS_NAME[ACTIVATION_KEY_STATUS.BLOCKED],
    }],
    size: 'md',
}];
