import { action } from 'mobx';
import createForm from '../../../../helpers/Form';
import BaseStrategy from './BaseStrategy';
import { CityDistrictLandmarkItem } from '../item';
import { directory as forms } from '../../../../forms/operator';
import { DIRECTORIES } from '../../../../constants/operator';

class CityDistrictLandmarkStrategy extends BaseStrategy {
    constructor() {
        super(DIRECTORIES.CITY_DISTRICT_LANDMARK);
        this.Item = CityDistrictLandmarkItem;
    }

    createFilterForm() {
        const { filterFormName, filterForm } = this;
        if (filterForm) return filterForm;
        this.filterForm = createForm(forms[filterFormName]);
        return this.filterForm;
    }

    @action
    createItemForm() {
        const { itemFormName, itemForm } = this;
        if (itemForm) return itemForm;
        this.itemForm = createForm(forms[itemFormName]);
        return this.itemForm;
    }

    // eslint-disable-next-line class-methods-use-this
    prepareItemData(data = {}) {
        const modified = data;
        if (!modified.description) {
            modified.description = '';
        }
        return modified;
    }
}

export default CityDistrictLandmarkStrategy;
