import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.OPERATOR_NAME,
    width: 25,
    textAlign: 'left',
}, {
    ...TABLE_CELL.EMAIL,
    width: 25,
    textAlign: 'left',
}, {
    ...TABLE_CELL.ACCESS,
    width: 25,
    textAlign: 'left',
}, {
    ...TABLE_CELL.OPERATOR_PLAN,
    width: 10,
}, {
    ...TABLE_CELL.CONTACT_PHONE,
    width: 15,
    textAlign: 'left',
    sortName: 'phone',
}];
