import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import ReactTooltip from '../../../lib/react-tooltip';
import useStores from '../../../hooks/operator/use-stores';
import OperatorReport from '../../../stores/operator/OperatorReport';
import { Table } from '../Table';
import OperatorReportPage from './OperatorReportPage';
import Pagination from '../../../components/Pagination';
import { CellReport, CellDone, CellPlan } from './OperatorReportCell';
import { OPERATOR_REPORT } from '../../../constants/operator';

import styles from './OperatorReport.module.scss';

const OperatorReportContent = ({
    store,
    meta,
}) => {
    const listRef = useRef();
    const { page } = useParams();
    const history = useHistory();
    const { headerActionsStore, headerToolsStore } = useStores();
    const { link: currentPage } = meta;
    // effects
    useEffect(() => {
        if (store.filterForm) {
            headerActionsStore.setActions(null);
            headerToolsStore.setTarget(null);
            return store.filterForm.on('success', () => {
                history.replace(`${currentPage}/1`, { inner: true });
                return Promise.resolve();
            });
        }
        return undefined;
    }, [store]);
    useEffect(() => {
        const { location: { state }, action } = history;
        if (!state || !state.inner || action === 'POP') {
            store.load(page || 1);
        }
        return undefined;
    }, [page, store]);
    // Interactions
    const handlePaginationClick = ({ page: nextPage }) => (
        history.push(`${currentPage}/${nextPage}`)
    );
    // Table headers
    const modifyHeaders = (headers) => headers.map((header) => {
        if (header.name.startsWith('hour')) {
            return { ...header, Cell: CellReport };
        }
        switch (header.name) {
            case 'done':
                return { ...header, Cell: CellDone };
            case 'plan':
                return { ...header, Cell: CellPlan };
            default:
                return header;
        }
    });
    // Component
    return (
        <>
            <OperatorReportPage
                meta={meta}
                store={store}
            >
                <Observer>
                    {() => (
                        <Table
                            ref={listRef}
                            headers={modifyHeaders(store.tableHeaders)}
                            store={store}
                            wrapperStyles={{
                                height: 'calc(100vh - 126px)',
                            }}
                        />
                    )}
                </Observer>
                <Observer>
                    {() => {
                        const pagination = store.paginationHandler.data;
                        return (
                            <Pagination
                                totalItems={pagination.totalItems}
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                pageNeighbours={pagination.pageNeighbours}
                                onPageChange={handlePaginationClick}
                                className={styles.pagination}
                            />
                        );
                    }}
                </Observer>
            </OperatorReportPage>
            <ReactTooltip
                id={OPERATOR_REPORT}
                delayHide={100}
                bodyMode
                getContent={(actions) => {
                    const parsedActions = actions
                        ? JSON.parse(actions)
                        : null;
                    if (!parsedActions || !parsedActions.length) {
                        return null;
                    }
                    return (
                        <div className={styles['day-details']}>
                            <div className={`${styles['day-details__row']} ${styles['day-details__row--header']}`}>
                                <div className={`${styles['day-details__name']} ${styles['day-details__title']}`}>
                                    Действие
                                </div>
                                <div className={`${styles['day-details__value']} ${styles['day-details__title']}`}>
                                    шт.
                                </div>
                            </div>
                            {parsedActions.map(({ name, count }, index) => (
                                <div className={styles['day-details__row']} key={`day-${index + 1}`}>
                                    <div className={styles['day-details__name']}>
                                        {name}
                                    </div>
                                    <div className={styles['day-details__value']}>
                                        {count}
                                    </div>
                                </div>
                            ))}
                        </div>
                    );
                }}
            />
        </>
    );
};

OperatorReportContent.propTypes = {
    store: PropTypes.oneOfType([
        PropTypes.instanceOf(OperatorReport),
        PropTypes.shape({}),
    ]),
    meta: OperatorReportPage.propTypes.meta,
};

OperatorReportContent.defaultProps = {
    meta: OperatorReportPage.defaultProps.meta,
    store: {
        tableHeaders: [],
        tableData: [],
        paginationHandler: { data: {} },
        load: () => null,
    },
};

export default OperatorReportContent;
