import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import useStores from '../../../hooks/operator/use-stores';
import useKeyPress from '../../../hooks/shared/use-key-press';
import Directory from '../../../stores/operator/Directory/Directory';
import { Table } from '../Table';
import DirectoryPage from './DirectoryPage';
import Pagination from '../../../components/Pagination';
import Dialog from '../Dialog';
import ApproveDialog from '../../shared/Dialog/ApproveDialog';
import DirectoryForm from './DirectoryForm';
import spawnNotification from '../../../utils/spawn-notification';

import styles from './DirectoryPage.module.scss';

const DirectoryContent = ({
    store,
    meta,
    messages,
}) => {
    const [customApproveMessage, setCustomeApproveMessage] = useState('');
    const listRef = useRef();
    const { dialogStore } = useStores();
    const { page } = useParams();
    const history = useHistory();
    const isCtrlPressed = useKeyPress('Control');
    const { link: currentPage } = meta;
    useEffect(() => {
        if (store.filterForm) {
            return store.filterForm.on('success', () => {
                history.replace(`${currentPage}/1`, { inner: true });
            });
        }
        return undefined;
    }, [store]);
    useEffect(() => {
        const { location: { state }, action } = history;
        if (!state || !state.inner || action === 'POP') {
            store.load(page || 1);
        }
    }, [page, store]);
    // Interactions
    const handleNewItemClick = () => {
        const itemForm = store.createItemForm();
        const unsubscribeForm = itemForm.on('done', () => {
            dialogStore.hide('new-directory-item');
            spawnNotification(messages.created);
        });
        const unsubscribeDialog = dialogStore.on('new-directory-item:hidden', () => {
            unsubscribeForm();
            unsubscribeDialog();
        });
        dialogStore.show('new-directory-item');
    };
    const handlePaginationClick = ({ page: nextPage }) => history.push(`${currentPage}/${nextPage}`);
    const handleRemoveClick = () => {
        if (!store.selected.length) return Promise.resolve();
        if (store.strategy.directoryName === 'phone') {
            const sumOfRelated = store.selected.reduce((sum, item) => sum + +item.totalLinked, 0);
            if (sumOfRelated) {
                setCustomeApproveMessage(`Выбранные контакты привязаны к ${sumOfRelated} объектам. Если продолжите, то из данных объектов контакты будут также удалены.`);
            }
        }
        return dialogStore.show('approve-remove-item');
    };
    const handleRemoveApprove = () => store.remove();
    const handleRowClick = ({ original: item }) => {
        if (store.strategy?.disableEdit) {
            return;
        }
        if (isCtrlPressed()) {
            item.select();
            return;
        }
        const itemForm = item.prepareForm();
        const unsubscribeForm = itemForm.on('done', () => {
            dialogStore.hide('edit-directory-item');
            spawnNotification(messages.updated);
        });
        const unsubscribeDialog = dialogStore.on('edit-directory-item:hidden', () => {
            unsubscribeForm();
            unsubscribeDialog();
        });
        store.setEditItem(item);
        dialogStore.show('edit-directory-item');
    };
    // Component
    return (
        <DirectoryPage
            meta={meta}
            onRemoveClick={handleRemoveClick}
            onCreateNewClick={handleNewItemClick}
            store={store}
        >
            <Table
                ref={listRef}
                headers={store.tableHeaders}
                store={store}
                wrapperStyles={{
                    height: 'calc(100vh - 131px)',
                }}
                onRowClick={handleRowClick}
                onDoubleRowClick={handleRowClick}
            />
            <Observer>
                {() => {
                    const pagination = store.paginationHandler.data;
                    return (
                        <Pagination
                            totalItems={pagination.totalItems}
                            currentPage={pagination.currentPage}
                            itemsPerPage={pagination.itemsPerPage}
                            pageNeighbours={pagination.pageNeighbours}
                            onPageChange={handlePaginationClick}
                            className={styles.pagination}
                        />
                    );
                }}
            </Observer>
            <Observer>
                {() => (
                    <ApproveDialog
                        useStores={useStores}
                        name="approve-remove-item"
                        header={<>{messages.delete}</>}
                        onApprove={handleRemoveApprove}
                        approveDisabled={store.loading}
                        onClose={() => setCustomeApproveMessage('')}
                    >
                        Вы уверены, что хотите
                        &nbsp;
                        {messages.approve}
                        &nbsp;
                        из справочника?
                        <br />
                        Отменить это действие невозможно
                        {!!customApproveMessage && (
                            <b>
                                <br />
                                <br />
                                {customApproveMessage}
                            </b>
                        )}
                    </ApproveDialog>
                )}
            </Observer>
            <Dialog
                name="new-directory-item"
                size="sm"
                footer={null}
                header={<>{messages.create}</>}
            >
                <Observer>
                    {() => (
                        <DirectoryForm
                            button="Добавить"
                            form={store.itemForm}
                        />
                    )}
                </Observer>
            </Dialog>
            <Dialog
                name="edit-directory-item"
                size="sm"
                footer={null}
                header={<>{messages.update}</>}
            >
                <Observer>
                    {() => (
                        <DirectoryForm
                            form={store.editItem && store.editItem.itemForm}
                        />
                    )}
                </Observer>
            </Dialog>
        </DirectoryPage>
    );
};

DirectoryContent.propTypes = {
    meta: DirectoryPage.propTypes.meta,
    messages: PropTypes.shape({
        created: PropTypes.string,
        updated: PropTypes.string,
        delete: PropTypes.string,
        create: PropTypes.string,
        update: PropTypes.string,
        approve: PropTypes.string,
    }),
    store: PropTypes.instanceOf(Directory),
};

DirectoryContent.defaultProps = {
    meta: DirectoryPage.defaultProps.meta,
    store: {
        tableHeaders: [],
        tableData: [],
        selected: [],
        paginationHandler: { data: {} },
        load: () => null,
    },
    messages: {
        created: 'Новый элемент добавлен в справочник',
        updated: 'Элемент справочника изменен',
        delete: 'Удалить?',
        create: 'Добавить',
        update: 'Изменить',
        approve: 'удалить элемент',
    },
};

export default DirectoryContent;
