/* global alert */
import { action, computed, observable } from 'mobx';
import { format } from 'date-fns';
import DynamicStructure from '../../shared/DynamicStructure';
import DataSource from '../../shared/DataSource';
import { BillingService } from '../../../services/operator';
import forEachField from '../../../forms/utils/for-each-field';
import createForm from '../../../helpers/Form';
import { billingUserDetailed as billingUserDetailedFields } from '../../../forms/operator';
import { DEFAULT_DATE_FORMAT } from '../../../constants/shared';
import isEmpty from '../../../utils/is-empty';

class BillingUserDetailed extends DynamicStructure {
    form;

    objectContentMap = [
        { name: 'mainInfo', label: 'Основная информация' },
        { name: 'contacts', label: 'Контакты' },
        { name: 'security', label: 'Безопасность' },
        { name: 'chat-bot', label: 'Телеграм-бот' },
    ];

    @observable
    $approving = false;

    @observable
    $registeredAt;

    @observable
    $updatedAt;

    @observable
    $activeUntil;

    @observable
    $status;

    @observable
    $name;

    @observable
    $company;

    @observable
    $hardwareName;

    @observable
    $security;

    @observable
    $activationKey;

    @observable
    $telegramCodes;

    constructor() {
        super();
        this.dataSource = new DataSource(BillingService);
        this.createForm();
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    @computed
    get approving() {
        const { $approving } = this;
        return $approving;
    }

    @computed
    get name() {
        const { $name: { firstName, lastName } = {} } = this;
        return firstName && lastName
            ? `${firstName} ${lastName}`
            : null;
    }

    @computed
    get fullName() {
        const { $name: { firstName, middleName, lastName } = {} } = this;
        return firstName && lastName
            ? `${firstName} ${middleName} ${lastName}`
            : null;
    }

    @computed
    get companyId() {
        const { $company: { id } = {} } = this;
        return id || null;
    }

    @computed
    get companyName() {
        const { $company: { name } = {} } = this;
        return name || null;
    }

    @computed
    get companyPhone() {
        const { $company: { phone } = {} } = this;
        return phone || null;
    }

    @computed
    get companyAddress() {
        const { $company: { address } = {} } = this;
        return address || null;
    }

    @computed
    get companyEmail() {
        const { $company: { email } = {} } = this;
        return email || null;
    }

    @computed
    get registeredAt() {
        const { $registeredAt } = this;
        return $registeredAt
            ? format(new Date($registeredAt), `${DEFAULT_DATE_FORMAT}, HH:mm`)
            : null;
    }

    @computed
    get registeredAtDate() {
        const { $registeredAt } = this;
        return $registeredAt
            ? format(new Date($registeredAt), DEFAULT_DATE_FORMAT)
            : null;
    }

    @computed
    get registeredAtTime() {
        const { $registeredAt } = this;
        return $registeredAt
            ? format(new Date($registeredAt), 'HH:mm')
            : null;
    }

    @computed
    get updatedAt() {
        const { $updatedAt } = this;
        return $updatedAt
            ? format(new Date($updatedAt), `${DEFAULT_DATE_FORMAT}, HH:mm`)
            : null;
    }

    @computed
    get activeUntil() {
        const { $activeUntil } = this;
        return $activeUntil
            ? format(new Date($activeUntil), `${DEFAULT_DATE_FORMAT}, HH:mm`)
            : null;
    }

    @computed
    get statusKey() {
        const { $status } = this;
        return $status
            ? $status.key
            : null;
    }

    @computed
    get hardwareName() {
        const { $hardwareName } = this;
        return $hardwareName || null;
    }

    @computed
    get maxDevicesPerMonth() {
        const { $security } = this;
        return $security?.maxDevicesPerMonth || '0';
    }

    @computed
    get maxDeviceChangesPerMonth() {
        const { $security } = this;
        return $security?.maxDeviceChangesPerMonth || '0';
    }

    @computed
    get deviceParamUpdatesPerMonth() {
        const { $security } = this;
        return $security?.deviceParamUpdatesPerMonth || '0';
    }

    @computed
    get newDevicesPerMonth() {
        const { $security } = this;
        return $security?.newDevicesPerMonth || '0';
    }

    @computed
    get statusValue() {
        const { $status } = this;
        return $status?.name || null;
    }

    @computed
    get activationKey() {
        const { $activationKey } = this;
        return $activationKey || null;
    }

    @computed
    get telegramUsers() {
        const { $telegramCodes } = this;
        return $telegramCodes || [];
    }

    @action
    preload() {
        this.dataSource.setPreload();
    }

    reload() {
        return this.load(this.id);
    }

    load(id) {
        return this.dataSource.getUser(id)
            .then((response) => {
                this.setData(response);
                this.setFormValues();
                return Promise.resolve(this);
            })
            .catch((e) => {
                console.error(e);
                alert(e.message);
            });
    }

    @action
    setApprovingState(state = true) {
        const { form } = this;
        if (state === true && form.snapshotIsDiffer) {
            this.$approving = true;
        } else {
            this.$approving = false;
        }
        return !this.$approving;
    }

    createForm() {
        const { form } = this;
        if (form) {
            return form;
        }
        this.form = createForm(billingUserDetailedFields);
        this.initFormListeners();
        return this.form;
    }

    setFormValues() {
        const { form } = this;
        forEachField(billingUserDetailedFields, (field) => {
            if (Object.getOwnPropertyDescriptor(this, field.name)) {
                // eslint-disable-next-line no-param-reassign
                if (!isEmpty(this[field.name])) {
                    form.$(field.name).set('value', this[field.name]);
                }
            }
            if (field.name === 'maxDevicesPerMonth') {
                const { newDevicesPerMonth: thisMonth } = this;
                form.$(field.name).set('extra', { ...form.$(field.name).get('extra'), thisMonth });
            }
            if (field.name === 'maxDeviceChangesPerMonth') {
                const { deviceParamUpdatesPerMonth: thisMonth } = this;
                form.$(field.name).set('extra', { ...form.$(field.name).get('extra'), thisMonth });
            }
        });
        form.makeSnapshot();
    }

    initFormListeners() {
        const {
            form,
        } = this;
        form.on('success', (values) => {
            const { id } = this;
            const request = this.prepareRequest(values);
            return this.dataSource.updateUser(id, request)
                .then((response) => {
                    this.setData(response);
                    form.makeSnapshot();
                    return Promise.resolve(response);
                })
                .catch((e) => alert(e));
        });
    }

    blockUser() {
        return this.dataSource.toggleUserBlock(this.id, 'blocked')
            .then(this.setData.bind(this))
            .catch((e) => alert(e));
    }

    unblockUser() {
        return this.dataSource.toggleUserBlock(this.id, 'active')
            .then(this.setData.bind(this))
            .catch((e) => alert(e));
    }

    archiveUser() {
        return this.dataSource.archiveUser(this.id)
            .then(this.setData.bind(this))
            .catch((e) => alert(e));
    }

    deleteTelegramAccount(chatId) {
        return this.dataSource.deleteTelegramAccount(chatId)
            .then(() => {
                const { telegramUsers } = this;
                this.$telegramCodes = telegramUsers.filter((u) => u.chatId !== chatId);
            })
            .catch((e) => alert(e));
    }

    prepareRequest(values) {
        const { form } = this;
        return form.nullValues(values);
    }

    toItem() {
        const {
            $hardwareName,
            $status,
        } = this;
        return {
            hardwareName: $hardwareName,
            status: $status,
        };
    }
}

export default BillingUserDetailed;
