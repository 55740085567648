/* global alert */
import {
    action,
    computed,
    observable,
} from 'mobx';
import { format } from 'date-fns';
import DynamicStructure from '../../shared/DynamicStructure';
import DataSource from '../../shared/DataSource';
import { BillingService } from '../../../services/operator';
import createForm from '../../../helpers/Form';
import forEachField from '../../../forms/utils/for-each-field';
import isEmpty from '../../../utils/is-empty';
import { billingCompanyDetailed as billingCompanyDetailedFields } from '../../../forms/operator';
import BillingSubscribeStore from '../BillingSubscribe';
import BillingDebtStore from '../BillingDebt';
import { DEFAULT_DATE_FORMAT } from '../../../constants/shared';

class BillingCompanyDetailed extends DynamicStructure {
    form;

    objectContentMap = [
        { name: 'mainInfo', label: 'Основная информация' },
        { name: 'contacts', label: 'Контакты' },
        { name: 'subscription', label: 'Подписка' },
    ];

    @observable
    $approving = false;

    @observable
    $subscribing = false;

    @observable
    $loaning = false;

    @observable
    $registeredAt;

    @observable
    $status;

    @observable
    $newPrice

    @observable
    $status

    @observable
    $price

    @observable
    $activationKey

    @observable
    $userCount

    @observable
    $userChanged

    @observable
    $activeUntil

    @observable
    subscribe = null;

    @observable
    debt = null;

    constructor() {
        super();
        this.dataSource = new DataSource(BillingService);
        this.createForm();
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    @computed
    get approving() {
        const { $approving } = this;
        return $approving;
    }

    @computed
    get subscribing() {
        const { $subscribing } = this;
        return $subscribing;
    }

    @computed
    get loaning() {
        const { $loaning } = this;
        return $loaning;
    }

    @computed
    get registeredAt() {
        const { $registeredAt } = this;
        return $registeredAt
            ? format(new Date($registeredAt), `${DEFAULT_DATE_FORMAT}, HH:mm`)
            : null;
    }

    @computed
    get registeredAtDate() {
        const { $registeredAt } = this;
        return $registeredAt
            ? format(new Date($registeredAt), DEFAULT_DATE_FORMAT)
            : null;
    }

    @computed
    get registeredAtTime() {
        const { $registeredAt } = this;
        return $registeredAt
            ? format(new Date($registeredAt), 'HH:mm')
            : null;
    }

    @computed
    get statusKey() {
        const { $status } = this;
        return $status
            ? $status.key
            : null;
    }

    @computed
    get statusValue() {
        const { $status } = this;
        const date = $status && $status.date && format(new Date($status.date), DEFAULT_DATE_FORMAT);
        return $status
            ? `${$status.name} ${date ? `${$status.datePrefix} ${date}` : ''}`
            : null;
    }

    @computed
    get userCount() {
        const { $userCount } = this;
        return $userCount || null;
    }

    get userChanged() {
        const { $userChanged } = this;
        return $userChanged || null;
    }

    @computed
    get activationKey() {
        const { $activationKey } = this;
        return $activationKey || null;
    }

    @computed
    get price() {
        const { $price } = this;
        return $price;
    }

    @computed
    get newPrice() {
        const { $newPrice, $price } = this;
        return $newPrice?.value || $price;
    }

    @action
    setApprovingState(state = true) {
        const { form } = this;
        if (state === true && form.snapshotIsDiffer) {
            this.$approving = true;
        } else {
            this.$approving = false;
        }
        return !this.$approving;
    }

    @action
    initSubscribtion(fromDetailed = true) {
        this.$subscribing = fromDetailed;
        if (!this.subscribe) {
            this.subscribe = new BillingSubscribeStore(this.id);
            this.subscribe.form.on('done', ([data]) => {
                this.setData(data);
                this.setFormValues();
                this.cancelSubscribtion();
                this.form.emit('updated', [data]);
            });
        }
    }

    @action
    cancelSubscribtion() {
        this.$subscribing = false;
    }

    @action
    initDebt(fromDetailed = true) {
        this.$loaning = fromDetailed;
        if (!this.debt) {
            this.debt = new BillingDebtStore(this.id);
            this.debt.form.on('done', ([data]) => {
                this.setData(data);
                this.setFormValues();
                this.cancelDebt();
                this.form.emit('updated', [data]);
            });
        }
    }

    @action
    cancelDebt() {
        this.$loaning = false;
    }

    @action
    preload() {
        this.dataSource.setPreload();
    }

    reload() {
        return this.load(this.id);
    }

    load(id) {
        return this.dataSource.getCompany(id)
            .then((response) => {
                this.setData(response);
                this.setFormValues();
                return Promise.resolve(this);
            })
            .catch((e) => {
                alert(e.message);
            });
    }

    createForm() {
        const { form } = this;
        if (form) {
            return form;
        }
        this.form = createForm(billingCompanyDetailedFields);
        this.initFormListeners();
        return this.form;
    }

    setFormValues() {
        const { form, $newPrice } = this;
        const since = $newPrice?.since;
        forEachField(billingCompanyDetailedFields, (field) => {
            if (Object.getOwnPropertyDescriptor(this, field.name)) {
                // eslint-disable-next-line no-param-reassign
                if (!isEmpty(this[field.name])) {
                    form.$(field.name).set('value', this[field.name]);
                }
            }

            if (field.name === 'price') {
                if (since) {
                    const formatted = format(new Date(since), DEFAULT_DATE_FORMAT);
                    form.$(field.name).set('label', `${field.label} ${since && ` с  ${formatted}`}`);
                }
                form.$(field.name).set('value', $newPrice?.value || this[field.name]);
            }
        });
        form.makeSnapshot();
    }

    initFormListeners() {
        const {
            form,
        } = this;
        form.on('success', (values) => {
            const { id } = this;
            const request = this.prepareRequest(values);
            return this.dataSource.updateCompany(id, request)
                .then((response) => {
                    this.setData(response);
                    form.makeSnapshot();
                    return Promise.resolve(response);
                })
                .catch((e) => alert(e));
        });
    }

    prepareRequest({ inn, ...values }) {
        const { form } = this;
        return form.nullValues({ inn: `${inn}`, ...values });
    }

    blockCompany() {
        return this.dataSource.toggleCompanyBlock(this.id, 'blocked')
            .then(this.setData.bind(this))
            .catch((e) => alert(e));
    }

    unblockCompany() {
        return this.dataSource.toggleCompanyBlock(this.id, 'active')
            .then(this.setData.bind(this))
            .catch((e) => alert(e));
    }

    archiveCompany() {
        return this.dataSource.archiveCompany(this.id)
            .then(this.setData.bind(this))
            .catch((e) => alert(e));
    }

    toItem() {
        const {
            $price,
            $name,
            $phone,
            $address,
            $activeUntil,
            $status,
            $activeUserCount,
            $userChanged,
        } = this;
        return {
            activeUntil: $activeUntil,
            price: $price,
            name: $name,
            phone: $phone,
            address: $address,
            status: $status,
            instances: {
                current: $activeUserCount,
                changed: $userChanged,
            },
        };
    }
}

export default BillingCompanyDetailed;
