import React from 'react';
import Filter from '../../shared/Filter/Filter';
import useStores from '../../../hooks/operator/use-stores';

const FilterWithStore = (props) => (
    <Filter
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        useStores={useStores}
    />
);

export default FilterWithStore;
