import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';
import declOfNum from '../../../utils/decl-of-num';

import styles from './BillingActions.module.scss';

const BillingActionsField = ({
    label,
    id,
    hidden,
    children,
    name,
    form,
}) => {
    if (hidden) return null;
    const { days } = form.$(name).get('extra');
    return (
        <div className={`${styles.field} ${hidden ? 'hidden' : ''}`}>
            {
                label
                    ? (
                        <Label
                            className={styles.field__label}
                            htmlFor={id}
                        >
                            {label}
                        </Label>
                    )
                    : null
            }
            {
                name === 'amount'
                    ? (
                        <div className={styles.field__amount}>
                            <div className={styles.field__sum}>
                                {children}
                            </div>
                            <div className={styles.field__days}>
                                {days && (
                                    <>
                                        Продлит подписку на
                                        {'\u00a0'}
                                        <b>
                                            {days}
                                            {'\u00a0'}
                                            {declOfNum(days, ['день', 'дня', 'дней'])}
                                        </b>
                                    </>
                                )}
                            </div>
                        </div>
                    ) : children
            }
        </div>
    );
};

BillingActionsField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    hidden: PropTypes.bool,
    name: PropTypes.string,
    form: PropTypes.shape({
        $: PropTypes.func,
    }),
    children: PropTypes.element.isRequired,
};

BillingActionsField.defaultProps = {
    label: '',
    hidden: false,
    name: '',
    form: {
        $: () => null,
    },
};

export default BillingActionsField;
