import React, { forwardRef } from 'react';
import useStores from '../../../hooks/operator/use-stores';
import { TableWithSelected } from '../../shared/TableWithSelected';

const TableWithSelectedWithStore = forwardRef((props, ref) => (
    <TableWithSelected
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
        useStores={useStores}
    />
));

export default TableWithSelectedWithStore;
