import { TABLE_CELL } from '../../../../constants/operator';

const tableHeaders = [
    TABLE_CELL.ROW_SELECT,
    {
        ...TABLE_CELL.CITY_NAME,
        width: 25,
        textAlign: 'left',
    },
    {
        ...TABLE_CELL.STREET_NAME,
        width: 35,
        textAlign: 'left',
    },
    {
        ...TABLE_CELL.CITY_ALIASES,
        width: 37,
        textAlign: 'left',
    },
];

export default tableHeaders;
