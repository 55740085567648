export default [{
    name: 'name',
    size: 'md',
    type: 'text',
    label: 'Наименование',
    rules: 'required|max:255',
}, {
    name: 'shortname',
    size: 'md',
    type: 'text',
    label: 'Кратко',
    hidden: true,
    rules: 'max:32',
}];
