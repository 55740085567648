import React from 'react';
import PropTypes from 'prop-types';
import BaseStrategy, {
    FlatStrategy,
    HouseStrategy,
    SteadStrategy,
    CommercialStrategy,
} from '../../../stores/shared/RefactoredObjectList/strategy';
import { withSaleStrategy, withRentStrategy } from '../../../stores/shared/RefactoredObjectList/strategy/mixins';
import ObjectListStore from '../../../stores/operator/RefactoredObjectList/ObjectList';
import RelatedStore from '../../../stores/operator/RefactoredObjectList/Related';
import NotepadStore from '../../../stores/operator/RefactoredObjectList/Notepad';
import { withParserStrategy, withBaseStrategy } from '../../../stores/operator/RefactoredObjectList/strategy/mixins';
import {
    FlatDetailed,
    HouseDetailed,
    SteadDetailed,
    CommercialDetailed,
} from '../../../stores/operator/RefactoredObjectDetailed';
import { withParserDetailed, withBaseDetailed } from '../../../stores/operator/RefactoredObjectDetailed/mixins';
import { withSaleDetailed, withRentDetailed } from '../../../stores/shared/RefactoredObjectDetailed/mixins';
import { PopupTabsWithApproveStore } from '../../../stores/shared/PopupTabsStore';
import NotepadList from '../../../stores/operator/NotepadList/NotepadList';
import OperatorExportObjects from '../../../stores/operator/ExportObjects/ExportObjects';
import ObjectListContent from './ObjectListContent';
import withStore from '../../../hocs/withStore';
import { PagesRequestTypesNamesMap } from '../../../routes/utils/pagesRouteInfo';

const storesFactory = (options) => {
    const { requestType, dealType, objectType } = options;
    let Strategy;
    let Detailed;
    let withDealTypeStrategy;
    let withRequestTypeStrategy;
    let withDealTypeDetailed;
    let withRequestTypeDetailed;
    switch (objectType) {
        case 'flats':
            Detailed = FlatDetailed;
            Strategy = FlatStrategy;
            break;
        case 'houses':
            Detailed = HouseDetailed;
            Strategy = HouseStrategy;
            break;
        case 'steads':
            Detailed = SteadDetailed;
            Strategy = SteadStrategy;
            break;
        case 'commercial':
            Detailed = CommercialDetailed;
            Strategy = CommercialStrategy;
            break;
        default:
            Strategy = BaseStrategy;
    }
    switch (requestType) {
        case 'parser':
        case 'duplicates':
            withRequestTypeStrategy = withParserStrategy;
            withRequestTypeDetailed = withParserDetailed;
            break;
        case 'base':
        default:
            withRequestTypeStrategy = withBaseStrategy;
            withRequestTypeDetailed = withBaseDetailed;
    }
    switch (dealType) {
        case 'sale':
            withDealTypeStrategy = withSaleStrategy;
            withDealTypeDetailed = withSaleDetailed;
            break;
        case 'rent':
        default:
            withDealTypeStrategy = withRentStrategy;
            withDealTypeDetailed = withRentDetailed;
    }
    Strategy = withRequestTypeStrategy(withDealTypeStrategy(Strategy));
    const list = new ObjectListStore(new Strategy());
    const notepads = requestType === 'base' ? new NotepadList() : null;
    const exportObjects = requestType === 'base' ? new OperatorExportObjects(list, notepads) : null;
    const tabs = new PopupTabsWithApproveStore();
    return () => ({
        list,
        tabs,
        notepads,
        exportObjects,
        createDetailed: (id) => {
            const DetailedClass = withRequestTypeDetailed(withDealTypeDetailed(Detailed));
            return new DetailedClass(id);
        },
        createRelated: (id, phone) => new RelatedStore(id, phone, requestType),
        createNotepad: (id) => new NotepadStore(id),
    });
};

const ObjectList = (props) => {
    const {
        match: {
            params: {
                dealType,
                objectType,
            },
        },
        meta,
        meta: {
            extra: {
                requestType,
            },
        },
    } = props;
    const pageName = PagesRequestTypesNamesMap.get(requestType);
    const createStores = storesFactory({
        requestType,
        dealType,
        objectType,
    });
    const Component = withStore(
        ObjectListContent,
        createStores,
    );
    return <Component meta={meta} selectable={requestType === 'base'} pageName={pageName} />;
};

ObjectList.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dealType: PropTypes.string,
            objectType: PropTypes.string,
        }),
    }),
    meta: PropTypes.shape({
        extra: PropTypes.shape({
            requestType: PropTypes.string,
        }),
        link: PropTypes.string,
    }),
};

ObjectList.defaultProps = {
    match: {},
    meta: {},
};

export default ObjectList;
