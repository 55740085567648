/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import useStores from '../../../hooks/operator/use-stores';
import BillingUsersListStore from '../../../stores/operator/RefactoredBillingUsersList';
import BillingUserDetailedStore from '../../../stores/operator/RefactoredBillingUserDetailed';
import BillingCompanyDetailed from '../../../stores/operator/RefactoredBillingCompanyDetailed';
import { TableWithSelected } from '../TableWithSelected';
import BillingUsersListPage from './BillingUsersListPage';
import Pagination from '../../../components/Pagination';
import KeyboardHandler from '../../../components/KeyboardHandler';
import BillingUserSelected from './BillingUserSelected';
import BillingUserDetailed from '../BillingUserDetailed';
import Dialog from '../Dialog';
import { BillingDebt, BillingSubscribe } from '../BillingActions';
import { SORT_ORDER, DIRECTORIES, SIZE } from '../../../constants/operator';

import styles from './BillingUsersList.module.scss';

const BillingUsersListContent = ({
    store,
    meta,
}) => {
    const [detailed, _setDetailed] = useState();
    const [detailedSelected, _setSelected] = useState();
    const [companySelected, _setCompanySelected] = useState();
    const detailedRef = useRef(detailed);
    const listRef = useRef();
    const keyboardRef = useRef();
    const { page } = useParams();
    const history = useHistory();
    const {
        headerActionsStore,
        headerToolsStore,
        dialogStore,
        directoriesStore,
    } = useStores();
    const { link: currentPage } = meta;
    const [companyId] = useQueryParam('companyId');
    let keyPressDelay;

    const setDetailed = (user) => {
        _setDetailed(user);
        detailedRef.current = user;
        _setSelected(user);
    };
    const prepareCompany = (selectedCompanyId) => {
        const company = new BillingCompanyDetailed();
        company.setData({ id: selectedCompanyId });
        _setCompanySelected(company);
    };
    const isUserSelected = (userId) => (
        detailedRef && userId === detailedRef.current?.id
    );
    const loadUser = ({ original: { id: userId } }, isDoubleClick) => {
        if (isUserSelected(userId)) {
            return detailedRef.current.reload();
        }
        const item = store.getItem(userId);
        const user = new BillingUserDetailedStore();
        user.setData({ id: userId });

        if (!isDoubleClick) {
            store.setCursor(companyId);
        }

        user.form.on('done', (response) => {
            if (!response.filter((res) => Boolean(res)).length) {
                return;
            }
            item.setData(user.toItem());
            dialogStore.hide();
        });
        user.form.on('updated', () => {
            item.setData(user.toItem());
        });

        if (!isDoubleClick && detailedRef.current) {
            detailedRef.current.preload();
        } else {
            user.load(userId);
            setDetailed(user, isDoubleClick);
            return Promise.resolve(user);
        }
        return user.load(userId)
            .then(() => {
                setDetailed(user, isDoubleClick);
                return Promise.resolve(user);
            });
    };

    useEffect(() => {
        if (store.filterForm) {
            headerActionsStore.setActions([]);
            headerToolsStore.setTarget(null);

            if (companyId) {
                const id = Number(companyId);
                directoriesStore[DIRECTORIES.BILLING_COMPANIES]
                    .loadData()
                    .then((options) => {
                        const company = options.find(({ value }) => value === id);
                        store.filterForm
                            .$('companyIds')
                            .set('value', { value: id, label: company.label });
                    });
                store.filterForm
                    .$('companyIds')
                    .set('value', { value: id, label: '...' });
                store.approveFilterData();
            }

            return store.filterForm.on('success', () => {
                history.replace(`${currentPage}/1`, { inner: true });
                keyboardRef.current.focus();
            });
        }
        return undefined;
    }, [store]);

    useEffect(() => {
        const { location: { state }, action } = history;
        if ((!state || !state.inner || action === 'POP') && store.filterForm) {
            store.load(page || 1);
            keyboardRef.current.focus();
        }
        return undefined;
    }, [page, store]);

    // Interactions
    const handlePaginationClick = ({ page: nextPage }) => {
        history.push(`${currentPage}/${nextPage}`);
        keyboardRef.current.focus();
    };
    const handleDoubleRowClick = (row) => {
        loadUser(row, true);
        dialogStore.show('user-detailed');
    };
    const handleSingleRowClick = (row) => {
        if (handleSingleRowClick.callback) {
            handleSingleRowClick.callback(
                loadUser(row)
                    .then((user) => {
                        prepareCompany(user.companyId);
                        return user;
                    }),
            );
        }
    };
    const handleSortChange = ({ sortName, sortDirection }) => {
        let nextDirection;
        switch (sortDirection) {
            case SORT_ORDER.ASC:
                nextDirection = SORT_ORDER.DESC;
                break;
            case SORT_ORDER.DESC:
                nextDirection = null;
                break;
            default:
                nextDirection = SORT_ORDER.ASC;
        }
        store.setSort(sortName, nextDirection);
        store.load(page);
    };
    const handleArrowKey = (direction) => {
        if (direction === -1) {
            store.setPrevCursor();
        } else {
            store.setNextCursor();
        }
        const currentId = store.cursor;
        const index = store.tableData.findIndex(({ id }) => id === currentId);
        listRef.current?.scrollToItem(index);
        if (keyPressDelay) {
            clearTimeout(keyPressDelay);
        }
        keyPressDelay = setTimeout(() => {
            handleSingleRowClick({ original: { id: currentId } });
        }, 300);
    };
    const handleDownKey = () => {
        handleArrowKey(1);
    };
    const handleUpKey = () => {
        handleArrowKey(-1);
    };
    const handleEnterKey = () => {
        handleDoubleRowClick({ original: { id: store.cursor } });
    };
    const handleDetailedCloseClick = () => {
        if (!detailed) {
            return true;
        }
        return detailed.setApprovingState();
    };
    const handleDetailedCancelClick = () => {
        dialogStore.hide();
    };
    const handleItemStatusChange = (id, data) => {
        const item = store.getItem(id);
        item.setData(data);
    };
    const handleSubscribeClick = () => {
        companySelected.initSubscribtion();
        dialogStore.show('detailed-subscription');
        companySelected.subscribe.form.on('done', () => {
            dialogStore.hide();
        });
    };
    const handleDebtClick = () => {
        companySelected.initDebt();
        dialogStore.show('detailed-debt');
        companySelected.debt.form.on('done', () => {
            dialogStore.hide();
        });
    };

    // Component
    return (
        <>
            <BillingUsersListPage
                meta={meta}
                store={store}
            >
                <KeyboardHandler
                    ref={keyboardRef}
                    onDown={handleDownKey}
                    onUp={handleUpKey}
                    onEnter={handleEnterKey}
                >
                    <Observer>
                        {() => (
                            <TableWithSelected
                                ref={listRef}
                                headers={store.tableHeaders}
                                store={store}
                                selectedContentHeight="190px"
                                onRowClick={handleSingleRowClick}
                                onDoubleRowClick={handleDoubleRowClick}
                                onTableHeaderClick={handleSortChange}
                                fullHeight="calc(100vh - 160px)"
                                components={{
                                    SelectedWrapper: (props) => (
                                        <BillingUserSelected
                                            onClick={handleDoubleRowClick}
                                            onSubscriptionClick={handleSubscribeClick}
                                            onDebtClick={handleDebtClick}
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...props}
                                        />
                                    ),
                                }}
                            />
                        )}
                    </Observer>
                </KeyboardHandler>
                <Observer>
                    {() => {
                        const pagination = store.paginationHandler.data;
                        return (
                            <Pagination
                                totalItems={pagination.totalItems}
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                pageNeighbours={pagination.pageNeighbours}
                                onPageChange={handlePaginationClick}
                                className={styles.pagination}
                            />
                        );
                    }}
                </Observer>
            </BillingUsersListPage>
            <Observer>
                {() => (
                    <Dialog
                        name="user-detailed"
                        className={styles['user-detailed__dialog']}
                        size={SIZE.LARGE}
                        onClose={() => keyboardRef.current.focus()}
                        closeable={detailed && !detailed.subscribing}
                        shouldBeClosed={handleDetailedCloseClick}
                        components={{
                            // eslint-disable-next-line react/prop-types
                            BodyWrapper: ({ children }) => (
                                <div className={styles['user-detailed__body']}>
                                    {children}
                                </div>
                            ),
                        }}
                    >
                        <BillingUserDetailed
                            id={detailed && detailed.id}
                            useStores={useStores}
                            store={detailed}
                            onClose={handleDetailedCancelClick}
                            onStatusChange={handleItemStatusChange}
                        />
                    </Dialog>
                )}
            </Observer>
            <Observer>
                {() => (
                    <>
                        <Dialog
                            className={styles['company-dialog']}
                            name="detailed-subscription"
                            size={SIZE.SMALL}
                            closeable={!companySelected?.subscribe?.form?.loading}
                        >
                            <BillingSubscribe
                                form={companySelected?.subscribe?.form || null}
                            />
                        </Dialog>
                        <Dialog
                            className={styles['company-dialog']}
                            name="detailed-debt"
                            size={SIZE.SMALL}
                            closeable={!companySelected?.debt?.form?.loading}
                        >
                            <BillingDebt
                                form={companySelected?.debt?.form || null}
                            />
                        </Dialog>
                    </>
                )}
            </Observer>
        </>
    );
};

BillingUsersListContent.propTypes = {
    store: PropTypes.oneOfType([
        PropTypes.instanceOf(BillingUsersListStore),
        PropTypes.shape({}),
    ]),
    meta: BillingUsersListPage.propTypes.meta,
};

BillingUsersListContent.defaultProps = {
    meta: BillingUsersListPage.defaultProps.meta,
    store: {
        tableHeaders: [],
        tableData: [],
        paginationHandler: { data: {} },
        load: () => null,
    },
};

export default BillingUsersListContent;
