export default [{
    type: 'fieldset',
    name: 'filter',
    fields: [{
        hidden: true,
        type: 'checkboxList',
        name: 'status',
        size: 'md',
        rows: 1,
        options: [{
            value: 'active',
            label: 'Активен',
        }, {
            value: 'expiring',
            label: 'Скоро истечет',
        }, {
            value: 'unpaid',
            label: 'Не оплачен',
        }, {
            value: 'blocked',
            label: 'Забанен',
        }, {
            value: 'archived',
            label: 'В архиве',
        }],
    }, {
        name: 'query',
        type: 'text',
        label: 'Поиск',
        placeholder: 'Компания, адрес, телефон',
        size: 'md',
        icon: 'search',
        rules: 'min:3',
    }],
}];
