import React from 'react';
import useStores from '../../hooks/operator/use-stores';
import requiresAuthorization from '../../hocs/requiresAuthorization';
import requiresNotAuthorized from '../../hocs/requiresNotAuthorized';
import { LoginPageContainer } from '../../containers/shared/LoginPage';
import Login from '../../containers/operator/Login';
import Root from '../../containers/operator/Root';
import ObjectListWrapper from '../../containers/operator/ObjectListPage/ObjectListWrapper';
import ObjectList from '../../containers/operator/ObjectListPage';
import OperatorList from '../../containers/operator/OperatorListPage';
import OperatorReport from '../../containers/operator/OperatorReportPage';
import OperatorLog from '../../containers/operator/OperatorLogPage';
import ActivationKeyList from '../../containers/operator/ActivationKeyListPage';
import directoryRoutes from './directory-routes';
import BillingCompaniesList from '../../containers/operator/BillingCompaniesList';
import BillingUsersList from '../../containers/operator/BillingUsersList';
import BillingPaymentsLog from '../../containers/operator/BillingPaymentsLog';
import BillingUsersLog from '../../containers/operator/BillingUsersLog';
import BillingWrapper from '../../containers/operator/BillingWrapper';
// icons
import {
    LayoutBlueIcon,
    ColumnsBlueIcon,
    WatchOrangeIcon,
    HomeBlueIcon,
    SettingsBlackIcon,
    CompanyIcon,
    WatchRubOrangeIcon,
    WatchUserOrangeIcon,
    SupportIcon,
    KeyIcon,
    ClipboardIcon,
    UserBlueIcon,
} from '../../components/Icons/icons';
import { PagesRouteInfo } from '../utils/pagesRouteInfo';

/**
 * @typedef {object} route
 * @property {string} title - route title
 * @property {string} path - relative path
 * @property {boolean} exact - strict check
 * @property {React.Component|React.FunctionComponent} component - react component
 * @property {React.Component|React.FunctionComponent} [icon] - route icon
 * @property {string} [pageTitle] - page title
 * @property {boolean} [addToBreadcrumbs] - flag to add title to breadcrumbs
 * @property {boolean} [isMenuGroup] - group to searchable select
 * @property {object} [extra] - any other data
 */

const routes = [{
    title: 'Авторизация',
    path: '/login',
    component: requiresNotAuthorized(LoginPageContainer, useStores),
    routes: [{
        title: 'Авторизация',
        path: '/auth',
        component: Login,
    }, {
        title: 'Авторизация',
        path: '/forgot',
        component: () => <>Забыли пароль</>,
    }],
}, {
    path: '/',
    component: requiresAuthorization(Root, useStores),
    routes: [{
        title: 'Парсер',
        addToBreadcrumbs: true,
        path: PagesRouteInfo.Parser.Root,
        routes: [{
            title: 'Выдача парсера',
            pageTitle: PagesRouteInfo.Parser.PageTitle,
            addToBreadcrumbs: true,
            path: '/list',
            icon: LayoutBlueIcon,
            routes: [{
                path: '/:dealType(sale|rent)/:objectType(flats|houses|steads|commercial)',
                component: ObjectList,
                extra: {
                    requestType: PagesRouteInfo.Parser.RequestType,
                },
            }],
        }, {
            title: 'Список досок',
            pageTitle: 'Список досок',
            addToBreadcrumbs: true,
            path: '/sources',
            icon: ColumnsBlueIcon,
            component: () => <>Список досок</>,
        }, {
            title: 'Логи',
            pageTitle: 'Логи',
            addToBreadcrumbs: true,
            path: '/logs',
            icon: WatchOrangeIcon,
            component: () => <>Логи</>,
        }, {
            title: 'Настройки',
            pageTitle: 'Настройки',
            addToBreadcrumbs: true,
            path: '/configuration',
            icon: SettingsBlackIcon,
            component: () => <>Настройки</>,
        }],
    }, {
        title: 'Поиск дубликатов',
        addToBreadcrumbs: true,
        path: PagesRouteInfo.Duplicates.Root,
        routes: [{
            title: 'Поиск дубликатов',
            pageTitle: PagesRouteInfo.Duplicates.PageTitle,
            addToBreadcrumbs: true,
            path: '/list',
            icon: LayoutBlueIcon,
            component: ObjectListWrapper,
            extra: {
                requestType: PagesRouteInfo.Duplicates.RequestType,
            },
            routes: [{
                path: '/:dealType(sale|rent)/:objectType(flats|houses|steads|commercial)',
                component: ObjectList,
                extra: {
                    requestType: PagesRouteInfo.Duplicates.RequestType,
                },
            }],
        }],
    }, {
        title: 'База объектов',
        addToBreadcrumbs: true,
        path: PagesRouteInfo.Base.Root,
        routes: [{
            title: 'Список объектов',
            pageTitle: PagesRouteInfo.Base.PageTitle,
            addToBreadcrumbs: true,
            path: '/list',
            icon: HomeBlueIcon,
            component: ObjectListWrapper,
            extra: {
                requestType: PagesRouteInfo.Base.RequestType,
            },
            routes: [{
                path: '/:dealType(sale|rent)/:objectType(flats|houses|steads|commercial)',
                component: ObjectList,
                extra: {
                    requestType: PagesRouteInfo.Base.RequestType,
                },
            }],
        }, {
            title: 'Логи действий с объектами',
            pageTitle: 'Логи действий с объектами',
            addToBreadcrumbs: true,
            path: '/logs',
            icon: WatchOrangeIcon,
            component: () => <>Логи действий с объектами</>,
        }],
    }, {
        title: 'Биллинг',
        path: '/billing',
        component: BillingWrapper,
        routes: [{
            title: 'Список компаний',
            icon: CompanyIcon,
            path: '/companies',
            routes: [{
                title: 'Список компаний',
                pageTitle: 'Список компаний',
                icon: null,
                addToBreadcrumbs: true,
                withPageTitle: true,
                path: '/list/:page?',
                component: BillingCompaniesList,
            }],
        }, {
            title: 'Список пользователей',
            icon: UserBlueIcon,
            path: '/users',
            routes: [{
                title: 'Список пользователей',
                pageTitle: 'Список пользователей',
                icon: null,
                addToBreadcrumbs: true,
                withPageTitle: true,
                path: '/list/:page?',
                component: BillingUsersList,
            }],
        }, {
            title: 'Логи оплаты',
            pageTitle: 'Логи оплаты',
            addToBreadcrumbs: true,
            path: '/payment-log/:page?',
            withPageTitle: true,
            icon: WatchRubOrangeIcon,
            component: BillingPaymentsLog,
        }, {
            title: 'Логи профилей',
            pageTitle: 'Логи профилей',
            addToBreadcrumbs: true,
            path: '/user-log/:page?',
            withPageTitle: true,
            icon: WatchUserOrangeIcon,
            component: BillingUsersLog,
        }],
    }, {
        title: 'Административная',
        addToBreadcrumbs: true,
        path: '/admin',
        showTools: false,
        routes: [{
            title: 'Список операторов',
            pageTitle: 'Список операторов',
            addToBreadcrumbs: true,
            path: '/operator-list/:page?',
            icon: SupportIcon,
            withPageTitle: true,
            component: OperatorList,
        }, {
            title: 'Список ключей',
            pageTitle: 'Список ключей',
            addToBreadcrumbs: true,
            path: '/key-list/:page?',
            icon: KeyIcon,
            withPageTitle: true,
            component: ActivationKeyList,
        }, {
            title: 'Отчеты',
            pageTitle: 'Отчеты',
            addToBreadcrumbs: true,
            path: '/operator-report/:page?',
            icon: ClipboardIcon,
            withPageTitle: true,
            component: OperatorReport,
        }, {
            title: 'Логи операторов',
            pageTitle: 'Логи операторов',
            addToBreadcrumbs: true,
            path: '/operator-logs/:page?',
            icon: WatchOrangeIcon,
            withPageTitle: true,
            component: OperatorLog,
        }],
    }, {
        title: 'Справочники',
        addToBreadcrumbs: true,
        path: '/reference',
        showTools: false,
        routes: [{
            title: 'Адресные справочники',
            pageTitle: 'Адресные справочники',
            addToBreadcrumbs: true,
            path: '/address',
            isMenuGroup: true,
            routes: directoryRoutes.addressDirectoryRoutes,
        }, {
            title: 'Характеристики объектов',
            pageTitle: 'Характеристики объектов',
            addToBreadcrumbs: true,
            path: '/simple',
            isMenuGroup: true,
            routes: directoryRoutes.simpleDirectoryRoutes,
        }, {
            title: 'Аренда',
            pageTitle: 'Аренда',
            addToBreadcrumbs: true,
            path: '/rent',
            isMenuGroup: true,
            routes: directoryRoutes.rentDirectoryRoutes,
        }, {
            title: 'Типы объектов',
            pageTitle: 'Типы объектов',
            addToBreadcrumbs: true,
            path: '/object',
            isMenuGroup: true,
            routes: directoryRoutes.objectDirectoryRoutes,
        }, {
            title: 'Статусы объектов',
            pageTitle: 'Статусы объектов',
            addToBreadcrumbs: true,
            path: '/object-status',
            isMenuGroup: true,
            routes: directoryRoutes.objectStatusDirectoryRoutes,
        }, {
            title: 'Контакты',
            pageTitle: 'Контакты',
            addToBreadcrumbs: true,
            path: '/contacts',
            isMenuGroup: true,
            routes: directoryRoutes.contactsDirectoryRoutes,
        }],
    }],
}];

export default routes;
