export default [{
    type: 'fieldset',
    name: 'filter',
    fields: [{
        name: 'query',
        type: 'text',
        label: 'Поиск',
        size: 'sm',
        icon: 'search',
        rules: 'min:3',
    }],
}];
