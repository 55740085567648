/* eslint-disable no-redeclare */
import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { Checkbox } from '../../../components/Input';
import FilterGroupsWrapper from '../../shared/FilterGroups';

import styles from './BillingCommon.module.scss';

const BillingCommonStatusFilter = ({
    title,
    filterForm,
    statusField,
    className,
}) => {
    const { name: fieldName } = statusField || {};
    const formField = filterForm.has(fieldName) ? filterForm.$(fieldName) : null;
    if (!formField) {
        return null;
    }

    const { options } = formField.get('extra');

    return (
        <FilterGroupsWrapper title={title} className={`${styles['filter-groups']} ${className || ''}`}>
            <Observer>
                {() => {
                    const selected = formField?.get('value') || [];

                    const handleChange = (e) => {
                        const { target: { value: current } } = e;
                        const selectedOptions = options.filter(
                            ({ value }) => selected.includes(value),
                        );
                        if (selected.includes(current)) {
                            formField.set(
                                'value',
                                selectedOptions
                                    .filter(({ value }) => value !== current)
                                    .map(({ value }) => value),
                            );
                            return filterForm.onSubmit(e);
                        }
                        formField.set('value', [...selectedOptions.map(({ value }) => value), current]);
                        return filterForm.onSubmit(e);
                    };

                    return (
                        Array.isArray(options) && options.map(
                            ({ value, label }) => (
                                <Checkbox
                                    disabled={formField.disabled || filterForm.loading}
                                    id={`billing-status-${value}`}
                                    className={styles['filter-groups__item']}
                                    name={`billing-status-${value}`}
                                    key={`billing-status-${value}`}
                                    value={value}
                                    onChange={handleChange}
                                    size={Checkbox.SIZE.MEDIUM}
                                    checked={selected.includes(value)}
                                >
                                    {label}
                                </Checkbox>
                            ),
                        )
                    );
                }}
            </Observer>
        </FilterGroupsWrapper>
    );
};

BillingCommonStatusFilter.propTypes = {
    title: PropTypes.string,
    filterForm: PropTypes.shape({
        $: PropTypes.func,
        has: PropTypes.func,
        onSubmit: PropTypes.func,
        loading: PropTypes.bool,
    }),
    statusField: PropTypes.shape({
        name: PropTypes.string,
    }),
    className: PropTypes.string,
};

BillingCommonStatusFilter.defaultProps = {
    title: '',
    filterForm: {
        $: () => null,
        has: () => null,
        subonSubmitmit: () => null,
    },
    statusField: null,
    className: '',
};

export default BillingCommonStatusFilter;
