import withStore from '../../../hocs/withStore';
import DirectoryStore from '../../../stores/operator/Directory/Directory';
import BaseStrategy, {
    CityStrategy,
    CityDistrictStrategy,
    HouseStrategy,
    AgencyStrategy,
    PhoneStrategy,
    CityDistrictLandmarkStrategy,
    StreetStrategy,
    ObjectStatusReasonStrategy,
} from '../../../stores/operator/Directory/strategy';
import DirectoryContent from './DirectoryContent';
import { DIRECTORIES } from '../../../constants/operator';

const createCityStore = (options) => () => new DirectoryStore(new CityStrategy(options));
const createCityDistrictStore = (options) => (
    () => new DirectoryStore(new CityDistrictStrategy(options))
);
const createStreetStore = (options) => () => new DirectoryStore(new StreetStrategy(options));
const createHouseStore = (options) => () => new DirectoryStore(new HouseStrategy(options));
const createPhoneStore = (options) => () => new DirectoryStore(new PhoneStrategy(options));
const createAgencyStore = (options) => () => new DirectoryStore(new AgencyStrategy(options));
const createCityDistrictLandmarkStore = (options) => () => (
    new DirectoryStore(new CityDistrictLandmarkStrategy(options))
);
const createObjectStatusReasonStore = (options) => () => (
    new DirectoryStore(new ObjectStatusReasonStrategy(options))
);
const createSimpleStore = (directory, options) => (
    () => new DirectoryStore(new BaseStrategy(directory, options))
);

const Directory = (props) => {
    const {
        meta: {
            extra: {
                directory,
                options,
            },
        },
    } = props;
    let createComponent;
    switch (directory) {
        case DIRECTORIES.CITY:
            createComponent = withStore(DirectoryContent, createCityStore(options));
            break;
        case DIRECTORIES.CITY_DISTRICT:
            createComponent = withStore(DirectoryContent, createCityDistrictStore(options));
            break;
        case DIRECTORIES.HOUSE:
            createComponent = withStore(DirectoryContent, createHouseStore(options));
            break;
        case DIRECTORIES.AGENCY:
            createComponent = withStore(DirectoryContent, createAgencyStore(options));
            break;
        case DIRECTORIES.PHONE_NUMBER:
            createComponent = withStore(DirectoryContent, createPhoneStore(options));
            break;
        case DIRECTORIES.CITY_DISTRICT_LANDMARK:
            createComponent = withStore(DirectoryContent, createCityDistrictLandmarkStore(options));
            break;
        case DIRECTORIES.STREET:
            createComponent = withStore(DirectoryContent, createStreetStore(options));
            break;
        case DIRECTORIES.OBJECT_STATUS_REASON:
            createComponent = withStore(DirectoryContent, createObjectStatusReasonStore(options));
            break;
        default:
            createComponent = withStore(DirectoryContent, createSimpleStore(directory, options));
            break;
    }
    return createComponent(props);
};

export default Directory;
