import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.CREATED,
    title: 'Дата',
    width: 12,
    textAlign: 'left',
}, {
    ...TABLE_CELL.CREATED_TIME,
    width: 12,
    textAlign: 'left',
}, {
    ...TABLE_CELL.OPERATOR_NAME,
    width: 17,
    textAlign: 'left',
}, {
    ...TABLE_CELL.ACTION,
    width: 13.5,
    textAlign: 'left',
}, {
    ...TABLE_CELL.OBJECT_ADDRESS,
    width: 22,
    textAlign: 'left',
}, {
    ...TABLE_CELL.DEAL_TYPE_NAME,
    width: 12,
    textAlign: 'left',
}, {
    ...TABLE_CELL.OBJECT_TYPE_NAME,
    textAlign: 'left',
}];
