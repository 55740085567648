import { action, computed, observable } from 'mobx';
import BaseItem from './BaseItem';
import { directory as forms } from '../../../../forms/operator';
import createForm from '../../../../helpers/Form';

class CityDistrictLandmarkItem extends BaseItem {
    @observable $city;

    @observable $cityDistrict;

    @computed
    get cityName() {
        const { $city: $val } = this;
        return $val
            ? `${$val.cityType.shortname} ${$val.name}`
            : null;
    }

    @computed
    get districtName() {
        const { $cityDistrict: $val } = this;
        return $val && $val.name ? $val.name : null;
    }

    @computed
    get cityId() {
        const { $city: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }

    @computed
    get cityDistrictId() {
        const { $cityDistrict: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }

    @action
    createItemForm() {
        const { parent: { strategy: { itemFormName } }, itemForm } = this;
        if (itemForm) return itemForm;
        this.itemForm = createForm(forms[itemFormName]);
        return this.itemForm;
    }
}

export default CityDistrictLandmarkItem;
