import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import ActivationKeyDetailedStore from '../../../stores/operator/ActivationKeyDetailed';
import ActivationKeyDetailedField from './ActivationKeyDetailedField';
import FormElements from '../Form';
import { Button } from '../../../components/Button';

import styles from './ActivationKeyDetailed.module.scss';

const ActivationKeyDetailed = ({
    store,
}) => {
    if (!store) {
        return null;
    }
    const { form } = store;
    return (
        <form onSubmit={form.onSubmit} className={styles['activation-key']}>
            <div className={styles['activation-key__fieldset']}>
                <FormElements
                    form={form}
                    elements={form.render}
                    components={{
                        FieldWrapper: ActivationKeyDetailedField,
                    }}
                />
            </div>
            <div className={styles['activation-key__submit']}>
                <Observer>
                    {() => (
                        <Button
                            type={Button.TYPE.SUBMIT}
                            color={Button.COLOR.GREEN}
                            size={Button.SIZE.LARGE}
                            disabled={form.loading}
                            block
                        >
                            {
                                store.isManual
                                    ? 'Сохранить'
                                    : 'Сгенерировать'
                            }
                        </Button>
                    )}
                </Observer>
            </div>
        </form>
    );
};

ActivationKeyDetailed.propTypes = {
    store: PropTypes.instanceOf(ActivationKeyDetailedStore),
};

ActivationKeyDetailed.defaultProps = {
    store: null,
};

export default ActivationKeyDetailed;
