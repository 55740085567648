import { NotepadService } from '../../../services/operator';
import NotepadList from '../../shared/NotepadList/NotepadList';

class OperatorNotepadList extends NotepadList {
    constructor() {
        super(NotepadService);
    }
}

export default OperatorNotepadList;
