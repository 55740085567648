import withStore from '../../../hocs/withStore';
import OperatorLogStore from '../../../stores/operator/OperatorLog';
import {
    FlatDetailed,
    HouseDetailed,
    SteadDetailed,
    CommercialDetailed,
} from '../../../stores/operator/RefactoredObjectDetailed';
import { withBaseDetailed } from '../../../stores/operator/RefactoredObjectDetailed/mixins';
import { withSaleDetailed, withRentDetailed } from '../../../stores/shared/RefactoredObjectDetailed/mixins';
import { PopupTabsWithApproveStore } from '../../../stores/shared/PopupTabsStore';
import OperatorLogContent from './OperatorLogContent';
import { OBJECT_TYPE, DEAL_TYPE } from '../../../constants/operator';

const detailedObjectFactory = (objectType, dealType) => {
    let Detailed;
    let withDealType;
    switch (objectType) {
        case OBJECT_TYPE.FLAT:
            Detailed = FlatDetailed;
            break;
        case OBJECT_TYPE.HOUSE:
            Detailed = HouseDetailed;
            break;
        case OBJECT_TYPE.STEAD:
            Detailed = SteadDetailed;
            break;
        case OBJECT_TYPE.COMMERCIAL:
            Detailed = CommercialDetailed;
            break;
        default:
            throw new Error('Unexpected object type');
    }
    switch (dealType) {
        case DEAL_TYPE.SALE:
            withDealType = withSaleDetailed;
            break;
        case DEAL_TYPE.RENT:
            withDealType = withRentDetailed;
            break;
        default:
            throw new Error('Unexpected deal type');
    }
    return withBaseDetailed(withDealType(Detailed));
};

const createOperatorLogStore = () => ({
    detailedObjectFactory,
    list: new OperatorLogStore(),
    tabs: new PopupTabsWithApproveStore(),
});

export default withStore(OperatorLogContent, createOperatorLogStore);
