import { TABLE_CELL } from '../../../../constants/operator';

const tableHeaders = [
    TABLE_CELL.ROW_SELECT,
    {
        name: 'nameFlat',
        title: 'Имя/Квартиры',
        width: 18,
        textAlign: 'left',
    }, {
        name: 'nameHouse',
        title: 'Имя/Дома',
        width: 18,
        textAlign: 'left',
    }, {
        name: 'nameStead',
        title: 'Имя/Земля',
        width: 12,
        textAlign: 'left',
    }, {
        name: 'nameCommertial',
        title: 'Имя/Нежилое',
        width: 12,
        textAlign: 'left',
    }, {
        name: 'phone',
        title: 'Телефон',
        width: 12,
        textAlign: 'left',
    }, {
        name: 'isAgent',
        title: 'Агент',
        width: 7,
    }, {
        name: 'totalLinked',
        title: 'Количество привязанных',
    },
];

export default tableHeaders;
