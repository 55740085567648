import forms from '../../shared/objects-filter';
import hightlightColor from './highlight-color';
import { DIRECTORIES } from '../../../constants/operator';

export default [forms.sourceBoard, {
    type: 'fieldset',
    name: 'hidden',
    hidden: true,
    fields: [{
        name: 'ownerTypeIds',
        type: 'directorySelect',
        hidden: true,
        directory: DIRECTORIES.OWNER_TYPE,
        multiple: true,
        clearable: true,
        isDropdown: true,
    }],
}, {
    type: 'fieldset', // ad
    name: 'ad',
    fields: [{
        name: 'objectStatusIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_STATUS,
        label: 'Статус',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        name: 'objectStatusReasonIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OBJECT_STATUS_REASON,
        label: 'Причина',
        multiple: true,
        clearable: true,
        searchable: true,
        isDropdown: true,
    }, {
        ...hightlightColor,
        directory: DIRECTORIES.PARSER_HIGHLIGHT_COLOR,
    }],
}, ...forms.flats, forms.dateCreated, {
    type: 'fieldset', // comment
    name: 'comment',
    fields: [{
        name: 'note',
        type: 'textarea',
        label: 'Комментарий',
        rows: 3,
    }],
}];
