export default [{
    type: 'fieldset',
    name: 'mainInfo',
    label: 'Основная информация',
    fields: [{
        type: 'fieldgroup',
        fields: [{
            type: 'text',
            name: 'lastName',
            label: 'Фамилия',
            rules: 'required',
        }, {
            type: 'text',
            name: 'firstName',
            label: 'Имя',
            rules: 'required',
        }, {
            type: 'text',
            name: 'patronymic',
            label: 'Отчество',
            rules: 'required',
        }, {
            type: 'phone',
            name: 'phone',
            label: 'Телефон',
            rules: 'required|phone',
        }, {
            type: 'text',
            name: 'email',
            label: 'E-mail',
            rules: 'required|email',
        }, {
            type: 'select',
            name: 'status',
            label: 'Статус',
            options: [{
                id: 1,
                value: 'ACTIVE',
                label: 'Активен',
            }, {
                id: 3,
                value: 'BLOCKED',
                label: 'Забанен',
            }, {
                id: 3,
                value: 'ARCHIVED',
                label: 'В архиве',
            }],
            rules: 'required',
        }, {
            type: 'numeric',
            name: 'plan',
            label: 'План',
            rules: 'required',
        }, {
            type: 'text',
            name: 'schedule',
            label: 'Расписание',
            rules: 'required',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'additionanInfo',
    label: 'Доп. информация',
    fields: [{
        name: 'operatorNoteWrapper',
        type: 'fieldgroup',
        fields: [{
            type: 'textarea',
            name: 'note',
            rules: 'required',
            label: 'Комментарий',
            rows: 5,
        }],
    }],
}, {
    type: 'fieldset',
    name: 'password',
    label: 'Пароль',
    fields: [{
        type: 'fieldgroup',
        fields: [{
            type: 'password',
            name: 'password',
            label: 'Пароль',
            rules: 'password|min:8',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'rights',
    label: 'Права оператора',
    fields: [{
        type: 'rights',
        name: 'rights',
    }],
}, {
    type: 'fieldset',
    name: 'companies',
    label: 'Компании',
    fields: [{
        name: 'attachedCompanies',
        type: 'companies',
    }],
}];
