import { computed, observable } from 'mobx';
import { withStatusField } from '../../../../helpers/Form/mixins';

const withBaseDetailed = (Store) => (
    class extends Store {
        @observable $addedAt;

        @observable $checkedAt;

        constructor(...rest) {
            super(...rest);
            this.requestType = 'base';
        }

        @computed
        get createdAt() {
            const { $addedAt } = this;
            return $addedAt;
        }

        @computed
        get checkedAt() {
            const { $checkedAt } = this;
            return $checkedAt;
        }

        createForm(mixins = []) {
            const { form } = this;
            if (form) {
                return form;
            }
            return super.createForm([
                withStatusField({
                    status: 'status',
                    reason: 'reason',
                }),
                ...mixins,
            ]);
        }

        prepareRequest(values) {
            const prepared = super.prepareRequest(values);
            return {
                ...prepared,
                status: {
                    statusId: values.status,
                    reasonId: values.reason,
                    comment: values.comment,
                },
            };
        }
    }
);

export default withBaseDetailed;
