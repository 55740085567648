import { TABLE_CELL } from '../../../../constants/operator';

const hourCell = {
    width: 4,
    title: 'hour',
};

const byHours = [{
    ...hourCell,
    name: `${hourCell.title}Till9`,
    title: 'До 9',
    width: 7,
}, ...Array(12).fill(0).map((v, index) => ({
    ...hourCell,
    title: `${index + 9}`,
    name: `${hourCell.title}${index + 9}`,
})), {
    ...hourCell,
    name: `${hourCell.title}After20`,
    title: 'После 20',
    width: 10,
}];

export default [{
    ...TABLE_CELL.OPERATOR_NAME,
    width: 20,
    textAlign: 'left',
}, ...byHours, {
    ...TABLE_CELL.OPERATOR_DONE,
    width: 10,
}, {
    ...TABLE_CELL.OPERATOR_PLAN,
    title: 'План',
    width: 7,
}];
