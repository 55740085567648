/* global alert */
import { toJS } from 'mobx';
import isEqual from 'lodash.isequal';
import { formatISO } from 'date-fns';
import { ObjectService, StorageService } from '../../../services/operator';
import forms from '../../../forms/operator/objects-filter';
import ObjectList from '../../shared/RefactoredObjectList/ObjectList';
import { DEAL_TYPE_MAP, OBJECT_TYPE_MAP } from '../../../constants/shared';

class OperatorObjectList extends ObjectList {
    constructor(stategy) {
        super(ObjectService, stategy, forms);
        this.restoreSort();
    }

    updatePhones(objectId, data) {
        const { strategy: { objectType, requestType } } = this;
        return this.dataSource.setPhones(
            objectId,
            data,
            { objectType, requestType },
        )
            .catch((e) => alert(e));
    }

    updateStatus(objectId, data) {
        const { strategy: { objectType, requestType } } = this;
        return this.dataSource.setStatus(
            objectId,
            data,
            { objectType, requestType },
        )
            .catch((e) => alert(e));
    }

    merge(objectId, data) {
        const { strategy: { objectType } } = this;
        return this.dataSource.merge(
            objectId,
            data,
            { objectType },
        )
            .catch((e) => alert(e));
    }

    storeSort() {
        const { strategy: { sortData, objectType, dealType }, storageName } = this;
        const sort = {
            ...toJS(sortData),
            objectType,
            dealType,
        };
        StorageService.add('sortData', { [storageName]: sort });
    }

    restoreSort() {
        const { storageName } = this;
        const savedSortData = StorageService('sortData');
        if (savedSortData && savedSortData[storageName]) {
            const { field, direction } = savedSortData[storageName];
            this.setSort(field, direction);
        }
    }

    setSort(field, direction) {
        super.setSort(field, direction);
        this.storeSort();
    }

    getPresetFilterData() {
        const { strategy: { objectType, dealType } } = this;
        return super.getPresetFilterData()
            .then(({ filter, currentFilter }) => {
                const { statusIds, ...currentFilterWithoutStatusIds } = currentFilter;
                return {
                    filter: currentFilterWithoutStatusIds,
                    objectType: OBJECT_TYPE_MAP[objectType],
                    dealType: DEAL_TYPE_MAP[dealType],
                    viewedAt: isEqual(filter, currentFilter)
                        ? formatISO(this.viewedAt)
                        : null,
                };
            });
    }
}

export default OperatorObjectList;
