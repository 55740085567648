import { action, observable } from 'mobx';
import { ListItem } from '../../../shared/List';
import { directory as forms } from '../../../../forms/operator';
import createForm from '../../../../helpers/Form';
import forEachField from '../../../../forms/utils/for-each-field';

class BaseItem extends ListItem {
    @observable itemForm;

    @action
    prepareForm() {
        let { itemForm } = this;
        if (!itemForm) {
            itemForm = this.createItemForm();
            this.initItemFormListeners();
        }
        this.setItemFormValues();
        return itemForm;
    }

    @action
    createItemForm() {
        const {
            parent: {
                strategy: {
                    itemFormName,
                    options: { localeName, maxNameLength, maxShortNameLength },
                },
            },
            itemForm,
        } = this;
        const form = forms[itemFormName];
        if (itemForm) return itemForm;
        this.itemForm = createForm(form);
        const nameField = this.itemForm.has('name') ? this.itemForm.$('name') : null;
        const shortNameField = this.itemForm.has('shortname') ? this.itemForm.$('shortname') : null;
        if (nameField && localeName) {
            nameField.set('label', localeName);
        }
        if (nameField && maxNameLength) {
            nameField.set('rules', `required|max:${maxNameLength}`);
        }
        if (shortNameField && maxShortNameLength) {
            shortNameField.set('extra', {
                ...shortNameField.extra,
                hidden: false,
            });
            shortNameField.set('rules', `max:${maxShortNameLength}`);
        }
        return this.itemForm;
    }

    @action
    setItemFormValues() {
        const { parent: { strategy: { itemFormName } }, itemForm } = this;
        const editFields = forms[itemFormName];
        forEachField(editFields, (field) => {
            if (Object.getOwnPropertyDescriptor(this, field.name)) {
                // eslint-disable-next-line no-param-reassign
                if (this[field.name]) {
                    itemForm.$(field.name).set('value', this[field.name]);
                }
            }
        });
    }

    initItemFormListeners() {
        const {
            parent,
            itemForm,
            id,
        } = this;
        itemForm.on('success', (values) => (
            parent.update(id, itemForm.clearValues(values))
                .then((response) => {
                    this.setData(response);
                    return Promise.resolve(response);
                })
        ));
    }
}

export default BaseItem;
