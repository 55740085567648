export default [{
    name: 'agencyAliases',
    size: 'md',
    type: 'text',
    label: 'Название агентства',
    rules: 'required|max:70',
}, {
    name: 'phone',
    size: 'md',
    type: 'phone',
    label: 'Номер телефона',
    rules: 'required|phone',
}];
