import React from 'react';
import PropTypes from 'prop-types';
import FormElements from '../Form';
import Label from '../../../components/Label';

import styles from './ActivationKeyList.module.scss';

const ActivationKeyStatusField = ({
    label,
    id,
    children,
}) => (
    <div className={styles['key-status__field']}>
        {
            label
                ? (
                    <Label
                        className={`
                            ${styles['key-status__label']}
                        `}
                        htmlFor={id}
                    >
                        {label}
                    </Label>
                )
                : null
        }
        {children}
    </div>
);

ActivationKeyStatusField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    children: PropTypes.element.isRequired,
};

ActivationKeyStatusField.defaultProps = {
    label: '',
};

const ActivationKeyStatus = ({ form, loading }) => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
        role="tooltip"
        onClick={(e) => e.stopPropagation()}
        onKeyPress={() => {}}
    >
        <form
            onSubmit={form.onSubmit}
            className={styles['key-status']}
        >
            <div
                className={`
                    ${styles['key-status__field-list']}
                    ${loading ? styles['key-status__field-list--disabled'] : ''}
                `}
            >
                <FormElements
                    form={form}
                    elements={form.render}
                    components={{
                        FieldWrapper: ActivationKeyStatusField,
                    }}
                />
            </div>
        </form>
    </div>
);

ActivationKeyStatus.propTypes = {
    form: PropTypes.shape({
        onSubmit: PropTypes.func,
        render: PropTypes.arrayOf(
            PropTypes.shape({}),
        ),
    }).isRequired,
    loading: PropTypes.bool,
};

ActivationKeyStatus.defaultProps = {
    loading: false,
};

export default ActivationKeyStatus;
