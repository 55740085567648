import React from 'react';
import PropTypes from 'prop-types';
import FormElements from '../Form';
import BillingActionsField from './BillingActionsField';
import { Button } from '../../../components/Button';

import styles from './BillingActions.module.scss';

const BillingSubscribe = ({ form, footer }) => (
    form && (
        <form onSubmit={form.onSubmit} className={styles.subscribe}>
            <div className={styles.header}>
                Продлить подписку
            </div>
            <FormElements
                form={form}
                elements={form.render}
                components={{
                    FieldWrapper: BillingActionsField,
                }}
            />
            <Button
                className={styles.subscribe__submit}
                type={Button.TYPE.SUBMIT}
                color={Button.COLOR.GREEN}
                size={Button.SIZE.LARGE}
                block
                disabled={form.loading}
            >
                Продлить подписку
            </Button>
            {footer}
        </form>
    )
);

BillingSubscribe.propTypes = {
    form: PropTypes.shape({
        render: PropTypes.arrayOf(PropTypes.shape({})),
        onSubmit: PropTypes.func,
        loading: PropTypes.bool,
    }),
    footer: PropTypes.element,
};

BillingSubscribe.defaultProps = {
    form: null,
    footer: null,
};

export default BillingSubscribe;
