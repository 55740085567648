import BaseStrategy from './BaseStrategy';
import CityStrategy from './CityStrategy';
import HouseStrategy from './HouseStrategy';
import PhoneStrategy from './PhoneStrategy';
import AgencyStrategy from './AgencyStrategy';
import CityDistrictLandmarkStrategy from './CityDistrictLandmarkStrategy';
import CityDistrictStrategy from './CityDistrictStrategy';
import StreetStrategy from './StreetStrategy';
import ObjectStatusReasonStrategy from './ObjectStatusReasonStrategy';

export default BaseStrategy;
export {
    CityStrategy,
    HouseStrategy,
    PhoneStrategy,
    AgencyStrategy,
    CityDistrictLandmarkStrategy,
    CityDistrictStrategy,
    StreetStrategy,
    ObjectStatusReasonStrategy,
};
