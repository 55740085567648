export default [{
    type: 'fieldset',
    name: 'subscribe',
    fields: [{
        type: 'select',
        name: 'days',
        label: 'Продлить подписку',
        size: 'md',
        value: {
            value: 30,
            label: '1 месяц',
        },
        options: [{
            value: 1,
            label: '1 день',
        }, {
            value: 7,
            label: 'неделя',
        }, {
            value: 30,
            label: '1 месяц',
        }, {
            value: 90,
            label: '3 месяца',
        }, {
            value: 180,
            label: 'полгода',
        }],
    }],
}];
