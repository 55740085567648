/* global alert */
import { action, computed, observable } from 'mobx';
import DataSource from '../../shared/DataSource';
import createForm from '../../../helpers/Form';
import { ActivationKeyService } from '../../../services/operator';
import { activationKeyDetailed as activationKeyDetailedFields } from '../../../forms/operator';

class ActivationKeyDetailed {
    form;

    @observable key;

    constructor() {
        this.dataSource = new DataSource(ActivationKeyService);
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    @computed
    get isManual() {
        const { form } = this;
        return Boolean(form.$('manualKey').get('value'));
    }

    createForm(mixins = []) {
        const { form } = this;
        if (form) {
            return form;
        }
        this.form = createForm(
            activationKeyDetailedFields,
            mixins,
        );
        this.initFormListeners();
        return this.form;
    }

    initFormListeners() {
        const {
            form,
        } = this;
        const manualKeyField = form.$('manualKey');
        const keyField = form.$('value');
        form.on('success', (values) => {
            const request = this.prepareRequest(values);
            return this.dataSource.createKey(request)
                .then(({ value: key }) => {
                    this.setKey(key);
                    return Promise.resolve(key);
                })
                .catch((e) => alert(e));
        });
        manualKeyField.observe(({ change: { newValue: value } }) => {
            const extra = keyField.get('extra');
            keyField.set('disabled', !value);
            keyField.set('extra', {
                ...extra,
                hidden: !value,
            });
        });
    }

    prepareRequest({ manualKey, ...values }) {
        const { form } = this;
        const request = form.nullValues(values);
        return {
            ...request,
            maxCompanyUseCount: request.maxCompanyUseCount || 0,
            maxUserUseCount: request.maxUserUseCount || 0,
        };
    }

    @action
    setKey(key) {
        this.key = key;
    }
}

export default ActivationKeyDetailed;
