import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import {
    SelectedHighlight,
    SelectedRow,
    SelectedTitle,
    SelectedWrapper,
} from '../../../components/TableSelected';
import { IconButton } from '../../../components/Button';
import SVGIcon from '../../../components/Icons/SVGIcon';
import Phone from '../../../components/Phone';
import {
    DoubleArrowGreenIcon,
    UserBlueIcon,
    BanIcon,
    ClockIcon,
} from '../../../components/Icons/icons';
import { EMPTY_DATA_STRING } from '../../../constants/shared';

import styles from './BillingUsersList.module.scss';

const BillingUserSelected = ({
    store,
    style,
    onClick,
    onSubscriptionClick,
    onDebtClick,
}) => {
    const handleClick = () => {
        onClick({ original: { id: store.id } });
    };

    return (
        <Observer>
            {() => (
                <SelectedWrapper style={style} loading={store.loading}>
                    <Observer>
                        {() => (
                            <div key={store.memoKey}>
                                <SelectedTitle>
                                    Инофрмация по пользователю
                                    &nbsp;
                                    <SelectedHighlight color={SelectedHighlight.COLOR.GREEN}>
                                        {store.name}
                                    </SelectedHighlight>
                                    <IconButton
                                        className={styles.selected__detailed}
                                        color={IconButton.COLOR.WHITE_BLUE}
                                        size={IconButton.SIZE.SMALL}
                                        icon={<SVGIcon SVGElement={UserBlueIcon} />}
                                        onClick={handleClick}
                                    >
                                        Полный профиль
                                    </IconButton>
                                    <span className={`${styles.selected__ban} ${store.statusKey === 'disabled' && styles['selected__ban--banned']}`}>
                                        <SVGIcon
                                            SVGElement={BanIcon}
                                        />
                                    </span>
                                </SelectedTitle>
                                <div className={styles.selected}>
                                    <div className={`${styles.selected__col} ${styles['selected__col--name']}`}>
                                        <SelectedRow
                                            name="ФИО"
                                            classNames={{
                                                row: styles.selected__row,
                                                name: `${styles.selected__name} ${styles['selected__name--name']}`,
                                                value: `${styles.selected__value} ${styles['selected__value--name']}`,
                                            }}
                                        >
                                            {store.fullName}
                                        </SelectedRow>
                                    </div>
                                    <div className={`${styles.selected__col} ${styles['selected__col--contacts']}`}>
                                        <SelectedRow
                                            name="Работник фирмы"
                                            classNames={{
                                                row: styles.selected__row,
                                                name: styles.selected__name,
                                                value: styles.selected__value,
                                            }}
                                        >
                                            {store.companyName}
                                        </SelectedRow>
                                        <SelectedRow
                                            name="Продлен до"
                                            classNames={{
                                                row: styles.selected__row,
                                                name: styles.selected__name,
                                                value: styles.selected__value,
                                            }}
                                        >
                                            {store.activeUntil}
                                        </SelectedRow>
                                    </div>
                                    <div className={styles.selected__col}>
                                        <SelectedRow
                                            name="Зарегистрирован"
                                            classNames={{
                                                row: styles.selected__row,
                                                name: styles.selected__name,
                                                value: styles.selected__value,
                                            }}
                                        >
                                            {store.registeredAt}
                                        </SelectedRow>
                                        <SelectedRow
                                            name="Дата обновления"
                                            classNames={{
                                                row: styles.selected__row,
                                                name: styles.selected__name,
                                                value: styles.selected__value,
                                            }}
                                        >
                                            {store.updatedAt}
                                        </SelectedRow>
                                    </div>
                                </div>
                                <div className={styles.hr} />
                                <div className={styles.selected}>
                                    <div className={`${styles.selected__col} ${styles['selected__col--company-name']}`}>
                                        <SelectedTitle>
                                            Компания
                                            &nbsp;
                                            <SelectedHighlight
                                                color={SelectedHighlight.COLOR.GREEN}
                                            >
                                                {store.companyName}
                                            </SelectedHighlight>
                                        </SelectedTitle>
                                    </div>
                                    <div className={`${styles.selected__col} ${styles['selected__col--contacts']}`}>
                                        <SelectedRow
                                            name="Телефон"
                                            classNames={{
                                                row: styles.selected__row,
                                                name: styles.selected__name,
                                                value: styles.selected__value,
                                            }}
                                        >
                                            {
                                                store.companyPhone
                                                    ? (
                                                        <Phone
                                                            copy
                                                            className={styles.selected__phone}
                                                        >
                                                            {store.companyPhone}
                                                        </Phone>
                                                    )
                                                    : EMPTY_DATA_STRING
                                            }
                                        </SelectedRow>
                                        <SelectedRow
                                            name="Почта"
                                            classNames={{
                                                row: styles.selected__row,
                                                name: styles.selected__name,
                                                value: styles.selected__value,
                                            }}
                                        >
                                            <a href={`mailto:${store.companyEmail}`} className={styles.selected__email}>
                                                {store.companyEmail}
                                            </a>
                                        </SelectedRow>
                                        <SelectedRow
                                            name="Адрес"
                                            classNames={{
                                                row: styles.selected__row,
                                                name: styles.selected__name,
                                                value: styles.selected__value,
                                            }}
                                        >
                                            {store.companyAddress}
                                        </SelectedRow>
                                    </div>
                                    <div className={`${styles.selected__col} ${styles['selected__col--action']}`}>
                                        <IconButton
                                            className={styles.selected__approve}
                                            color={IconButton.COLOR.WHITE_GREEN}
                                            size={IconButton.SIZE.MEDIUM}
                                            icon={<SVGIcon SVGElement={DoubleArrowGreenIcon} />}
                                            onClick={onSubscriptionClick}
                                        >
                                            Продлить
                                        </IconButton>
                                        <IconButton
                                            className={styles.selected__promise}
                                            color={IconButton.COLOR.WHITE_ORANGE}
                                            size={IconButton.SIZE.MEDIUM}
                                            icon={<SVGIcon SVGElement={ClockIcon} />}
                                            onClick={onDebtClick}
                                        >
                                            Обещанный платеж
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Observer>
                </SelectedWrapper>
            )}
        </Observer>
    );
};

BillingUserSelected.propTypes = {
    store: PropTypes.shape({
        memoKey: PropTypes.number,
        loading: PropTypes.bool,
        id: PropTypes.number,
        name: PropTypes.string,
        fullName: PropTypes.string,
        companyName: PropTypes.string,
        activeUntil: PropTypes.string,
        updatedAt: PropTypes.string,
        registeredAt: PropTypes.string,
        statusKey: PropTypes.string,
        companyAddress: PropTypes.string,
        companyEmail: PropTypes.string,
        companyPhone: PropTypes.string,
    }).isRequired,
    style: PropTypes.shape({}),
    onClick: PropTypes.func,
    onSubscriptionClick: PropTypes.func,
    onDebtClick: PropTypes.func,
};

BillingUserSelected.defaultProps = {
    style: {},
    onClick: () => null,
    onSubscriptionClick: () => null,
    onDebtClick: () => null,
};

export default BillingUserSelected;
