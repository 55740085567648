import BaseStrategy from './BaseStrategy';
import { StreetItem } from '../item';
import { DIRECTORIES } from '../../../../constants/operator';

class StreetStrategy extends BaseStrategy {
    constructor(options) {
        super(DIRECTORIES.STREET, options);
        this.Item = StreetItem;
    }

    // eslint-disable-next-line class-methods-use-this
    prepareItemData(data) {
        const { aliases, ...rest } = data;
        let preparedAliases;
        if (typeof aliases === 'string') {
            preparedAliases = aliases.split(';').filter((a) => !!a);
            preparedAliases.forEach((alias, i) => {
                preparedAliases[i] = alias.replace(/\s+/g, ' ').trim();
            });
        }
        return {
            aliases: preparedAliases || [],
            ...rest,
        };
    }
}

export default StreetStrategy;
