export default [{
    type: 'fieldset',
    name: 'mainInfo',
    label: 'Основная информация',
    fields: [{
        type: 'text',
        name: 'hardwareName',
        label: 'ID Компьютера',
        rules: 'required|max:1024',
    }],
}, {
    type: 'fieldset',
    name: 'contacts',
    label: 'Контакты',
    fields: [{
        type: 'text',
        name: 'address',
        label: 'Адрес',
        rules: 'required|max:50',
    }],
}, {
    type: 'fieldset',
    name: 'security',
    label: 'Безопасность',
    fields: [{
        name: 'billingCompanyContacts',
        type: 'fieldgroup',
        fields: [{
            type: 'integer',
            name: 'maxDevicesPerMonth',
            rules: 'required',
            label: 'Новых устройств в месяц',
        }, {
            type: 'integer',
            name: 'maxDeviceChangesPerMonth',
            rules: 'required',
            label: 'Смен параметров устройства в месяц',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'chat-bot',
    label: 'Телеграм-бот',
    fields: [],
}];
