import React from 'react';
import PropTypes from 'prop-types';
import { EMPTY_DATA_STRING } from '../../../constants/shared';

import styles from './BillingCompaniesList.module.scss';

const BillingCompaniesListStatusCell = ({ value }) => {
    if (!value) {
        return EMPTY_DATA_STRING;
    }
    const { name, key } = value;
    return (
        <div
            className={`${styles.status} ${styles[`status--${key}`]}`}
        >
            {name}
        </div>
    );
};

BillingCompaniesListStatusCell.propTypes = {
    value: PropTypes.shape({
        name: PropTypes.string,
        key: PropTypes.string,
    }).isRequired,
};

export default BillingCompaniesListStatusCell;
