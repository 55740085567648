import { computed } from 'mobx';
import capitalizeFirstLetter from '../../../utils/capitalize-first-letter';
import ObjectList from './ObjectList';
import PaginationAsCursor from '../../shared/DataSource/entities/PaginationAsCursor';
import tables from './tables';

class SimilarObjectList extends ObjectList {
    constructor(strategy, id, parent, requestType) {
        super(strategy);
        this.id = id;
        this.parent = parent;
        this.requestType = requestType;
        this.paginationHandler = new PaginationAsCursor();
    }

    get $tableHeaders() {
        const { strategy: { sortData, objectType } } = this;
        const headers = tables[`similar${capitalizeFirstLetter(objectType)}`];
        return headers.map((header) => ({
            ...header,
            sortDirection: sortData?.field === header.sortName ? sortData.direction : undefined,
        }));
    }

    @computed
    get tableHeaders() {
        return this.$tableHeaders;
    }

    @computed
    get tableData() {
        return this.list;
    }

    @computed
    get parentObjectName() {
        const { parent } = this;
        return parent.objectName;
    }

    get storageName() {
        return `similar${capitalizeFirstLetter(super.storageName)}`;
    }

    createFilterForm() {
        return this.filterForm;
    }

    getList(page, filter) {
        const { strategy: { objectType }, id, requestType } = this;
        return this.dataSource.getSimilarObjects(
            id,
            page,
            {
                objectType,
                requestType,
                sortFilter: filter?.sort?.length ? {
                    sortName: filter.sort[0].name,
                    sortOrder: filter.sort[0].order,
                } : undefined,
            },
        );
    }

    reload() {
        const { paginationHandler, strategy } = this;
        paginationHandler.setPaginationData(undefined);
        this.setList([]);
        return strategy.prepareFilterData()
            .then((data) => super.load(data))
            .then((list) => {
                this.setList(list);
            })
            .catch((e) => this.dataSource.setError(e));
    }

    loadMore() {
        const { paginationHandler } = this;
        if (paginationHandler.isEnd) {
            return Promise.resolve();
        }
        return this.load();
    }

    createDetailed() {
        const { parent } = this;
        const Detailed = parent.constructor;
        return new Detailed();
    }

    toItem(data) {
        return super.toItem({ ...data, idModifier: 'similar' });
    }
}

export default SimilarObjectList;
