export default [{
    type: 'fieldset', // ad
    name: 'location',
    fields: [{
        name: 'name',
        size: 'md',
        type: 'text',
        label: 'Ориентир',
        rules: 'required|max:128',
    }, {
        name: 'description',
        size: 'md',
        type: 'textarea',
        label: 'Описание',
        rows: 2,
        rules: 'max:512',
    }],
}];
