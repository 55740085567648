import { computed, observable } from 'mobx';
import BaseItem from './BaseItem';

class PhoneItem extends BaseItem {
    @observable $aliases;

    @observable $phone;

    @computed
    get phone() {
        const { $phone } = this;
        return $phone;
    }

    @computed
    get isAgent() {
        const { $isAgent } = this;
        return $isAgent ? 'Да' : 'Нет';
    }

    @computed
    get nameFlat() {
        const { $contact } = this;
        return $contact?.flat;
    }

    @computed
    get nameHouse() {
        const { $contact } = this;
        return $contact?.house;
    }

    @computed
    get nameCommertial() {
        const { $contact } = this;
        return $contact?.commertial;
    }

    @computed
    get nameStead() {
        const { $contact } = this;
        return $contact?.stead;
    }

    @computed
    get totalLinked() {
        const { $totalLinked } = this;
        return $totalLinked || '0';
    }
}

export default PhoneItem;
