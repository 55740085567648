import { action, computed, observable } from 'mobx';

class Pagination {
    pageNeighbours = 0;

    itemsPerPage = 0;

    @observable currentPage = 0;

    @observable totalPages = 0;

    @observable totalItems = 0;

    constructor(pageNeighbours = 2) {
        this.pageNeighbours = pageNeighbours;
    }

    @computed
    get data() {
        return {
            totalItems: this.totalItems,
            itemsPerPage: this.itemsPerPage,
            currentPage: this.currentPage,
            pageNeighbours: this.pageNeighbours,
        };
    }

    setPageNeighbours(pageNeighbours) {
        this.pageNeighbours = pageNeighbours;
    }

    handleResponse({
        meta: {
            itemsPerPage,
            itemsTotal,
            currentPage,
            totalPages,
        },
        data,
    }) {
        this.setPaginationData(
            itemsPerPage,
            itemsTotal,
            currentPage,
            totalPages,
        );
        return Promise.resolve(data);
    }

    // eslint-disable-next-line class-methods-use-this
    prepareRequest(...request) {
        return Promise.resolve(request);
    }

    @action
    setPaginationData(
        itemsPerPage,
        itemsTotal,
        currentPage,
        totalPages,
    ) {
        this.itemsPerPage = Number(itemsPerPage);
        this.totalItems = Number(itemsTotal);
        this.currentPage = Number(currentPage);
        this.totalPages = Number(totalPages);
    }
}

export default Pagination;
