import { DIRECTORIES } from '../../constants/operator';
import Directory from '../../containers/operator/DirectoryPage';

const {
    ADDRESS_DIRECTORIES: addressDirectories,
    SIMPLE_DIRECTORIES: simpleDirectories,
} = DIRECTORIES;

const createRoute = ({
    directory,
    title,
    localeName,
    maxNameLength,
    maxShortNameLength,
    ...route
}) => ({
    addToBreadcrumbs: true,
    component: Directory,
    path: `/${directory}/:page?`,
    extra: { directory, options: { localeName, maxNameLength, maxShortNameLength } },
    pageTitle: title,
    title,
    withPageTitle: true,
    ...route,
});

export default {
    addressDirectoryRoutes: [
        createRoute({
            directory: addressDirectories.CITY,
            title: 'Населенные пункты',
            localeName: 'Нас. пункт',
        }),
        createRoute({
            directory: addressDirectories.CITY_DISTRICT,
            title: 'Районы',
            localeName: 'Район',
        }),
        createRoute({
            directory: addressDirectories.CITY_DISTRICT_LANDMARK,
            title: 'Ориентиры',
        }),
        createRoute({
            directory: addressDirectories.STREET,
            title: 'Улицы',
        }),
        createRoute({
            directory: addressDirectories.HOUSE,
            title: 'Дома',
        }),
    ],
    simpleDirectoryRoutes: [
        createRoute({
            directory: simpleDirectories.COMPLEX,
            title: 'Комплексы',
            localeName: 'Комплекс',
            maxNameLength: 255,
        }),
        createRoute({
            directory: simpleDirectories.DEVELOPER,
            title: 'Застройщики',
            localeName: 'Застройщик',
            maxNameLength: 255,
        }),
        createRoute({
            directory: simpleDirectories.HOUSE_TYPE,
            title: 'Типы домов',
            localeName: 'Тип',
        }),
        createRoute({
            directory: simpleDirectories.PHONE_TYPE,
            title: 'Тип номера телефона',
            localeName: 'Тип',
            maxNameLength: 64,
        }),
        createRoute({
            directory: simpleDirectories.BUILDING,
            title: 'Постройкам на земельном участке',
            localeName: 'Постройки',
            maxNameLength: 128,
        }),
        createRoute({
            directory: simpleDirectories.BALCONY,
            title: 'Балконы',
            localeName: 'Балкон',
            maxNameLength: 128,
            maxShortNameLength: 16,
        }),
        createRoute({
            directory: simpleDirectories.FURNISHING,
            title: 'Мебелировка',
            localeName: 'Мебелировка',
            maxNameLength: 128,
            maxShortNameLength: 32,
        }),
        createRoute({
            directory: simpleDirectories.FURNITURE,
            title: 'Мебель',
            localeName: 'Наличие',
            maxNameLength: 128,
            maxShortNameLength: 32,
        }),
        createRoute({
            directory: simpleDirectories.SERIES,
            title: 'Серия',
            localeName: 'Серия',
            maxNameLength: 128,
        }),
        createRoute({
            directory: simpleDirectories.WALL_MATERIAL,
            title: 'Метариал стен',
            localeName: 'Материал',
            maxNameLength: 128,
        }),
        createRoute({
            directory: simpleDirectories.WATER_SUPPLY,
            title: 'Водоснабжение',
            localeName: 'Тип',
            maxNameLength: 64,
        }),
        createRoute({
            directory: simpleDirectories.WINDOW_TYPE,
            title: 'Тип окон',
            localeName: 'Тип окон',
            maxNameLength: 128,
            maxShortNameLength: 16,
        }),
        createRoute({
            directory: simpleDirectories.OWNER_TYPE,
            title: 'Тип собственника',
            localeName: 'Собственник',
            maxNameLength: 32,
        }),
        createRoute({
            directory: simpleDirectories.EFFLUENT,
            title: 'Наличие стока',
            localeName: 'Сток',
            maxNameLength: 64,
        }),
        createRoute({
            directory: simpleDirectories.HEATING,
            title: 'Отопление',
            localeName: 'Отопление',
            maxNameLength: 128,
        }),
        createRoute({
            directory: simpleDirectories.LAVATORY,
            title: 'Сан.узел',
            localeName: 'Сан.узел',
            maxNameLength: 128,
        }),
        createRoute({
            directory: simpleDirectories.PASSAGE,
            title: 'Подъезд',
            localeName: 'Подъезд',
            maxNameLength: 128,
        }),
        createRoute({
            directory: simpleDirectories.OBJECT_CONDITION,
            title: 'Состояние объекта',
            localeName: 'Состояние',
            maxNameLength: 128,
            maxShortNameLength: 32,
        }),
        createRoute({
            directory: simpleDirectories.OBJECT_ROOM_TYPE,
            title: 'Типы комнат',
            localeName: 'Тип',
            maxNameLength: 128,
            maxShortNameLength: 16,
        }),
        createRoute({
            directory: simpleDirectories.STOVE_TYPE,
            title: 'Тип плиты',
            localeName: 'Тип',
            maxNameLength: 128,
            maxShortNameLength: 16,
        }),
    ],
    rentDirectoryRoutes: [
        createRoute({
            directory: simpleDirectories.RENT_FLAT_TYPE,
            title: 'Аренда квартиры',
            localeName: 'Тип',
            maxNameLength: 64,
        }),
        createRoute({
            directory: simpleDirectories.RENT_PERIOD,
            title: 'Период аренды',
            localeName: 'Период',
            maxNameLength: 64,
        }),
        createRoute({
            directory: simpleDirectories.RENT_SERVICE,
            title: 'Ком. услуги',
            localeName: 'Тип',
            maxNameLength: 255,
            maxShortNameLength: 32,
        }),
        createRoute({
            directory: simpleDirectories.RENT_CONDITION,
            title: 'Условия аренды',
            localeName: 'Условие',
            maxNameLength: 255,
        }),

    ],
    objectDirectoryRoutes: [
        createRoute({
            directory: simpleDirectories.OBJECT_FLAT_TYPE,
            title: 'Типы квартир',
            localeName: 'Тип',
            maxNameLength: 64,
        }),
        createRoute({
            directory: simpleDirectories.OBJECT_COMMERCIAL_TYPE,
            title: 'Типы коммерческой недвижимости',
            localeName: 'Тип',
            maxNameLength: 64,
        }),
        createRoute({
            directory: simpleDirectories.OBJECT_HOUSE_TYPE,
            title: 'Типы домов',
            localeName: 'Тип',
            maxNameLength: 64,
        }),
        createRoute({
            directory: simpleDirectories.OBJECT_STEAD_TYPE,
            title: 'Типы земельных участков',
            localeName: 'Тип',
            maxNameLength: 128,
            maxShortNameLength: 32,
        }),
    ],
    objectStatusDirectoryRoutes: [
        createRoute({
            directory: simpleDirectories.OBJECT_STATUS_REASON,
            title: 'Причины смены статуса',
            localeName: 'Причина',
        }),
    ],
    contactsDirectoryRoutes: [
        createRoute({
            directory: addressDirectories.AGENCY,
            title: 'Агентства',
            localeName: 'Название',
            maxNameLength: 70,
        }),
        createRoute({
            directory: simpleDirectories.PHONE_NUMBER,
            title: 'Телефоны',
            localeName: 'Номер или имя',
        }),
    ],
};
