import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/Icons/SVGIcon';
import DetailedView from '../../shared/DetailedView';
import DetailedViewFieldset from '../../shared/DetailedView/DetailedViewFieldset';
import { CrossIcon } from '../../../components/Icons/icons';
import { Button, IconButton } from '../../../components/Button';
import styles from './BillingUserDetailed.module.scss';

const BillingUserChatbotSection = (props) => {
    const { store, fieldset } = props;
    const { telegramUsers } = store;

    const [chatIdToDelete, setChatIdToDelete] = useState(null);

    const handleDeleteTelegramAccount = () => {
        setChatIdToDelete(null);
        store.deleteTelegramAccount(chatIdToDelete);
    };

    return (
        <div className={styles.chatbot}>
            <DetailedViewFieldset fieldset={fieldset}>
                {telegramUsers.length ? (

                    <table>
                        <tbody>
                            {telegramUsers.map((user, index) => (
                                <tr key={user.userId}>
                                    <td>
                                        {index + 1}
                                        .
                                    </td>
                                    <td>
                                        {!!user.userName && (
                                            <b>
                                                {user.userName}
                                                &nbsp;
                                            </b>
                                        )}
                                        {`${user.userFirstName || ''} ${user.userLastName || ''}`.trim()}
                                    </td>
                                    <td>
                                        <div>
                                            {(chatIdToDelete === user.chatId) ? (
                                                <>
                                                    <Button
                                                        size="sm"
                                                        color="white-red"
                                                        onClick={() => setChatIdToDelete(null)}
                                                    >
                                                        Отмена
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        color="white-blue"
                                                        onClick={handleDeleteTelegramAccount}
                                                    >
                                                        Продолжить
                                                    </Button>
                                                </>
                                            ) : (
                                                <IconButton
                                                    size="sm"
                                                    color="white-blue"
                                                    onClick={() => setChatIdToDelete(user.chatId)}
                                                    icon={(
                                                        <SVGIcon
                                                            SVGElement={CrossIcon}
                                                        />
                                                    )}
                                                >
                                                    Удалить
                                                </IconButton>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className={styles['chatbot-empty']}>
                        Список пуст
                    </div>
                )}
            </DetailedViewFieldset>
        </div>
    );
};

BillingUserChatbotSection.propTypes = {
    fieldset: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        label: PropTypes.string,
    }),
    store: PropTypes.shape({
        ...DetailedView.propTypes.store,
        deleteTelegramAccount: PropTypes.func,
        activationKey: PropTypes.string,
        companyName: PropTypes.string,
        id: PropTypes.number,
        statusValue: PropTypes.string,
        statusKey: PropTypes.string,
        telegramUsers: PropTypes.arrayOf(PropTypes.shape({
            chatId: PropTypes.string,
            userId: PropTypes.string,
            userName: PropTypes.string,
            userLastName: PropTypes.string,
            userFirstName: PropTypes.string,
        })),
        form: PropTypes.shape({
            render: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

BillingUserChatbotSection.defaultProps = {
    ...DetailedView.defaultProps,
};

export default BillingUserChatbotSection;
