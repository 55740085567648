import BaseStrategy from './BaseStrategy';
import { PhoneItem } from '../item';
import { DIRECTORIES } from '../../../../constants/operator';

class PhoneStrategy extends BaseStrategy {
    constructor(options) {
        super(DIRECTORIES.PHONE_NUMBER, options);
        this.Item = PhoneItem;
        this.hideCreate = true;
        this.disableEdit = true;
    }
}

export default PhoneStrategy;
