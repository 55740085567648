import React from 'react';
import PropTypes from 'prop-types';
import { STATUS_FORM, POSITION } from '../../../constants/shared';

import styles from '../../shared/Table/Table.module.scss';
import activationKeyStyles from './ActivationKeyList.module.scss';

const ActivationKeyStatusCell = ({
    value: { text, value },
    cell: { row: { original: { id } } },
}) => (
    <div
        className={`
            ${styles['table__status-trigger']}
            ${activationKeyStyles['status-trigger']}
            ${activationKeyStyles[`status-trigger--${value}`]}
        `}
        data-tip={id}
        data-for={STATUS_FORM}
        data-effect="solid"
        data-place={POSITION.RIGHT}
        data-class={`${styles.table__hint} ${styles.table__status}`}
    >
        {text}
    </div>
);

ActivationKeyStatusCell.propTypes = {
    value: PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        text: PropTypes.string,
    }).isRequired,
    cell: PropTypes.shape({
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        }),
    }).isRequired,
};

export default ActivationKeyStatusCell;
