import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import {
    SelectedHighlight,
    SelectedRow,
    SelectedTitle,
    SelectedWrapper,
} from '../../../components/TableSelected';
import Phone from '../../../components/Phone';
import { EMPTY_DATA_STRING } from '../../../constants/shared';

import styles from './OperatorList.module.scss';
import { IconButton } from '../../../components/Button';
import { UserBlueIcon } from '../../../components/Icons/icons';
import SVGIcon from '../../../components/Icons/SVGIcon';

const OperatorSelected = ({
    store,
    style,
    onClick,
}) => {
    const handleClick = () => {
        onClick({ original: { id: store.id } });
    };
    return (
        <Observer>
            {() => (
                <SelectedWrapper style={style} loading={store.loading}>
                    <Observer>
                        {() => (
                            <div key={store.memoKey}>
                                <SelectedTitle>
                                    Информация по оператору
                                    &nbsp;
                                    <SelectedHighlight color={SelectedHighlight.COLOR.GREEN}>
                                        {store.firstName}
                                        &nbsp;
                                        {store.lastName}
                                    </SelectedHighlight>
                                </SelectedTitle>
                                <div className={styles.selected}>
                                    <div className={styles.selected__col}>
                                        <SelectedRow name="ФИО">
                                            {store.lastName}
                                            &nbsp;
                                            {store.firstName}
                                            &nbsp;
                                            {store.patronymic}
                                        </SelectedRow>
                                        <SelectedRow name="Телефон">
                                            {
                                                store.phone
                                                    ? (
                                                        <Phone
                                                            copy
                                                            className={styles.selected__phone}
                                                        >
                                                            {store.phone}
                                                        </Phone>
                                                    )
                                                    : EMPTY_DATA_STRING
                                            }
                                        </SelectedRow>
                                        <SelectedRow name="Доступ">
                                            {store.access}
                                        </SelectedRow>
                                    </div>
                                    <div className={styles.selected__col}>
                                        <SelectedRow name="График работы">
                                            {store.schedule}
                                        </SelectedRow>
                                        <SelectedRow name="План">
                                            {store.plan}
                                        </SelectedRow>
                                        <SelectedRow name="Зарегистрирован">
                                            {store.createdAtDate}
                                            &nbsp;
                                            {store.createdAtTime}
                                        </SelectedRow>
                                    </div>
                                    <div className={`${styles.selected__col} ${styles['selected__col--action']}`}>
                                        <IconButton
                                            color={IconButton.COLOR.WHITE_BLUE}
                                            size={IconButton.SIZE.MEDIUM}
                                            icon={<SVGIcon SVGElement={UserBlueIcon} />}
                                            onClick={handleClick}
                                        >
                                            Полный профиль
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Observer>
                </SelectedWrapper>
            )}
        </Observer>
    );
};

OperatorSelected.propTypes = {
    store: PropTypes.shape({
        memoKey: PropTypes.number,
        loading: PropTypes.bool,
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        patronymic: PropTypes.string,
        phone: PropTypes.string,
        access: PropTypes.string,
        createdAtTime: PropTypes.string,
        createdAtDate: PropTypes.string,
        plan: PropTypes.string,
        schedule: PropTypes.string,
    }).isRequired,
    style: PropTypes.shape({}),
    onClick: PropTypes.func,
};

OperatorSelected.defaultProps = {
    style: {},
    onClick: () => null,
};

export default OperatorSelected;
