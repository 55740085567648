import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { CalcIcon } from '../../../components/Icons/icons';
import useStores from '../../../hooks/operator/use-stores';
import Dialog from '../Dialog';
import ButtonMenu from '../../../components/Button/ButtonMenu';
import MortgageCalculator from '../../shared/MortgageCalculator';

import styles from './HeaderTools.module.scss';

const HeaderTools = ({ store }) => {
    const { dialogStore } = useStores();
    const { mortgageCalculator } = store;
    const showMortgageCalculator = () => {
        dialogStore.show('mortgage');
    };
    return (
        <>
            <ButtonMenu
                className={styles.header__button}
                onClick={showMortgageCalculator}
                icon={(
                    <span>
                        <SVGIcon
                            SVGElement={CalcIcon}
                            height={12}
                            className={`${styles.header__icon} ${styles['header__icon--mortgage']}`}
                        />
                    </span>
                )}
            >
                Калькулятор ипотеки
            </ButtonMenu>
            {/* <ButtonMenu
                className={styles.header__button}
                onClick={shotStats}
                icon={(
                    <span>
                        <SVGIcon
                            SVGElement={StatsIcon}
                            height={14}
                        />
                    </span>
                )}
            >
                Анализ цен
            </ButtonMenu>
            <a
                className={styles.link}
                // TODO change logic to method or getting this value from data field
                href="https://2gis.ru/krasnoyarsk/geo/985901152862692/92.863715%2C56.012741?m=92.956542%2C55.995322%2F12.11"
                target="_blank"
                rel="noreferrer noopener"
            >
                <SVGIcon
                    SVGElement={DoubleGISIcon}
                    height={14}
                    className={styles.link__icon}
                />
                Показать в 2ГИС
            </a> */}
            <Dialog
                name="mortgage"
                size="md"
                footer={null}
                className={styles['mortgage-dialog']}
                header={(
                    <div className={styles['mortgage-header']}>
                        <span>Калькулятор ипотеки</span>
                        <SVGIcon
                            SVGElement={CalcIcon}
                            height={24}
                            className={styles['mortgage-icon']}
                        />
                    </div>
                )}
            >
                <Observer>
                    {() => (
                        <MortgageCalculator
                            monthlyPayment={mortgageCalculator.monthlyPayment}
                            totalOverPayment={mortgageCalculator.totalOverPayment}
                            form={mortgageCalculator.form}
                            useStores={useStores}
                        />
                    )}
                </Observer>
            </Dialog>
        </>
    );
};

HeaderTools.propTypes = {
    store: PropTypes.shape({
        mortgageCalculator: PropTypes.shape({
            form: PropTypes.shape({}),
            monthlyPayment: PropTypes.string,
            totalOverPayment: PropTypes.string,
        }),
        clientAppInfo: PropTypes.shape({
            version: PropTypes.string,
            path: PropTypes.string,
        }),
    }),
};

HeaderTools.defaultProps = {
    store: {},
};

export default HeaderTools;
