// TODO add logic for visibility this block
// TODO add correct message
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, NavLink } from 'react-router-dom';
import { Observer } from 'mobx-react';
import Modal from 'react-modal';
import Container from '../../../components/Container';
import Dropdown from '../../../components/Dropdown';
import SVGIcon from '../../../components/Icons/SVGIcon';
import MenuGroup from './MenuGroup';
import {
    ListBlueIcon,
    UserGreenIcon,
    ZapIcon,
    ToolIcon,
    // TrophyIcon,
} from '../../../components/Icons/icons';
// import HeaderNotify from '../../../components/HeaderNotify';
import useStores from '../../../hooks/operator/use-stores';
import HeaderActions from '../HeaderActions/HeaderActions';
import HeaderTools from '../HeaderActions/HeaderTools';
import HeaderUser from '../HeaderUser';

import styles from './Root.module.scss';

Modal.setAppElement('#root');

const Root = ({ routes, children }) => {
    const { headerActionsStore, headerToolsStore, sessionStore } = useStores();
    const { pathname } = useLocation();
    const activeRootPage = routes.find(({ link }) => pathname.includes(link));
    if (!activeRootPage) {
        return children;
    }
    const { showTools = true } = activeRootPage;
    return (
        <div className={styles.root}>
            <header className={styles.root__header}>
                <Container>
                    {/* main pages */}
                    <div className={`${styles.menu} ${styles['menu--main']}`}>
                        <Dropdown
                            name={activeRootPage.title}
                            className={`${styles.menu__item} ${styles['menu__item--main']}`}
                            openClassName={`${styles['menu__item--open']}`}
                        >
                            <div className={styles.menu__content}>
                                {routes.map(({ link, title }) => (
                                    <NavLink
                                        key={link}
                                        className={styles.menu__link}
                                        to={link}
                                    >
                                        {title}
                                    </NavLink>
                                ))}
                            </div>
                        </Dropdown>
                    </div>
                    {/* nested pages */}
                    <div className={styles.menu}>
                        <Dropdown
                            name={(
                                <>
                                    <SVGIcon
                                        className={styles.menu__icon}
                                        SVGElement={ListBlueIcon}
                                        height={14}
                                    />
                                    Страницы
                                </>
                            )}
                            className={`${styles.menu__item}`}
                            openClassName={`${styles['menu__item--open']}`}
                        >
                            <div className={styles.menu__content}>
                                {activeRootPage.routes.map(({
                                    link,
                                    title,
                                    icon,
                                    isMenuGroup,
                                    routes: groupRoutes,
                                }) => {
                                    if (isMenuGroup) {
                                        return (
                                            <MenuGroup
                                                key={link}
                                                title={title}
                                                routes={groupRoutes}
                                            />
                                        );
                                    }
                                    return (
                                        <NavLink
                                            key={link}
                                            className={styles.menu__link}
                                            to={link}
                                        >
                                            {
                                                icon
                                                    ? (
                                                        <SVGIcon
                                                            className={styles.menu__icon}
                                                            SVGElement={icon}
                                                            height={14}
                                                        />
                                                    )
                                                    : null
                                            }
                                            {title}
                                        </NavLink>
                                    );
                                })}
                            </div>
                        </Dropdown>
                    </div>
                    <div className={`${styles.menu}`}>
                        <Observer>
                            {() => (
                                headerActionsStore.actions.length
                                    ? (
                                        <Dropdown
                                            name={(
                                                <>
                                                    <SVGIcon
                                                        className={styles.menu__icon}
                                                        SVGElement={ZapIcon}
                                                        height={14}
                                                    />
                                                    Действия
                                                </>
                                            )}
                                            className={`${styles.menu__item}`}
                                            openClassName={`${styles['menu__item--open']}`}
                                        >
                                            <div className={styles.menu__content}>
                                                <HeaderActions
                                                    actions={headerActionsStore.actions}
                                                />
                                            </div>
                                        </Dropdown>
                                    )
                                    : null
                            )}
                        </Observer>
                    </div>
                    {/* Tools dropdown */}
                    {
                        showTools
                            ? (
                                <div className={`${styles.menu}`}>
                                    <Observer>
                                        {() => (
                                            <Dropdown
                                                name={(
                                                    <>
                                                        <SVGIcon
                                                            className={styles.menu__icon}
                                                            SVGElement={ToolIcon}
                                                            height={14}
                                                        />
                                                        Инструменты
                                                    </>
                                                )}
                                                className={`${styles.menu__item}`}
                                                openClassName={`${styles['menu__item--open']}`}
                                            >
                                                <div className={styles.menu__content}>
                                                    <HeaderTools
                                                        store={headerToolsStore}
                                                    />
                                                </div>
                                            </Dropdown>
                                        )}
                                    </Observer>
                                </div>
                            )
                            : null
                    }
                    {/* user menu */}
                    <div className={`${styles.menu} ${styles['menu--user']}`}>
                        <Observer>
                            {() => {
                                const { firstName = '', lastName = '-/-' } = sessionStore.operatorInfo || {};
                                return (
                                    <Dropdown
                                        name={(
                                            <>
                                                <SVGIcon
                                                    className={styles.menu__icon}
                                                    SVGElement={UserGreenIcon}
                                                    height={14}
                                                />
                                                {`${firstName} ${lastName}`}
                                            </>
                                        )}
                                        align={Dropdown.ALIGN.RIGHT}
                                        className={`${styles.menu__item} ${styles['menu__item--user']}`}
                                        openClassName={`${styles['menu__item--open']}`}
                                    >
                                        <div className={`${styles.menu__content}`}>
                                            <HeaderUser />
                                        </div>
                                    </Dropdown>
                                );
                            }}
                        </Observer>
                    </div>
                    {/* <div className={`${styles.menu} ${styles['menu--rate']}`}>
                        <Dropdown
                            name={(
                                <>
                                    <SVGIcon
                                        className={styles.menu__icon}
                                        SVGElement={TrophyIcon}
                                        height={14}
                                    />
                                    Рейтинг
                                </>
                            )}
                            align={Dropdown.ALIGN.RIGHT}
                            className={`${styles.menu__item}`}
                            openClassName={`${styles['menu__item--open']}`}
                        >
                            <div className={`${styles.menu__content}`}>
                                <h2>Рейтинг операторов</h2>
                            </div>
                        </Dropdown>
                    </div>
                    <div className={`${styles.menu} ${styles['menu--notify']}`}>
                        <HeaderNotify>
                            2 дня до конца месяца, план выполнен на 30%
                        </HeaderNotify>
                    </div> */}
                </Container>
            </header>
            <Container>
                <div className={styles.content}>
                    {children}
                </div>
            </Container>
        </div>
    );
};

Root.propTypes = {
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string,
            icon: PropTypes.func,
        }),
    ),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]),
};

Root.defaultProps = {
    routes: [],
    children: null,
};

export default Root;
