import base from './base';
import editBase from './edit-base';
import house from './house';
import phone from './phone';
import editHouse from './edit-house';
import editAgency from './edit-agency';
import editCity from './edit-city';
import editCitydistrict from './edit-city-district';
import citydistrictlandmark from './city-district-landmark';
import editCitydistrictlandmark from './edit-city-district-landmark';
import street from './street';
import editStreet from './edit-street';
import editObjectstatusreason from './edit-object-status-reason';

export default {
    base,
    editBase,
    house,
    phone,
    editHouse,
    editAgency,
    editCity,
    editCitydistrict,
    citydistrictlandmark,
    editCitydistrictlandmark,
    street,
    editStreet,
    editObjectstatusreason,
};
