import { action } from 'mobx';
import createForm from '../../../../helpers/Form';
import BaseStrategy from './BaseStrategy';
import { withAddressField } from '../../../../helpers/Form/mixins';
import { HouseItem } from '../item';
import { directory as forms } from '../../../../forms/operator';
import { DIRECTORIES } from '../../../../constants/operator';

class HouseStrategy extends BaseStrategy {
    constructor() {
        super(DIRECTORIES.HOUSE);
        this.Item = HouseItem;
    }

    createFilterForm() {
        const { filterFormName, filterForm } = this;
        if (filterForm) return filterForm;
        this.filterForm = createForm(
            forms[filterFormName],
            [withAddressField({
                area: 'areaIds',
                city: 'cityIds',
                street: 'streetIds',
            })],
        );
        return this.filterForm;
    }

    @action
    createItemForm() {
        const { itemFormName, itemForm } = this;
        if (itemForm) return itemForm;
        this.itemForm = createForm(
            forms[itemFormName],
            [withAddressField({
                area: 'areaId',
                city: 'cityId',
                street: 'streetId',
            })],
        );
        return this.itemForm;
    }

    // eslint-disable-next-line class-methods-use-this
    prepareItemData(data) {
        const modified = data;
        if (modified.cityId && modified.streetId) {
            delete modified.cityId;
        }
        return {
            structureNumber: '',
            buildingNumber: '',
            number: '',
            ...modified,
        };
    }
}

export default HouseStrategy;
