export default [{
    type: 'fieldset',
    name: 'mainInfo',
    label: 'Основная информация',
    fields: [{
        type: 'text',
        name: 'name',
        label: 'Название фирмы',
        rules: 'required',
    }, {
        type: 'textarea',
        name: 'note',
        label: 'Комментарий',
        rules: 'required',
        rows: 4,
    }],
}, {
    type: 'fieldset',
    name: 'contacts',
    label: 'Контакты',
    fields: [{
        name: 'billingCompanyContacts',
        type: 'fieldgroup',
        fields: [{
            type: 'phone',
            name: 'phone',
            rules: 'required|phone',
            label: 'Телефон',
        }, {
            type: 'text',
            name: 'email',
            rules: 'required|email',
            label: 'Почта',
        }, {
            type: 'text',
            name: 'inn',
            rules: 'required|numeric|digits:10',
            label: 'ИНН',
        }],
    }, {
        name: 'billingCompanyAddress',
        type: 'fieldgroup',
        fields: [{
            type: 'text',
            name: 'address',
            rules: 'required',
            label: 'Адрес',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'subscription',
    label: 'Подписка',
    fields: [{
        type: 'numeric',
        name: 'price',
        rules: 'required|numeric',
        label: 'Новая цена подписки',
        postfix: 'руб/мес',
    }],
}];
