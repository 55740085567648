import React from 'react';
import ButtonMenu from '../../../components/Button/ButtonMenu';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { LogOutIcon } from '../../../components/Icons/icons';
import useStores from '../../../hooks/operator/use-stores';

import styles from './HeaderUser.module.scss';

const HeaderUser = () => {
    const { sessionStore } = useStores();
    return (
        <ButtonMenu
            className={styles.header__button}
            onClick={() => {
                sessionStore.logout();
            }}
            icon={(
                <span>
                    <SVGIcon
                        SVGElement={LogOutIcon}
                        height={14}
                    />
                </span>
            )}
        >
            Выйти
        </ButtonMenu>
    );
};

export default HeaderUser;
