import { format } from 'date-fns';
import { computed } from 'mobx';
import { ListItem } from '../../../shared/List';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from '../../../../constants/shared';

class LogItem extends ListItem {
    @computed
    get companyName() {
        const { $companyName } = this;
        return $companyName;
    }

    @computed
    get amount() {
        const { $amount } = this;
        return $amount;
    }

    @computed
    get paidAt() {
        const { $paidAt } = this;
        return $paidAt
            ? format(new Date($paidAt), DEFAULT_DATE_FORMAT)
            : null;
    }

    @computed
    get paidAtTime() {
        const { $paidAt } = this;
        return $paidAt
            ? format(new Date($paidAt), DEFAULT_TIME_FORMAT)
            : null;
    }

    @computed
    get paidUntil() {
        const { $paidUntil } = this;
        return $paidUntil
            ? format(new Date($paidUntil), DEFAULT_DATE_FORMAT)
            : null;
    }

    @computed
    get paidUntilTime() {
        const { $paidUntil } = this;
        return $paidUntil
            ? format(new Date($paidUntil), DEFAULT_TIME_FORMAT)
            : null;
    }
}

export default LogItem;
