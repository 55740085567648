import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import useStores from '../../../hooks/operator/use-stores';
import OperatorLog from '../../../stores/operator/OperatorLog';
import { PopupTabsWithApproveStore } from '../../../stores/shared/PopupTabsStore';
import { Table } from '../Table';
import { PagePopups } from '../../shared/PagePopups';
import OperatorLogPage from './OperatorLogPage';
import Pagination from '../../../components/Pagination';
import KeyboardHandler from '../../../components/KeyboardHandler';
import { TAB_TYPE } from '../../../constants/operator';

import styles from './OperatorLog.module.scss';

const OperatorLogContent = ({
    store: {
        list,
        tabs,
        detailedObjectFactory,
    },
    meta,
}) => {
    const listRef = useRef();
    const keyboardRef = useRef();
    const { page } = useParams();
    const history = useHistory();
    const { headerActionsStore, headerToolsStore } = useStores();
    const { link: currentPage } = meta;
    // effects
    useEffect(() => {
        if (list.filterForm) {
            headerActionsStore.setActions(null);
            headerToolsStore.setTarget(null);
            return list.filterForm.on('success', () => {
                history.replace(`${currentPage}/1`, { inner: true });
                keyboardRef.current.focus();
                return Promise.resolve();
            });
        }
        return undefined;
    }, [list]);
    useEffect(() => {
        const { location: { state }, action } = history;
        if (!state || !state.inner || action === 'POP') {
            list.load(page || 1);
            keyboardRef.current.focus();
        }
        return undefined;
    }, [page, list]);
    // Interactions
    const handlePaginationClick = ({ page: nextPage }) => (
        history.push(`${currentPage}/${nextPage}`)
    );
    const handleRowClick = (row) => {
        const { id } = row.original;
        list.setCursor(id);
    };
    const handleDoubleRowClick = (data) => {
        const {
            original: {
                objectAddress,
                id: rowId,
                objectId: id,
                isExclusive,
                dealType,
                objectType,
            },
        } = data;
        const Detailed = detailedObjectFactory(objectType, dealType);
        const instance = new Detailed();
        instance.setData({ id, exclusive: isExclusive });
        const form = instance.createForm();
        form.on('done', (response) => {
            if (!response.filter((res) => Boolean(res)).length) {
                return;
            }
            if (Number(page) === 1) {
                list.load(1);
            }
            tabs.removeTab(id);
        });
        list.setCursor(rowId);
        instance.load(id);
        tabs.addTab(
            instance,
            rowId,
            objectAddress,
            TAB_TYPE.EDIT_OBJECT,
        );
    };
    const handleArrowKey = (direction) => {
        if (direction === -1) {
            list.setPrevCursor();
        } else {
            list.setNextCursor();
        }
        const currentId = list.cursor;
        const index = list.tableData.findIndex(({ id }) => id === currentId);
        listRef.current?.scrollToItem(index);
    };
    const handleDownKey = () => {
        handleArrowKey(1);
    };
    const handleUpKey = () => {
        handleArrowKey(-1);
    };
    const handleEnterKey = () => {
        const objectItem = list.getItem(list.cursor);
        handleDoubleRowClick({ original: objectItem });
    };
    const handleObjectClose = () => {
        keyboardRef.current.focus();
    };
    // Component
    return (
        <>
            <OperatorLogPage
                meta={meta}
                store={list}
            >
                <KeyboardHandler
                    ref={keyboardRef}
                    onDown={handleDownKey}
                    onUp={handleUpKey}
                    onEnter={handleEnterKey}
                >
                    <Observer>
                        {() => (
                            <Table
                                ref={listRef}
                                headers={list.tableHeaders}
                                store={list}
                                onRowClick={handleRowClick}
                                onDoubleRowClick={handleDoubleRowClick}
                                wrapperStyles={{
                                    height: 'calc(100vh - 126px)',
                                }}
                            />
                        )}
                    </Observer>
                </KeyboardHandler>
                <Observer>
                    {() => {
                        const pagination = list.paginationHandler.data;
                        return (
                            <Pagination
                                totalItems={pagination.totalItems}
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                pageNeighbours={pagination.pageNeighbours}
                                onPageChange={handlePaginationClick}
                                className={styles.pagination}
                            />
                        );
                    }}
                </Observer>
            </OperatorLogPage>
            <PagePopups
                useStores={useStores}
                tabsStore={tabs}
                onClose={handleObjectClose}
            />
        </>
    );
};

OperatorLogContent.propTypes = {
    store: PropTypes.shape({
        list: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(OperatorLog),
        ]),
        tabs: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(PopupTabsWithApproveStore),
        ]),
        detailedObjectFactory: PropTypes.func,
    }),
    meta: OperatorLogPage.propTypes.meta,
};

OperatorLogContent.defaultProps = {
    meta: OperatorLogPage.defaultProps.meta,
    store: {
        list: {
            tableHeaders: [],
            tableData: [],
            paginationHandler: { data: {} },
            load: () => null,
        },
        tabs: {
            tabs: [],
        },
        detailedObjectFactory: () => null,
    },
};

export default OperatorLogContent;
