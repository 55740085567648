import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.DEAL_TYPE_NAME,
    width: 15,
    textAlign: 'left',
}, {
    ...TABLE_CELL.ROOMS_COUNT,
    width: 3,
    fields: [{
        name: 'rooms',
    }],
}, {
    ...TABLE_CELL.STREET_NAME,
    width: 25,
    textAlign: 'left',
}, {
    ...TABLE_CELL.HOUSE_NUMBER,
    title: '№',
    width: 8,
}, {
    ...TABLE_CELL.FLOOR_COUNT,
    width: 10,
}, {
    ...TABLE_CELL.SQUARE,
    width: 10,
}, {
    ...TABLE_CELL.CONTACT_PHONE,
    width: 18,
}, {
    ...TABLE_CELL.OBJECT_STATUS_UPDATE_WITH_CONTACT,
    width: 6,
    textAlign: 'left',
    fields: [{
        name: 'objectStatusIds',
    }],
    sortName: null,
}];
