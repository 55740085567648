/* eslint-disable no-redeclare */
/* global Event */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import useStores from '../../../hooks/operator/use-stores';
import BillingCompaniesListStore from '../../../stores/operator/RefactoredBillingCompaniesList';
import BillingCompanyDetailedStore from '../../../stores/operator/RefactoredBillingCompanyDetailed';
import { TableWithSelected } from '../TableWithSelected';
import BillingCompaniesListPage from './BillingCompaniesListPage';
import Pagination from '../../../components/Pagination';
import KeyboardHandler from '../../../components/KeyboardHandler';
import BillingCompaniesListLinkCell from './BillingCompaniesListLinkCell';
import CellDefaultWithTooltip from '../../shared/Table/CellDefaultWithTooltip';
import BillingCompaniesListInstancesCell from './BillingCompaniesListInstancesCell';
import BillingCompaniesListStatusCell from './BillingCompaniesListStatusCell';
import BillingCompanySelected from './BillingCompanySelected';
import Dialog from '../Dialog';
import BillingCompanyDetailed from '../BillingCompanyDetailed';
import { BillingSubscribe } from '../BillingActions';
import { SORT_ORDER, TABLE_CELL, SIZE } from '../../../constants/operator';

import styles from './BillingCompaniesList.module.scss';

const BillingCompaniesListContent = ({
    store,
    meta,
}) => {
    const [detailed, _setDetailed] = useState();
    const [detailedSelected, _setSelected] = useState();
    const detailedRef = useRef(detailed);
    const listRef = useRef();
    const keyboardRef = useRef();
    const { page } = useParams();
    const history = useHistory();
    const {
        headerActionsStore,
        headerToolsStore,
        dialogStore,
        billingStatsStore = {},
    } = useStores();
    const { link: currentPage } = meta;
    let keyPressDelay;

    const setDetailed = (company) => {
        _setDetailed(company);
        detailedRef.current = company;
        _setSelected(company);
    };
    const isCompanySelected = (companyId) => (
        detailedRef && companyId === detailedRef.current?.id
    );
    const loadCompany = ({ original: { id: companyId } }, isDoubleClick) => {
        if (isCompanySelected(companyId)) {
            return detailedRef.current.reload();
        }
        const item = store.getItem(companyId);
        const company = new BillingCompanyDetailedStore();
        company.setData({ id: companyId });

        if (!isDoubleClick) {
            store.setCursor(companyId);
        }
        company.form.on('done', (response) => {
            if (!response.filter((res) => Boolean(res)).length) {
                return;
            }
            item.setData(company.toItem());
            dialogStore.hide();
        });
        company.form.on('updated', () => {
            const { isOpen } = dialogStore;
            item.setData(company.toItem());
            if (isOpen === 'detailed-subscription') {
                dialogStore.hide();
            }
        });

        if (!isDoubleClick && detailedRef.current) {
            detailedRef.current.preload();
        } else {
            company.load(companyId);
            setDetailed(company, isDoubleClick);
            return company;
        }
        return company.load(companyId)
            .then(() => {
                setDetailed(company, isDoubleClick);
                return Promise.resolve(company);
            });
    };

    useEffect(() => {
        if (store.filterForm) {
            headerActionsStore.setActions([]);
            headerToolsStore.setTarget(null);

            return store.filterForm.on('success', () => {
                history.replace(`${currentPage}/1`, { inner: true });
                keyboardRef.current.focus();
            });
        }
        return undefined;
    }, [store]);

    useEffect(() => {
        if (!store.filterForm) {
            return;
        }
        const { selected } = billingStatsStore || {};
        const { location: { state }, action } = history;
        let overridePage;
        if ((!state || !state.inner || action === 'POP') || selected) {
            if (selected) {
                overridePage = 1;
                const field = store.filterForm.$('status');
                field.set('value', [selected]);
                billingStatsStore.reset();
                store.filterForm.onSubmit(new Event('submit'));
            } else {
                store.load(overridePage || page || 1);
            }
            keyboardRef.current.focus();
        }
    }, [page, store, billingStatsStore.selected]);

    // Interactions
    const handlePaginationClick = ({ page: nextPage }) => {
        history.push(`${currentPage}/${nextPage}`);
        keyboardRef.current.focus();
    };
    const handleDoubleRowClick = (row) => {
        loadCompany(row, true);
        dialogStore.show('company-detailed');
    };
    const handleSingleRowClick = (row) => {
        if (handleSingleRowClick.callback) {
            handleSingleRowClick.callback(loadCompany(row));
        }
    };
    const handleSortChange = ({ sortName, sortDirection }) => {
        let nextDirection;
        switch (sortDirection) {
            case SORT_ORDER.ASC:
                nextDirection = SORT_ORDER.DESC;
                break;
            case SORT_ORDER.DESC:
                nextDirection = null;
                break;
            default:
                nextDirection = SORT_ORDER.ASC;
        }
        store.setSort(sortName, nextDirection);
        store.load(page);
    };
    const handleArrowKey = (direction) => {
        if (direction === -1) {
            store.setPrevCursor();
        } else {
            store.setNextCursor();
        }
        const currentId = store.cursor;
        const index = store.tableData.findIndex(({ id }) => id === currentId);
        listRef.current?.scrollToItem(index);
        if (keyPressDelay) {
            clearTimeout(keyPressDelay);
        }
        keyPressDelay = setTimeout(() => {
            handleSingleRowClick({ original: { id: currentId } });
        }, 300);
    };
    const handleDownKey = () => {
        handleArrowKey(1);
    };
    const handleUpKey = () => {
        handleArrowKey(-1);
    };
    const handleEnterKey = () => {
        handleDoubleRowClick({ original: { id: store.cursor } });
    };
    const handleDetailedCloseClick = () => {
        if (!detailed) {
            return true;
        }
        return detailed.setApprovingState();
    };
    const handleDetailedCancelClick = () => {
        dialogStore.hide();
    };
    const handleItemStatusChange = (id, data) => {
        const item = store.getItem(id);
        item.setData(data);
    };
    const handleSelectedSubscriptionClick = () => {
        if (!detailedSelected) {
            return;
        }
        detailedSelected.initSubscribtion(false);
        dialogStore.show('detailed-subscription');
    };

    const modifyHeaders = (headers) => headers.map((header) => {
        switch (header.name) {
            case TABLE_CELL.BILLING_USERS.name:
                return { ...header, Cell: BillingCompaniesListLinkCell };
            case TABLE_CELL.BILLING_COMPANY_NAME.name:
            case TABLE_CELL.BILLING_COMPANY_ADDRESS.name:
                return { ...header, Cell: CellDefaultWithTooltip };
            case TABLE_CELL.BILLING_INSTANCES.name:
                return { ...header, Cell: BillingCompaniesListInstancesCell };
            case TABLE_CELL.BILLING_STATUS.name:
                return { ...header, Cell: BillingCompaniesListStatusCell };
            default:
                return header;
        }
    });

    // Component
    return (
        <>
            <BillingCompaniesListPage
                meta={meta}
                store={store}
            >
                <KeyboardHandler
                    ref={keyboardRef}
                    onDown={handleDownKey}
                    onUp={handleUpKey}
                    onEnter={handleEnterKey}
                >
                    <Observer>
                        {() => (
                            <TableWithSelected
                                ref={listRef}
                                headers={modifyHeaders(store.tableHeaders)}
                                store={store}
                                selectedContentHeight="150px"
                                onRowClick={handleSingleRowClick}
                                onDoubleRowClick={handleDoubleRowClick}
                                onTableHeaderClick={handleSortChange}
                                fullHeight="calc(100vh - 160px)"
                                components={{
                                    SelectedWrapper: (props) => (
                                        <BillingCompanySelected
                                            onClick={handleDoubleRowClick}
                                            onSubscriptionClick={handleSelectedSubscriptionClick}
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...props}
                                        />
                                    ),
                                }}
                            />
                        )}
                    </Observer>
                </KeyboardHandler>
                <Observer>
                    {() => {
                        const pagination = store.paginationHandler.data;
                        return (
                            <Pagination
                                totalItems={pagination.totalItems}
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                pageNeighbours={pagination.pageNeighbours}
                                onPageChange={handlePaginationClick}
                                className={styles.pagination}
                            />
                        );
                    }}
                </Observer>
            </BillingCompaniesListPage>
            <Observer>
                {() => (
                    <Dialog
                        name="company-detailed"
                        className={styles['company-detailed__dialog']}
                        size={SIZE.LARGE}
                        onClose={() => keyboardRef.current.focus()}
                        closeable={detailed && !detailed.subscribing && !detailed.loaning}
                        shouldBeClosed={handleDetailedCloseClick}
                        components={{
                            // eslint-disable-next-line react/prop-types
                            BodyWrapper: ({ children }) => (
                                <div className={styles['company-detailed__body']}>
                                    {children}
                                </div>
                            ),
                        }}
                    >
                        <BillingCompanyDetailed
                            id={detailed && detailed.id}
                            useStores={useStores}
                            store={detailed}
                            onClose={handleDetailedCancelClick}
                            onStatusChange={handleItemStatusChange}
                        />
                    </Dialog>
                )}
            </Observer>
            <Observer>
                {() => (
                    <Dialog
                        className={styles['subscribe-dialog']}
                        name="detailed-subscription"
                        size={SIZE.SMALL}
                        closeable={!detailedSelected?.subscribe?.form?.loading}
                    >
                        <BillingSubscribe
                            form={detailedSelected?.subscribe?.form || null}
                        />
                    </Dialog>
                )}
            </Observer>
        </>
    );
};

BillingCompaniesListContent.propTypes = {
    store: PropTypes.oneOfType([
        PropTypes.instanceOf(BillingCompaniesListStore),
        PropTypes.shape({}),
    ]),
    meta: BillingCompaniesListPage.propTypes.meta,
};

BillingCompaniesListContent.defaultProps = {
    meta: BillingCompaniesListPage.defaultProps.meta,
    store: {
        tableHeaders: [],
        tableData: [],
        paginationHandler: { data: {} },
        load: () => null,
    },
};

export default BillingCompaniesListContent;
