export default [{
    type: 'fieldset',
    name: 'baseStatusGroups',
    fields: [{
        type: 'dropdownSelect',
        name: 'statusIds',
        size: 'sm',
        label: 'Статус',
        multiple: true,
        value: [{
            id: 1,
            value: 'active',
            label: 'Активен',
        }, {
            id: 3,
            value: 'blocked',
            label: 'Забанен',
        }, {
            id: 3,
            value: 'archived',
            label: 'В архиве',
        }],
        options: [{
            id: 1,
            value: 'active',
            label: 'Активен',
        }, {
            id: 3,
            value: 'blocked',
            label: 'Забанен',
        }, {
            id: 3,
            value: 'archived',
            label: 'В архиве',
        }],
    }],
}, {
    type: 'fieldset',
    name: 'search',
    fields: [{
        name: 'query',
        type: 'text',
        label: 'Поиск',
        placeholder: 'Имя, почта, телефон',
        size: 'sm',
        icon: 'search',
    }],
}];
