import { action, computed, observable } from 'mobx';
import BaseItem from './BaseItem';
import { directory as forms } from '../../../../forms/operator';
import createForm from '../../../../helpers/Form';
import { withAddressField } from '../../../../helpers/Form/mixins';

class HouseItem extends BaseItem {
    @observable $city;

    @observable $street;

    @observable $houseType;

    @computed
    get cityName() {
        const { $city: $val } = this;
        return $val
            ? `${$val.cityType.shortname} ${$val.name}`
            : null;
    }

    @computed
    get streetName() {
        const { $street: $val } = this;
        return $val
            ? `${$val.streetType.shortname} ${$val.name}`
            : null;
    }

    @computed
    get houseTypeName() {
        const { $houseType: $val } = this;
        return $val && $val.name ? $val.name : null;
    }

    @computed
    get houseNumber() {
        return this.$number;
    }

    @computed
    get cityId() {
        const { $city: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }

    @computed
    get streetId() {
        const { $street: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }

    @computed
    get houseTypeId() {
        const { $houseType: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }

    @action
    createItemForm() {
        const { parent: { strategy: { itemFormName } }, itemForm } = this;
        if (itemForm) return itemForm;
        this.itemForm = createForm(
            forms[itemFormName],
            [withAddressField({
                area: 'areaIds',
                city: 'cityId',
                street: 'streetId',
            })],
        );
        return this.itemForm;
    }
}

export default HouseItem;
