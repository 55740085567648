import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.BILLING_COMPANY_NAME,
    width: 32,
    textAlign: 'left',
}, {
    ...TABLE_CELL.AMOUNT,
    width: 13.5,
    textAlign: 'left',
}, {
    ...TABLE_CELL.BILLING_PAID_UNTIL,
    width: 30,
    textAlign: 'left',
}, {
    ...TABLE_CELL.DATE,
    name: 'paidAt',
    width: 11,
    textAlign: 'left',
}, {
    ...TABLE_CELL.TIME,
    name: 'paidAtTime',
    textAlign: 'left',
}];
