import forms from '../../shared/objects-detailed';
import status from './status';
import extraAdsForm from './extra-ads';

export default {
    saleFlats: [...status, ...forms.saleFlats],
    rentFlats: [...status, ...forms.rentFlats],
    saleHouses: [...status, ...forms.saleHouses],
    rentHouses: [...status, ...forms.rentHouses],
    saleSteads: [...status, ...forms.saleSteads],
    rentSteads: [...status, ...forms.rentSteads],
    saleCommercial: [...status, ...forms.saleCommercial],
    rentCommercial: [...status, ...forms.rentCommercial],
    extraAdsForm,
};
