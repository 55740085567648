import { computed, observable } from 'mobx';
import { ListItem } from '../../../shared/List';
import { OPERATOR_STATUS } from '../../../../constants/operator';

class OperatorItem extends ListItem {
    @observable $status;

    @computed
    get operatorName() {
        const { $operator } = this;
        return $operator;
    }

    @computed
    get plan() {
        const { $plan } = this;
        return $plan || '0';
    }

    @computed
    get contactPhone() {
        const { $phone } = this;
        return $phone;
    }

    @computed
    get disabled() {
        const { $status } = this;
        return $status !== OPERATOR_STATUS.ACTIVE;
    }
}

export default OperatorItem;
