import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import useStores from '../../../hooks/operator/use-stores';
import OperatorList from '../../../stores/operator/OperatorList';
import { TableWithSelected } from '../TableWithSelected';
import OperatorListPage from './OperatorListPage';
import Pagination from '../../../components/Pagination';
import KeyboardHandler from '../../../components/KeyboardHandler';
import OperatorDetailedStore from '../../../stores/operator/OperatorDetailed';
import Dialog from '../Dialog';
import OperatorDetailed from '../OperatorDetailed';
import OperatorSelected from './OperatorSelected';
import { AdminBanIcon, AdminPlusIcon } from '../../../components/Icons/icons';
import ApproveDialog from '../../shared/Dialog/ApproveDialog';
import { SIZE, SORT_ORDER } from '../../../constants/operator';

import styles from './OperatorList.module.scss';

const OperatorListContent = ({
    store,
    meta,
}) => {
    const [detailed, _setDetailed] = useState(false);
    const detailedRef = useRef(detailed);
    const listRef = useRef();
    const keyboardRef = useRef();
    const { page } = useParams();
    const history = useHistory();
    const { dialogStore, headerActionsStore, headerToolsStore } = useStores();
    const { link: currentPage } = meta;
    let keyPressDelay;
    const setDetailed = (operator) => {
        _setDetailed(operator);
        detailedRef.current = operator;
    };
    const isOperatorSelected = (operatorId) => (
        detailedRef && operatorId === detailedRef.current.id
    );
    const loadOperator = ({ original: { id: operatorId } }) => {
        if (isOperatorSelected(operatorId)) {
            return detailedRef.current;
        }
        const item = store.getItem(operatorId);
        const operator = new OperatorDetailedStore();
        store.setCursor(operatorId);
        operator.load(operatorId);
        setDetailed(operator);

        operator.form.on('done', (response) => {
            if (!response.filter((res) => Boolean(res)).length) {
                return;
            }
            item.setData(operator.toItem());
            dialogStore.hide();
        });
        return operator;
    };
    useEffect(() => {
        if (store.filterForm) {
            headerActionsStore.setActions([{
                actionText: 'Добавить оператора',
                icon: AdminPlusIcon,
                actionFunction: () => {
                    const operator = new OperatorDetailedStore();
                    operator.load();
                    setDetailed(operator);
                    operator.form.on('done', (response) => {
                        if (!response.filter((res) => Boolean(res)).length) {
                            return;
                        }
                        keyboardRef.current.focus();
                        dialogStore.hide();
                        store.load(page);
                    });
                    dialogStore.show('operator-detailed');
                },
            }, {
                actionText: 'Забанить оператора',
                disabled: !store.cursor,
                icon: AdminBanIcon,
                actionFunction: () => {
                    dialogStore.show('approve-ban-operator');
                },
            }]);
            headerToolsStore.setTarget(null);
            return store.filterForm.on('success', () => {
                history.replace(`${currentPage}/1`, { inner: true });
                keyboardRef.current.focus();
            });
        }
        return undefined;
    }, [store, store.cursor]);
    useEffect(() => {
        const { location: { state }, action } = history;
        if (!state || !state.inner || action === 'POP') {
            store.load(page || 1);
            keyboardRef.current.focus();
        }
    }, [page, store]);
    // Interactions
    const handlePaginationClick = ({ page: nextPage }) => {
        history.push(`${currentPage}/${nextPage}`);
        keyboardRef.current.focus();
    };
    const handleDoubleRowClick = (row) => {
        loadOperator(row);
        dialogStore.show('operator-detailed');
    };
    const handleSingleRowClick = (row) => {
        if (handleSingleRowClick.callback) {
            handleSingleRowClick.callback(loadOperator(row));
        }
    };
    const handleSortChange = ({ sortName, sortDirection }) => {
        let nextDirection;
        switch (sortDirection) {
            case SORT_ORDER.ASC:
                nextDirection = SORT_ORDER.DESC;
                break;
            case SORT_ORDER.DESC:
                nextDirection = null;
                break;
            default:
                nextDirection = SORT_ORDER.ASC;
        }
        store.setSort(sortName, nextDirection);
        store.load(page);
    };
    const handleBanApprove = () => {
        const operatorId = store.cursor;
        store.ban(operatorId)
            .then(() => {
                if (isOperatorSelected(operatorId)) {
                    detailedRef.current.setBan();
                }
            });
        dialogStore.hide();
        keyboardRef.current.focus();
    };
    const handleArrowKey = (direction) => {
        if (direction === -1) {
            store.setPrevCursor();
        } else {
            store.setNextCursor();
        }
        const currentId = store.cursor;
        const index = store.tableData.findIndex(({ id }) => id === currentId);
        listRef.current?.scrollToItem(index);
        if (keyPressDelay) {
            clearTimeout(keyPressDelay);
        }
        keyPressDelay = setTimeout(() => {
            handleSingleRowClick({ original: { id: currentId } });
        }, 300);
    };
    const handleDownKey = () => {
        handleArrowKey(1);
    };
    const handleUpKey = () => {
        handleArrowKey(-1);
    };
    const handleEnterKey = () => {
        handleDoubleRowClick({ original: { id: store.cursor } });
    };
    const handleDetailedCloseClick = () => {
        if (!detailed) {
            return true;
        }
        return detailed.setApprovingState();
    };
    const handleDetailedCancelClick = () => {
        dialogStore.hide();
    };
    // Component
    return (
        <>
            <OperatorListPage
                meta={meta}
                store={store}
            >
                <KeyboardHandler
                    ref={keyboardRef}
                    onDown={handleDownKey}
                    onUp={handleUpKey}
                    onEnter={handleEnterKey}
                >
                    <Observer>
                        {() => (
                            <TableWithSelected
                                ref={listRef}
                                headers={store.tableHeaders}
                                store={store}
                                selectedContentHeight="111px"
                                Detailed={OperatorDetailed}
                                onRowClick={handleSingleRowClick}
                                onDoubleRowClick={handleDoubleRowClick}
                                onTableHeaderClick={handleSortChange}
                                fullHeight="calc(100vh - 131px)"
                                components={{
                                    SelectedWrapper: (props) => (
                                        <OperatorSelected
                                            onClick={handleDoubleRowClick}
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...props}
                                        />
                                    ),
                                }}
                            />
                        )}
                    </Observer>
                </KeyboardHandler>
                <Observer>
                    {() => {
                        const pagination = store.paginationHandler.data;
                        return (
                            <Pagination
                                totalItems={pagination.totalItems}
                                currentPage={pagination.currentPage}
                                itemsPerPage={pagination.itemsPerPage}
                                pageNeighbours={pagination.pageNeighbours}
                                onPageChange={handlePaginationClick}
                                className={styles.pagination}
                            />
                        );
                    }}
                </Observer>
            </OperatorListPage>
            <Dialog
                name="operator-detailed"
                className={styles['operator-detailed__dialog']}
                size={SIZE.LARGE}
                onClose={() => keyboardRef.current.focus()}
                shouldBeClosed={handleDetailedCloseClick}
                components={{
                    // eslint-disable-next-line react/prop-types
                    BodyWrapper: ({ children }) => (
                        <div className={styles['operator-detailed__body']}>
                            {children}
                        </div>
                    ),
                }}
            >
                <OperatorDetailed
                    id={detailed.id || 'new'}
                    useStores={useStores}
                    store={detailed}
                    onClose={handleDetailedCancelClick}
                />
            </Dialog>
            <ApproveDialog
                useStores={useStores}
                name="approve-ban-operator"
                header={<>Забанить оператора?</>}
                onApprove={handleBanApprove}
                approveDisabled={store.loading}
            >
                Вы уверены, что хотите
                &nbsp;
                забанить выбранного оператора?
            </ApproveDialog>
        </>
    );
};

OperatorListContent.propTypes = {
    store: PropTypes.oneOfType([
        PropTypes.instanceOf(OperatorList),
        PropTypes.shape({}),
    ]),
    meta: OperatorListPage.propTypes.meta,
};

OperatorListContent.defaultProps = {
    meta: OperatorListPage.defaultProps.meta,
    store: {
        tableHeaders: [],
        tableData: [],
        paginationHandler: { data: {} },
        load: () => null,
    },
};

export default OperatorListContent;
