import { computed, observable } from 'mobx';
import BaseItem from './BaseItem';

class CityItem extends BaseItem {
    @observable $city;

    @observable $streetType;

    @computed
    get cityName() {
        const { $city: $val } = this;
        return $val
            ? `${$val.cityType.shortname} ${$val.name}`
            : null;
    }

    @computed
    get streetName() {
        const { $name, $streetType } = this;
        return $name && $streetType
            ? `${$streetType.shortname} ${$name}`
            : null;
    }

    @computed
    get cityId() {
        const { $city: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }

    @computed
    get streetTypeId() {
        const { $streetType: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }

    @computed
    get aliases() {
        const { $aliases: $val } = this;
        const aliases = [];
        $val.forEach((alias) => {
            aliases.push(alias.name);
        });
        return $val
            ? aliases.join('; ')
            : null;
    }
}

export default CityItem;
