import { toJS } from 'mobx';

const withStatusGroups = (Store) => (
    class extends Store {
        createFilterForm(mixins = []) {
            const form = super.createFilterForm(mixins);
            const statusField = 'statusIds';
            if (!form.has(statusField)) {
                return form;
            }
            const statusFormField = form.$('statusIds');
            statusFormField.observe(({ change }) => {
                const { newValue } = change;
                const newValueJS = toJS(newValue);
                const extra = statusFormField.get('extra');
                const mainGroupOption = extra.options.find((option) => option.value === 'mainGroup');
                if (newValueJS.length > 0) {
                    mainGroupOption.disabled = false;
                } else {
                    mainGroupOption.disabled = true;
                    statusFormField.set('value', [mainGroupOption]);
                }
            });
            return form;
        }

        prepareFilterData() {
            return super.prepareFilterData()
                .then((data) => {
                    const { statusIds = [] } = data;
                    const groups = {};
                    for (let i = 0; i < statusIds.length; i += 1) {
                        groups[statusIds[i]] = 1;
                    }
                    return Promise.resolve({ ...data, statusIds: null, ...groups });
                });
        }

        // конвертирует сохраненный фильтр в подходящий
        convertPresetFilterData(filterData) {
            const { sort, filter } = super.convertPresetFilterData(filterData);
            const { filterForm } = this;
            const statusFormField = filterForm.$('statusIds');
            const extra = statusFormField.get('extra');
            const {
                mainGroup,
                archiveGroup,
                inProgressGroup,
                agencyGroup,
                trashGroup,
                ...modifiedFilter
            } = filter;
            const values = [];
            extra.options.forEach((option) => {
                if (filter[option.value]) {
                    values.push(option);
                }
            });
            return {
                sort,
                filter: { ...modifiedFilter, statusIds: values },
            };
        }
    }
);

export default withStatusGroups;
