import { withStatusField } from '../../../../../helpers/Form/mixins';
import { withParserItem } from '../../item/mixins';
import capitalizeFirstLetter from '../../../../../utils/capitalize-first-letter';
import tables from '../../tables';

const withParserStrategy = (Store) => (
    class extends Store {
        constructor(...rest) {
            super(...rest);
            const { Item } = this;
            this.requestType = 'parser';
            this.Item = withParserItem(Item);
            this.tables = tables;
        }

        get strategyName() {
            const { requestType, objectType } = this;
            return `${requestType}${capitalizeFirstLetter(objectType)}`;
        }

        createFilterForm(mixins = []) {
            return super.createFilterForm([
                withStatusField({
                    status: 'objectStatusIds',
                    reason: 'objectStatusReasonIds',
                }),
                ...mixins,
            ]);
        }
    }
);

export default withParserStrategy;
