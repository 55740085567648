export default {
    type: 'fieldset',
    name: 'incorrect',
    fields: [{
        name: 'incorrect',
        type: 'checkbox',
        size: 'md',
        label: 'Некорректные варианты',
    }],
};
