import React from 'react';
import { Observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import useStores from '../../../hooks/operator/use-stores';
import { Button } from '../../../components/Button';
import LoginPage, { LoginPageField } from '../../shared/LoginPage';
import FormElements from '../Form';

import styles from '../../shared/LoginPage/LoginPage.module.scss';

const Login = () => {
    const { sessionStore } = useStores();
    const { loginForm } = sessionStore;
    return (
        <LoginPage
            title="Вход"
        >
            <Observer>
                {() => (
                    <form onSubmit={loginForm.onSubmit}>
                        <FormElements
                            form={loginForm}
                            elements={loginForm.render}
                            components={{
                                FieldWrapper: LoginPageField,
                            }}
                        />
                        <div className={styles.login__submit}>
                            <Button
                                type="submit"
                                color={Button.COLOR.GREEN}
                                size={Button.SIZE.LARGE}
                                block
                                disabled={loginForm.loading}
                            >
                                Войти
                            </Button>
                        </div>
                        <div className={styles.login__forgot}>
                            <Link className="link" to="/forgot">Забыли пароль?</Link>
                        </div>
                    </form>
                )}
            </Observer>
        </LoginPage>
    );
};

export default Login;
