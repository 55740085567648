import { TABLE_CELL } from '../../../../constants/operator';

const tableHeaders = [
    TABLE_CELL.ROW_SELECT,
    {
        ...TABLE_CELL.HOUSE_NUMBER,
        width: 10,
    }, {
        ...TABLE_CELL.STRUCTURE_NUMBER,
        width: 10,
    }, {
        ...TABLE_CELL.BUILDING_NUMBER,
        width: 10,
    }, {
        ...TABLE_CELL.HOUSE_TYPE_NAME,
        width: 15,
        textAlign: 'left',
    }, {
        ...TABLE_CELL.CITY_NAME,
        width: 25,
        textAlign: 'left',
    },
    TABLE_CELL.STREET_NAME,
];

export default tableHeaders;
