import React from 'react';
import PropTypes from 'prop-types';
import Filter from '../Filter/Filter';
import { Button } from '../../../components/Button';

import styles from './OperatorList.module.scss';

const OperatorListFilterFooter = () => (
    <>
        <div className={styles.filter__footer}>
            <Button
                type="submit"
                size={Button.SIZE.LARGE}
                color={Button.COLOR.BLUE}
                block
            >
                Искать
            </Button>
        </div>
    </>
);

const OperatorListFilter = ({
    filterForm,
    title,
    onCollapse,
}) => (
    <Filter
        form={filterForm}
        title={title}
        fullHeight={false}
        components={{
            FilterFooter: OperatorListFilterFooter,
        }}
        customClassNames={{
            fieldList: styles['filter__field-list'],
        }}
        onCollapse={onCollapse}
    />
);

OperatorListFilter.propTypes = {
    filterForm: PropTypes.shape({}).isRequired,
    title: PropTypes.string,
    onCollapse: PropTypes.func,
};

OperatorListFilter.defaultProps = {
    title: '',
    onCollapse: () => null,
};

export default OperatorListFilter;
