export default [{
    type: 'fieldset', // ad
    name: 'filter',
    fields: [{
        name: 'query',
        type: 'text',
        label: 'Наименование',
        icon: 'search',
        rules: 'min:3',
    }],
}];
