import BaseStrategy from './BaseStrategy';
import { ObjectStatusReasonItem } from '../item';
import { DIRECTORIES } from '../../../../constants/operator';

class ObjectStatusReasonStrategy extends BaseStrategy {
    constructor(options) {
        super(DIRECTORIES.OBJECT_STATUS_REASON, options);
        this.Item = ObjectStatusReasonItem;
    }
}

export default ObjectStatusReasonStrategy;
