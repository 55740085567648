import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.HARDWARE_NAME,
    title: 'ID компьютера',
    width: 16.5,
    textAlign: 'left',
}, {
    ...TABLE_CELL.BILLING_COMPANY_NAME,
    width: 20,
    textAlign: 'left',
}, {
    ...TABLE_CELL.ACTION,
    width: 40,
    textAlign: 'left',
}, {
    ...TABLE_CELL.CREATED,
    name: 'createdAt',
    width: 11,
    textAlign: 'left',
}, {
    ...TABLE_CELL.CREATED_TIME,
    name: 'createdAtTime',
    textAlign: 'left',
}];
