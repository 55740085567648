import React from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import DetailedView from '../../shared/DetailedView';
import { Button, ButtonLink, IconButton } from '../../../components/Button';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { BanIcon, DoubleArrowGreenIcon } from '../../../components/Icons/icons';
import ObjectComponentApprove from '../../shared/ObjectComponent/ObjectComponentApprove';
import DetailedViewFieldset from '../../shared/DetailedView/DetailedViewFieldset';
import FormElements from '../../shared/Form';
import BillingUserDetailedField from './BillingUserDetailedField';
import BillingUserDetailedGroup from './BillingUserDetailedGroup';
import BillingUserChatbotSection from './BillingUserChatbotSection';
import { findFieldset } from '../../../forms/utils/find-field';

import styles from './BillingUserDetailed.module.scss';

const BillingUserDetailed = ({
    id,
    useStores,
    store,
    onClose,
    onStatusChange,
}) => {
    const { dialogStore } = useStores();

    if (!store) {
        return null;
    }

    const { form } = store;
    const toggleApprovingState = () => {
        store.setApprovingState(false);
    };
    const handleApproveCancel = () => {
        dialogStore.hide();
    };
    const handleApprove = (e) => {
        form.onSubmit(e);
        form.on('error', () => toggleApprovingState());
    };
    const handleBlockUserClick = () => {
        const { statusKey } = store;
        let promise;
        if (statusKey !== 'blocked') {
            promise = store.blockUser();
        } else {
            promise = store.unblockUser();
        }
        return promise
            .then(() => {
                onStatusChange(id, store.toItem());
                return Promise.resolve();
            });
    };
    const handleArchiveUserClick = () => (
        store.archiveUser()
            .then(() => {
                dialogStore.hide();
                onStatusChange(id, store.toItem());
                return Promise.resolve();
            })
    );

    const createContentComponent = (name, label) => {
        if (!['mainInfo', 'security', 'chat-bot'].includes(name)) {
            return null;
        }
        // eslint-disable-next-line react/prop-types
        return () => (
            <Observer key={`${name}-${id}`}>
                {() => {
                    const isBlocked = store.statusKey === 'blocked';
                    switch (name) {
                        case 'mainInfo':
                            return (
                                <>
                                    <DetailedViewFieldset
                                        fieldset={{
                                            id: name,
                                            label,
                                            name,
                                        }}
                                    >
                                        <div className={styles['main-info']}>
                                            <div className={styles['main-info__form']}>
                                                <div className={styles.data__field}>
                                                    <div className={styles.data__label}>
                                                        ID пользователя
                                                    </div>
                                                    <div className={styles.data__value}>
                                                        {store.id}
                                                    </div>
                                                </div>
                                                <div className={styles.data__field}>
                                                    <div className={styles.data__label}>
                                                        Ключ
                                                    </div>
                                                    <div className={styles.data__value}>
                                                        {store.activationKey}
                                                    </div>
                                                </div>
                                                <div className={styles.data__field}>
                                                    <div className={styles.data__label}>
                                                        Работник компании
                                                    </div>
                                                    <div className={styles.data__value}>
                                                        {store.companyName}
                                                    </div>
                                                </div>
                                                {FormElements({
                                                    useStores,
                                                    form,
                                                    elements: [
                                                        // eslint-disable-next-line react/prop-types
                                                        findFieldset(store.form.render, name),
                                                    ],
                                                    components: {
                                                        FieldWrapper: BillingUserDetailedField,
                                                    },
                                                })}
                                            </div>
                                            <div className={styles['main-info__action-list']}>
                                                <div className={styles.data__field}>
                                                    <div className={styles.data__label}>
                                                        Статус
                                                    </div>
                                                    <div className={`${styles.data__value} ${styles[`data__value--${store.statusKey}`]}`}>
                                                        {store.statusValue}
                                                    </div>
                                                </div>
                                                {
                                                    store.statusKey !== 'archived' && (
                                                        <>
                                                            <IconButton
                                                                className={styles['main-info__action']}
                                                                color={
                                                                    isBlocked
                                                                        ? Button.COLOR.WHITE_GREEN
                                                                        : Button.COLOR.WHITE_RED
                                                                }
                                                                size={ButtonLink.SIZE.MEDIUM}
                                                                icon={(
                                                                    <SVGIcon
                                                                        SVGElement={isBlocked
                                                                            ? DoubleArrowGreenIcon
                                                                            : BanIcon}
                                                                    />
                                                                )}
                                                                block
                                                                onClick={handleBlockUserClick}
                                                            >
                                                                {
                                                                    isBlocked
                                                                        ? 'Разблокировать'
                                                                        : 'Заблокировать'
                                                                }
                                                            </IconButton>
                                                            <Button
                                                                className={styles['main-info__action']}
                                                                color={ButtonLink.COLOR.WHITE_BLACK}
                                                                size={ButtonLink.SIZE.MEDIUM}
                                                                block
                                                                onClick={handleArchiveUserClick}
                                                            >
                                                                В архив
                                                            </Button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </DetailedViewFieldset>
                                </>
                            );
                        case 'security':
                            return (
                                <>
                                    <DetailedViewFieldset
                                        fieldset={{
                                            id: name,
                                            label,
                                            name,
                                        }}
                                    >
                                        {FormElements({
                                            useStores,
                                            form,
                                            elements: [
                                                // eslint-disable-next-line react/prop-types
                                                findFieldset(store.form.render, name),
                                            ],
                                            components: {
                                                FieldWrapper: BillingUserDetailedField,
                                                FieldGroupWrapper: BillingUserDetailedGroup,
                                            },
                                        })}
                                    </DetailedViewFieldset>
                                </>
                            );
                        case 'chat-bot':
                            return (
                                <BillingUserChatbotSection
                                    store={store}
                                    fieldset={{ id: name, label, name }}
                                />
                            );
                        default:
                            return null;
                    }
                }}
            </Observer>
        );
    };

    return (
        <div className={styles['user-detailed']}>
            <Observer>
                {() => (
                    <>
                        {
                            store.approving && (
                                <ObjectComponentApprove
                                    onClose={handleApproveCancel}
                                    onCancel={handleApproveCancel}
                                    onApprove={handleApprove}
                                    toggleApprovingState={toggleApprovingState}
                                    disabled={form.loading}
                                />
                            )
                        }
                    </>
                )}
            </Observer>
            <DetailedView
                id={id}
                key={`user-${id}`}
                useStores={useStores}
                store={store}
                createContentComponent={createContentComponent}
                wrapper={({ children }) => (
                    <div className={styles['user-detailed__wrapper']}>
                        <div className={styles['user-detailed__header']}>
                            <div className={styles['user-detailed__title']}>
                                Профиль пользователя
                                {' '}
                                {store.name}
                            </div>
                        </div>
                        {children}
                    </div>
                )}
                formWrappers={{
                    FieldWrapper: BillingUserDetailedField,
                    FieldGroupWrapper: BillingUserDetailedGroup,
                }}
            >
                <Observer>
                    {() => (
                        <div
                            className={`
                                ${styles.footer}
                                ${styles['user-detailed__footer']}
                            `}
                        >
                            <div
                                className={`
                                    ${styles.footer__col}
                                    ${styles.footer__user}
                                    ${store.statusKey === 'blocked' ? styles['footer__user--blocked'] : ''}
                                `}
                            >
                                {
                                    store.registeredAt
                                        ? (
                                            <>
                                                <div>
                                                    <div>Зарегистрирован</div>
                                                    <div>
                                                        {store.registeredAtDate}
                                                        &nbsp;в&nbsp;
                                                        {store.registeredAtTime}
                                                    </div>
                                                </div>
                                                <div>
                                                    <SVGIcon
                                                        SVGElement={BanIcon}
                                                    />
                                                </div>
                                            </>
                                        )
                                        : null
                                }
                            </div>
                            <div className={styles.footer__col}>
                                <div
                                    className={`
                                        ${styles.footer__buttons}
                                        ${(form.snapshotIsDiffer || store.userChanged) ? styles['footer__buttons--active'] : ''}
                                    `}
                                >
                                    <Button
                                        type={Button.TYPE.BUTTON}
                                        size="md"
                                        color="white-black"
                                        className={styles.footer__button}
                                        onClick={onClose}
                                        disabled={form.loading}
                                    >
                                        Отменить изменения
                                    </Button>
                                    <Button
                                        type={Button.TYPE.SUBMIT}
                                        size="md"
                                        color="white-green"
                                        className={styles.footer__button}
                                        disabled={form.loading}
                                    >
                                        Сохранить изменения
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </Observer>
            </DetailedView>
        </div>
    );
};

BillingUserDetailed.propTypes = {
    onClose: PropTypes.func,
    ...DetailedView.propTypes,
    store: PropTypes.shape({
        ...DetailedView.propTypes.store,
        activationKey: PropTypes.string,
        companyName: PropTypes.string,
        id: PropTypes.number,
        statusValue: PropTypes.string,
        statusKey: PropTypes.string,
        form: PropTypes.shape({
            render: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

BillingUserDetailed.defaultProps = {
    onClose: () => null,
    ...DetailedView.defaultProps,
};

export default BillingUserDetailed;
