import React from 'react';
import PropTypes from 'prop-types';
import getRange from '../../utils/get-range';
import { ChevronRightIcon } from '../Icons/icons';

import styles from './Pagination.module.scss';
import SVGIcon from '../Icons/SVGIcon';

const LEFT_PAGE = 'left';
const RIGHT_PAGE = 'right';
const START_PAGE = 'start';
const END_PAGE = 'end';

class Pagination extends React.Component {
    get totalPages() {
        const {
            props: {
                totalItems,
                itemsPerPage,
            },
        } = this;
        return Math.ceil(totalItems / itemsPerPage);
    }

    fetchPageNumbers = () => {
        const {
            props: {
                currentPage,
                pageNeighbours,
            },
            totalPages,
        } = this;
        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;
        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = getRange(startPage, endPage);
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);
            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = getRange(startPage - spillOffset, startPage - 1);
                    pages = [START_PAGE, LEFT_PAGE, ...extraPages, ...pages, totalPages];
                    break;
                }
                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = getRange(endPage + 1, endPage + spillOffset);
                    pages = [1, ...pages, ...extraPages, RIGHT_PAGE, END_PAGE];
                    break;
                }
                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [START_PAGE, LEFT_PAGE, ...pages, RIGHT_PAGE, END_PAGE];
                    break;
                }
            }
            return pages;
        }
        return getRange(1, totalPages);
    }

    goLeft = () => {
        const {
            props: {
                currentPage,
            },
            goTo,
        } = this;
        goTo(currentPage - 1);
    }

    goRight = () => {
        const {
            props: {
                currentPage,
            },
            goTo,
        } = this;
        goTo(currentPage + 1);
    }

    handleClick = (page) => (e) => {
        e.preventDefault();
        const {
            totalPages,
            goTo,
            goLeft,
            goRight,
        } = this;
        switch (page) {
            case START_PAGE:
                goTo(1);
                break;
            case END_PAGE:
                goTo(totalPages);
                break;
            case LEFT_PAGE:
                goLeft();
                break;
            case RIGHT_PAGE:
                goRight();
                break;
            default:
                goTo(page);
        }
    }

    goTo = (page) => {
        const {
            props: {
                onPageChange,
                itemsPerPage,
                totalItems,
            },
            totalPages,
        } = this;
        const nextPage = Math.max(0, Math.min(page, totalPages));
        const paginationData = {
            page: nextPage,
            totalPages,
            itemsPerPage,
            totalItems,
        };
        if (onPageChange) {
            onPageChange(paginationData);
        }
    }

    render() {
        const {
            props: {
                totalItems,
                currentPage,
                className,
            },
            totalPages,
            fetchPageNumbers,
            handleClick,
        } = this;
        if (!totalItems || totalPages === 1) return null;
        const pages = fetchPageNumbers();
        return (
            <>
                <nav className={`${styles.pagination} ${className}`}>
                    <ul className={styles.pagination__list}>
                        {
                            pages.map((page, index) => {
                                const isString = typeof page === 'string';
                                let chevron = (
                                    <SVGIcon
                                        className={styles.pagination__icon}
                                        SVGElement={ChevronRightIcon}
                                        height={7}
                                    />
                                );
                                if (isString && (page === START_PAGE || page === END_PAGE)) {
                                    chevron = (
                                        <>
                                            {chevron}
                                            {chevron}
                                        </>
                                    );
                                }
                                return (
                                    <li
                                        key={`pagination-${index + 1}`}
                                        className={`
                                            ${styles.pagination__item}
                                            ${isString ? styles[`pagination__item--${page}`] : ''}
                                            ${currentPage === page ? styles['pagination__item--active'] : ''}
                                        `}
                                    >
                                        <button
                                            className={styles.pagination__link}
                                            type="button"
                                            onClick={handleClick(page)}
                                        >
                                            <span>
                                                {
                                                    isString
                                                        ? chevron
                                                        : page
                                                }
                                            </span>
                                        </button>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </nav>
            </>
        );
    }
}

Pagination.propTypes = {
    totalItems: PropTypes.number,
    itemsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChange: PropTypes.func,
    className: PropTypes.string,
};

Pagination.defaultProps = {
    totalItems: 0,
    itemsPerPage: 10,
    currentPage: 1,
    pageNeighbours: 2,
    onPageChange: null,
    className: '',
};

export default Pagination;
