import React from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import DetailedView from '../../shared/DetailedView';
import { Button, ButtonLink, IconButton } from '../../../components/Button';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { BanIcon, ClockIcon, DoubleArrowGreenIcon } from '../../../components/Icons/icons';
import ObjectComponentApprove from '../../shared/ObjectComponent/ObjectComponentApprove';
import BillingCompanyDetailedField from './BillingCompanyDetailedField';
import DetailedViewFieldset from '../../shared/DetailedView/DetailedViewFieldset';
import FormElements from '../../shared/Form';
import BillingCompanyDetailedGroup from './BillingCompanyDetailedGroup';
import { findFieldset } from '../../../forms/utils/find-field';
import { BillingSubscribe, BillingDebt } from '../BillingActions';

import styles from './BillingCompanyDetailed.module.scss';

const BillingCompanyDetailed = ({
    id,
    useStores,
    store,
    onClose,
    onStatusChange,
}) => {
    const { dialogStore } = useStores();
    if (!store) {
        return null;
    }

    const { form } = store;
    const toggleApprovingState = () => {
        store.setApprovingState(false);
    };
    const handleApproveCancel = () => {
        dialogStore.hide();
    };
    const handleApprove = (e) => {
        form.onSubmit(e);
        form.on('error', () => toggleApprovingState());
    };
    const handleBlockCompanyClick = () => {
        const { statusKey } = store;
        let promise;
        if (statusKey !== 'blocked') {
            promise = store.blockCompany();
        } else {
            promise = store.unblockCompany();
        }
        return promise
            .then(() => {
                onStatusChange(id, store.toItem());
                return Promise.resolve();
            });
    };
    const handleArchiveCompanyClick = () => (
        store.archiveCompany()
            .then(() => {
                dialogStore.hide();
                onStatusChange(id, store.toItem());
                return Promise.resolve();
            })
    );
    const handleSubscribeClick = () => {
        store.initSubscribtion();
    };
    const handleSubscribeCancel = () => {
        store.cancelSubscribtion();
    };
    const handleDebtClick = () => {
        store.initDebt();
    };
    const handleDebtCancel = () => {
        store.cancelDebt();
    };

    const createContentComponent = (name, label) => {
        if (!['mainInfo', 'subscription'].includes(name)) {
            return null;
        }
        // eslint-disable-next-line react/prop-types
        return () => (
            <Observer key={`${name}-${id}`}>
                {() => {
                    const isBlocked = store.statusKey === 'blocked';
                    switch (name) {
                        case 'mainInfo':
                            return (
                                <>
                                    <DetailedViewFieldset
                                        fieldset={{
                                            id: name,
                                            label,
                                            name,
                                        }}
                                    >
                                        <div className={styles['main-info']}>
                                            <div className={styles['main-info__form']}>
                                                {FormElements({
                                                    useStores,
                                                    form,
                                                    elements: [
                                                        // eslint-disable-next-line react/prop-types
                                                        findFieldset(store.form.render, name),
                                                    ],
                                                    components: {
                                                        FieldWrapper: BillingCompanyDetailedField,
                                                    },
                                                })}
                                            </div>
                                            <div className={styles['main-info__action-list']}>
                                                <ButtonLink
                                                    className={styles['main-info__action']}
                                                    target="_blank"
                                                    href={`/billing/users/list?companyId=${id}`}
                                                    color={ButtonLink.COLOR.WHITE_BLACK}
                                                    size={ButtonLink.SIZE.MEDIUM}
                                                    block
                                                >
                                                    Пользователи
                                                </ButtonLink>
                                                {
                                                    store.statusKey !== 'archived' && (
                                                        <>
                                                            <IconButton
                                                                className={styles['main-info__action']}
                                                                color={
                                                                    isBlocked
                                                                        ? Button.COLOR.WHITE_GREEN
                                                                        : Button.COLOR.WHITE_RED
                                                                }
                                                                size={ButtonLink.SIZE.MEDIUM}
                                                                icon={(
                                                                    <SVGIcon
                                                                        SVGElement={isBlocked
                                                                            ? DoubleArrowGreenIcon
                                                                            : BanIcon}
                                                                    />
                                                                )}
                                                                block
                                                                onClick={handleBlockCompanyClick}
                                                            >
                                                                {
                                                                    isBlocked
                                                                        ? 'Разблокировать'
                                                                        : 'Заблокировать'
                                                                }
                                                            </IconButton>
                                                            <Button
                                                                className={styles['main-info__action']}
                                                                color={ButtonLink.COLOR.WHITE_BLACK}
                                                                size={ButtonLink.SIZE.MEDIUM}
                                                                block
                                                                onClick={handleArchiveCompanyClick}
                                                            >
                                                                В архив
                                                            </Button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </DetailedViewFieldset>
                                </>
                            );
                        case 'subscription':
                            return (
                                <>
                                    <DetailedViewFieldset
                                        fieldset={{
                                            id: name,
                                            label,
                                            name,
                                        }}
                                    >
                                        <div className={styles.subscription}>
                                            <div className={styles.subscription__form}>
                                                <div className={styles.subscription__field}>
                                                    <div className={styles.subscription__label}>
                                                        Ключ
                                                    </div>
                                                    <div className={styles.subscription__value}>
                                                        {store.activationKey || '-/-'}
                                                    </div>
                                                </div>
                                                <div className={styles.subscription__field}>
                                                    <div className={styles.subscription__label}>
                                                        Кол-во пользователей
                                                    </div>
                                                    <div className={styles.subscription__value}>
                                                        {store.userCount || '0'}
                                                    </div>
                                                </div>
                                                <div className={styles.subscription__field}>
                                                    <div className={styles.subscription__label}>
                                                        Текущая цена подписки
                                                    </div>
                                                    <div className={styles.subscription__value}>
                                                        {store.price || '0'}
                                                    </div>
                                                </div>
                                                {FormElements({
                                                    useStores,
                                                    form,
                                                    elements: [
                                                        findFieldset(store.form.render, name),
                                                    ],
                                                    components: {
                                                        FieldWrapper: BillingCompanyDetailedField,
                                                    },
                                                })}
                                            </div>
                                            <div className={styles['subscription__action-list']}>
                                                <div className={styles.subscription__field}>
                                                    <div className={styles.subscription__label}>
                                                        Статус
                                                    </div>
                                                    <div className={`${styles.subscription__value} ${styles[`subscription__value--${store.statusKey}`]}`}>
                                                        {store.statusValue}
                                                    </div>
                                                </div>
                                                <IconButton
                                                    className={styles.subscription__action}
                                                    color={ButtonLink.COLOR.WHITE_ORANGE}
                                                    size={ButtonLink.SIZE.MEDIUM}
                                                    icon={<SVGIcon SVGElement={ClockIcon} />}
                                                    block
                                                    onClick={handleDebtClick}
                                                >
                                                    Обещанный платеж
                                                </IconButton>
                                                <IconButton
                                                    className={`${styles.subscription__action} ${styles['subscription__action--subscribe']}`}
                                                    color={ButtonLink.COLOR.WHITE_GREEN}
                                                    size={ButtonLink.SIZE.MEDIUM}
                                                    icon={(
                                                        <SVGIcon
                                                            SVGElement={DoubleArrowGreenIcon}
                                                        />
                                                    )}
                                                    block
                                                    onClick={handleSubscribeClick}
                                                >
                                                    Продлить подписку
                                                </IconButton>
                                            </div>
                                        </div>
                                    </DetailedViewFieldset>
                                </>
                            );
                        default:
                            return null;
                    }
                }}
            </Observer>
        );
    };

    return (
        <div className={styles['company-detailed']}>
            <Observer>
                {() => (
                    <>
                        {
                            store.approving && (
                                <ObjectComponentApprove
                                    onClose={handleApproveCancel}
                                    onCancel={handleApproveCancel}
                                    onApprove={handleApprove}
                                    toggleApprovingState={toggleApprovingState}
                                    disabled={form.loading}
                                />
                            )
                        }
                        {
                            store.subscribing && (
                                <div className={styles['company-detailed__subscribe']}>
                                    <BillingSubscribe
                                        form={store.subscribe?.form || null}
                                        footer={(
                                            <Button
                                                className={styles['company-detailed__cancel']}
                                                type={Button.TYPE.BUTTON}
                                                color={Button.COLOR.WHITE_BLACK}
                                                size={Button.SIZE.MEDIUM}
                                                block
                                                onClick={handleSubscribeCancel}

                                            >
                                                Отмена
                                            </Button>
                                        )}
                                    />
                                </div>
                            )
                        }
                        {
                            store.loaning && (
                                <div className={styles['company-detailed__subscribe']}>
                                    <BillingDebt
                                        form={store.debt?.form || null}
                                        footer={(
                                            <Button
                                                className={styles['company-detailed__cancel']}
                                                type={Button.TYPE.BUTTON}
                                                color={Button.COLOR.WHITE_BLACK}
                                                size={Button.SIZE.MEDIUM}
                                                block
                                                onClick={handleDebtCancel}

                                            >
                                                Отмена
                                            </Button>
                                        )}
                                    />
                                </div>
                            )
                        }
                    </>
                )}
            </Observer>
            <DetailedView
                id={id}
                key={`company-${id}`}
                useStores={useStores}
                store={store}
                createContentComponent={createContentComponent}
                wrapper={({ children }) => (
                    <div className={styles['company-detailed__wrapper']}>
                        <div className={styles['company-detailed__header']}>
                            <div className={styles['company-detailed__title']}>
                                Профиль компании
                                {' '}
                                {store.name}
                            </div>
                        </div>
                        {children}
                    </div>
                )}
                formWrappers={{
                    FieldWrapper: BillingCompanyDetailedField,
                    FieldGroupWrapper: BillingCompanyDetailedGroup,
                }}
            >
                <Observer>
                    {() => (
                        <div
                            className={`
                                ${styles.footer}
                                ${styles['company-detailed__footer']}
                            `}
                        >
                            <div
                                className={`
                                    ${styles.footer__col}
                                    ${styles.footer__company}
                                    ${store.statusKey === 'blocked' ? styles['footer__company--blocked'] : ''}
                                `}
                            >
                                {
                                    store.registeredAt
                                        ? (
                                            <>
                                                <div>
                                                    <div>Зарегистрирован</div>
                                                    <div>
                                                        {store.registeredAtDate}
                                                        &nbsp;в&nbsp;
                                                        {store.registeredAtTime}
                                                    </div>
                                                </div>
                                                <div>
                                                    <SVGIcon
                                                        SVGElement={BanIcon}
                                                    />
                                                </div>
                                            </>
                                        )
                                        : null
                                }
                            </div>
                            <div className={styles.footer__col}>
                                <div
                                    className={`
                                        ${styles.footer__buttons}
                                        ${(form.snapshotIsDiffer || store.userChanged) ? styles['footer__buttons--active'] : ''}
                                    `}
                                >
                                    <Button
                                        type={Button.TYPE.BUTTON}
                                        size="md"
                                        color="white-black"
                                        className={styles.footer__button}
                                        onClick={onClose}
                                        disabled={form.loading}
                                    >
                                        Отменить изменения
                                    </Button>
                                    <Button
                                        type={Button.TYPE.SUBMIT}
                                        size="md"
                                        color="white-green"
                                        className={styles.footer__button}
                                        disabled={form.loading}
                                    >
                                        Сохранить изменения
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </Observer>
            </DetailedView>
        </div>
    );
};

BillingCompanyDetailed.propTypes = {
    onClose: PropTypes.func,
    ...DetailedView.propTypes,
    store: PropTypes.shape({
        ...DetailedView.propTypes.store,
        activationKey: PropTypes.string,
        userCount: PropTypes.number,
        price: PropTypes.number,
        statusValue: PropTypes.string,
        statusKey: PropTypes.string,
        form: PropTypes.shape({
            render: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

BillingCompanyDetailed.defaultProps = {
    onClose: () => null,
    ...DetailedView.defaultProps,
};

export default BillingCompanyDetailed;
