/* global alert */
import { computed, observable } from 'mobx';
import { ActivationKeyService } from '../../../services/operator';
import DataSource from '../../shared/DataSource';
import Pagination from '../../shared/DataSource/entities/Pagination';
import { ListWithDataSource } from '../../shared/List';
import LogItem from './item';
import { activationKeyList as activationKeyListFields } from '../../../forms/operator';
import createForm from '../../../helpers/Form';
import tableHeaders from './table';
import listWithCursor from '../../shared/List/mixins/listWithCursor';

class ActivationKeyList extends ListWithDataSource {
    filterForm;

    filterData;

    @observable
    sortData = {};

    constructor() {
        super(
            LogItem,
            new DataSource(ActivationKeyService),
            new Pagination(),
        );
        this.createFilterForm();
    }

    @computed
    get tableHeaders() {
        const {
            sortData,
        } = this;
        const headers = tableHeaders;
        return headers.map((header) => ({
            ...header,
            sortDirection: sortData[header.sortName],
        }));
    }

    @computed
    get tableData() {
        return this.list;
    }

    getList(page = 1, filter) {
        return this.dataSource.getAll(filter, page);
    }

    load(page = 1) {
        return super.load(page, this.prepareFilterData())
            .then((list) => {
                this.setList(list);
                return Promise.resolve();
            })
            .catch((e) => alert(e));
    }

    updateStatus(id, { status }) {
        return this.dataSource.setStatus(id, status)
            .catch((e) => alert(e));
    }

    createFilterForm() {
        const { filterForm } = this;
        if (filterForm) {
            return filterForm;
        }
        this.filterForm = createForm(activationKeyListFields);
        this.setFilterData(this.filterForm.clearValues(this.filterForm.values()));
        this.initFilterFormListeners();
        return this.filterForm;
    }

    setFilterData(filterData) {
        this.filterData = filterData;
    }

    setSort(field, direction) {
        const { sortData, filterForm } = this;
        sortData[field] = direction;
        this.sortData = filterForm.clearValues(sortData);
    }

    prepareFilterData() {
        const { filterData, sortData } = this;
        const sort = Object.keys(sortData).map((key) => ({
            name: key,
            order: sortData[key],
        }));
        return {
            ...filterData,
            sort,
        };
    }

    initFilterFormListeners() {
        const { filterForm } = this;
        if (!filterForm) return;
        filterForm.on('success', (values) => {
            this.setFilterData(filterForm.clearValues(values));
            return this.load();
        });
    }
}

export default listWithCursor(ActivationKeyList);
