import { withStatusGroups } from '../../../../shared/RefactoredObjectList/strategy/mixins';
import capitalizeFirstLetter from '../../../../../utils/capitalize-first-letter';
import tables from '../../tables';

const withBaseStrategy = (Store) => (
    class extends withStatusGroups(Store) {
        constructor(...rest) {
            super(...rest);
            this.requestType = 'base';
            this.tables = tables;
        }

        get strategyName() {
            const { requestType, objectType } = this;
            return `${requestType}${capitalizeFirstLetter(objectType)}`;
        }
    }
);

export default withBaseStrategy;
