import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonLink } from '../../../components/Button';

import styles from './BillingCompaniesList.module.scss';

const BillingCompaniesListLinkCell = ({ cell }) => {
    const { row: { original: { id } } } = cell;

    const handleClick = (e) => {
        if (e) {
            e.stopPropagation();
        }
    };
    return (
        <ButtonLink
            Component={Link}
            className={styles['users-link']}
            href={`/billing/users/list?companyId=${id}`}
            color={ButtonLink.COLOR.WHITE_BLUE}
            size={ButtonLink.SIZE.SMALL}
            onClick={handleClick}
        >
            Перейти
        </ButtonLink>
    );
};

BillingCompaniesListLinkCell.propTypes = {
    cell: PropTypes.shape({
        row: PropTypes.shape({
            original: PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
            }),
        }),
    }).isRequired,
};

export default BillingCompaniesListLinkCell;
