import BaseStrategy from './BaseStrategy';
import { CityDistrictItem } from '../item';
import { DIRECTORIES } from '../../../../constants/operator';

class CityDistrictStrategy extends BaseStrategy {
    constructor(options) {
        super(DIRECTORIES.CITY_DISTRICT, options);
        this.Item = CityDistrictItem;
    }
}

export default CityDistrictStrategy;
