import { computed, observable } from 'mobx';
import BaseItem from './BaseItem';

class AgencyItem extends BaseItem {
    @observable $aliases;

    @observable $phone;

    @computed
    get agencyName() {
        const { $aliases: $val } = this;
        const aliases = [];
        $val.forEach((alias) => {
            aliases.push(alias.name);
        });
        return $val
            ? aliases.join('; ')
            : null;
    }

    @computed
    get agencyAliases() {
        const { $aliases: $val } = this;
        const aliases = [];
        $val.forEach((alias) => {
            aliases.push(alias.name);
        });
        return $val
            ? aliases.join('; ')
            : null;
    }

    @computed
    get contactPhone() {
        const { $phone: $val } = this;
        return $val
            ? `${$val}`
            : '';
    }

    @computed
    get phone() {
        const { $phone: $val } = this;
        return $val
            ? `${$val}`
            : '';
    }
}

export default AgencyItem;
