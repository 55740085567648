import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'manage',
    label: 'Управление',
    fields: [{
        name: 'manageNested',
        type: 'fieldgroup',
        fields: [{
            name: 'status',
            type: 'directorySelect',
            directory: DIRECTORIES.OBJECT_STATUS,
            label: 'Статус',
            rules: 'required',
            searchable: true,
            clearable: true,
            menuPlacement: 'top',
        }, {
            name: 'reason',
            type: 'directorySelect',
            directory: DIRECTORIES.OBJECT_STATUS_REASON,
            label: 'Причина',
            clearable: true,
            searchable: true,
            menuPlacement: 'top',
        }, {
            name: 'user',
            type: 'directoryClientSearch',
            directory: DIRECTORIES.USERS,
            label: 'Клиент',
            clearable: true,
            searchable: true,
            menuPlacement: 'top',
        }],
    }, {
        name: 'commentGroup',
        type: 'fieldgroup',
        fields: [{
            name: 'comment',
            type: 'textarea',
            rows: 4,
            label: 'Комментарий',
        }],
    }],
}];
