import base from './base';
import city from './city';
import house from './house';
import phone from './phone';
import agency from './agency';
import citydistrict from './city-district';
import citydistrictlandmark from './city-district-landmark';
import street from './street';
import objectstatusreason from './object-status-reason';

export default {
    base,
    city,
    house,
    phone,
    agency,
    citydistrictlandmark,
    citydistrict,
    street,
    objectstatusreason,
};
