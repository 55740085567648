import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Select } from '../../../components/Select';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { SearchIcon } from '../../../components/Icons/icons';

import styles from './Root.module.scss';

const MenuGroup = ({
    title,
    routes,
}) => (
    <div
        className={`${styles.menu__link} ${styles['menu__link--group']}`}
    >
        {title}
        <div
            className={styles['menu__link-select']}
        >
            <div className={styles['menu__link-select__title']}>Поиск</div>
            <Select
                name={`MenuGroupItem--${title}`}
                options={routes.map((routeItem) => ({
                    value: routeItem.link,
                    label: routeItem.title,
                }))}
                wrappers={{
                    // eslint-disable-next-line react/prop-types
                    OptionWrapper: ({ value, label }) => (
                        <NavLink
                            key={value}
                            to={value}
                            className={`${styles.menu__link}`}
                        >
                            {label}
                        </NavLink>
                    ),
                    DropdownIndicatorWrapper: () => (
                        <div className={styles['menu__link-select__icon']}>
                            <SVGIcon SVGElement={SearchIcon} />
                        </div>
                    ),
                }}
                searchable
                menuIsOpen
            />
        </div>
    </div>
);

MenuGroup.propTypes = {
    title: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            link: PropTypes.string,
        }),
    ).isRequired,
};

export default MenuGroup;
