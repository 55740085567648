import React from 'react';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import DetailedView from '../../shared/DetailedView';
import { Button } from '../../../components/Button';
import SVGIcon from '../../../components/Icons/SVGIcon';
import { BanIcon, SupportIcon } from '../../../components/Icons/icons';
import ObjectComponentApprove from '../../shared/ObjectComponent/ObjectComponentApprove';
import { OPERATOR_STATUS } from '../../../constants/operator';

import styles from './OperatorDetailed.module.scss';

const OperatorDetailed = ({
    id,
    useStores,
    store,
    onClose,
}) => {
    if (!store) {
        return null;
    }
    const { dialogStore } = useStores();
    const { form } = store;
    const toggleApprovingState = () => {
        store.setApprovingState(false);
    };
    const handleApproveCancel = () => {
        dialogStore.hide();
    };
    const handleApprove = (e) => {
        form.onSubmit(e);
        form.on('error', () => toggleApprovingState());
    };

    return (
        <div className={styles['operator-detailed']}>
            <Observer>
                {() => (
                    store.approving
                        ? (
                            <ObjectComponentApprove
                                onClose={handleApproveCancel}
                                onCancel={handleApproveCancel}
                                onApprove={handleApprove}
                                toggleApprovingState={toggleApprovingState}
                                disabled={form.loading}
                            />
                        )
                        : null
                )}
            </Observer>
            <DetailedView
                id={id}
                key={`operator-${id}`}
                useStores={useStores}
                store={store}
                wrapper={({ children }) => (
                    <div className={styles['operator-detailed__wrapper']}>
                        <div className={styles['operator-detailed__header']}>
                            <div className={styles['operator-detailed__icon']}>
                                <SVGIcon
                                    SVGElement={SupportIcon}
                                    height={20}
                                />
                            </div>
                            <div className={styles['operator-detailed__title']}>
                                Редактирование
                                <br />
                                профиля оператора
                            </div>
                        </div>
                        {children}
                    </div>
                )}
            >
                <Observer>
                    {() => (
                        <div
                            className={`
                                ${styles.footer}
                                ${styles['operator-detailed__footer']}
                            `}
                        >
                            <div
                                className={`
                                    ${styles.footer__col}
                                    ${styles.footer__operator}
                                    ${store.statusName !== OPERATOR_STATUS.ACTIVE ? styles['footer__operator--blocked'] : ''}
                                `}
                            >
                                {
                                    store.createdAtDate
                                        ? (
                                            <>
                                                <div>
                                                    <div>Зарегистрирован</div>
                                                    <div>
                                                        {store.createdAtDate}
                                                        &nbsp;в&nbsp;
                                                        {store.createdAtTime}
                                                    </div>
                                                </div>
                                                <div>
                                                    <SVGIcon
                                                        SVGElement={BanIcon}
                                                    />
                                                </div>
                                            </>
                                        )
                                        : null
                                }
                            </div>
                            <div className={styles.footer__col}>
                                <div
                                    className={`
                                        ${styles.footer__buttons}
                                        ${(form.snapshotIsDiffer || store.userChanged) ? styles['footer__buttons--active'] : ''}
                                    `}
                                >
                                    <Button
                                        type={Button.TYPE.BUTTON}
                                        size="md"
                                        color="white-black"
                                        className={styles.footer__button}
                                        onClick={onClose}
                                        disabled={form.loading}
                                    >
                                        Отменить изменения
                                    </Button>
                                    <Button
                                        type={Button.TYPE.SUBMIT}
                                        size="md"
                                        color="white-green"
                                        className={styles.footer__button}
                                        disabled={form.loading}
                                    >
                                        Сохранить изменения
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </Observer>
            </DetailedView>
        </div>
    );
};

OperatorDetailed.propTypes = {
    onClose: PropTypes.func,
    ...DetailedView.propTypes,
};

OperatorDetailed.defaultProps = {
    onClose: () => null,
    ...DetailedView.defaultProps,
};

export default OperatorDetailed;
