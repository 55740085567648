/* global alert */
import {
    action,
    computed,
    observable,
    toJS,
} from 'mobx';
import { OperatorService } from '../../../services/operator';
import DataSource from '../../shared/DataSource';
import Pagination from '../../shared/DataSource/entities/Pagination';
import { ListWithDataSource } from '../../shared/List';
import OperatorItem from './item';
import { operatorReport as operatorReportFields } from '../../../forms/operator';
import createForm from '../../../helpers/Form';
import tableHeaders from './table';

class OperatorReport extends ListWithDataSource {
    @observable
    filterIsReady = false;

    filterForm;

    filterData;

    constructor() {
        super(
            OperatorItem,
            new DataSource(OperatorService),
            new Pagination(),
        );
        this.createFilterForm();
    }

    // eslint-disable-next-line class-methods-use-this
    get tableHeaders() {
        return tableHeaders;
    }

    @computed
    get tableData() {
        return this.list;
    }

    @action
    setFilterIsReady(status) {
        this.filterIsReady = status;
    }

    getList(page = 1, filter) {
        return this.dataSource.getReport(filter, page);
    }

    load(page = 1) {
        const { filterForm, filterIsReady } = this;
        if (!filterIsReady) {
            this.dataSource.setPreload();
            return new Promise((resolve) => {
                filterForm.on('ownertype:preloaded', () => {
                    super.load(page, this.prepareFilterData())
                        .then((list) => {
                            this.setList(list);
                            resolve();
                        })
                        .catch((e) => alert(e));
                });
            });
        }
        return super.load(page, this.prepareFilterData())
            .then((list) => {
                this.setList(list);
                return Promise.resolve();
            })
            .catch((e) => alert(e));
    }

    createFilterForm() {
        const { filterForm } = this;
        if (filterForm) {
            return filterForm;
        }
        this.filterForm = createForm(operatorReportFields);
        this.setFilterData(this.filterForm.clearValues(this.filterForm.values()));
        this.initFilterFormListeners();
        return this.filterForm;
    }

    setFilterData(filterData) {
        this.filterData = filterData;
    }

    prepareFilterData() {
        const { filterData } = this;
        return {
            ...filterData,
            timezoneOffset: ((new Date()).getTimezoneOffset() / 60) * -1,
        };
    }

    initFilterFormListeners() {
        const { filterForm } = this;
        if (!filterForm) return;
        const statusFormField = filterForm.$('status');
        const ownerTypeFormField = filterForm.$('ownerTypeIds');
        const createObserver = (field) => ({ change }) => {
            const { newValue } = change;
            const newValueJS = toJS(newValue);
            const extra = field.get('extra');
            if (newValueJS.length === 0) {
                field.set('value', extra.options);
            }
        };
        filterForm.on('ownertype:preloaded', (options) => {
            ownerTypeFormField.set('value', options);
            this.setFilterData(filterForm.clearValues(this.filterForm.values()));
            this.setFilterIsReady(true);
            ownerTypeFormField.observe(createObserver(ownerTypeFormField));
        });
        statusFormField.observe(createObserver(statusFormField));
        filterForm.on('success', (values) => {
            this.setFilterData(filterForm.clearValues(values));
            return this.load();
        });
    }
}

export default OperatorReport;
