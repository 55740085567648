import { toJS } from 'mobx';

const withStatusField = (statusFields) => (Store) => (
    class extends Store {
        statusFields;

        constructor(fields) {
            super(fields);
            const { status, reason } = statusFields;
            if (!status || !reason) {
                throw new Error('Both status and reason fields required');
            }
            if (!this.$(status) || !this.$(reason)) {
                throw new Error('Both status and reason fields must be defined in form');
            }
            this.statusFields = statusFields;
            this.observeStatusField();
        }

        observeStatusField() {
            const form = this;
            const { statusFields: { status, reason } } = this;
            const statusField = form.$(status);
            const reasonField = form.$(reason);
            statusField.observe(({ change }) => {
                const { newValue } = change;
                const newValueJS = toJS(newValue);
                const isStatusMultiple = newValueJS instanceof Array;
                const isReasonMultiple = Boolean(reasonField.multiple);
                let statusIds = [];
                if (isStatusMultiple) {
                    statusIds = newValueJS.map(({ value }) => value);
                } else if (newValueJS) {
                    if (newValueJS instanceof Object) {
                        statusIds = [newValueJS.value];
                    } else {
                        statusIds = [newValueJS];
                    }
                }
                reasonField.set('extra', {
                    ...reasonField.extra,
                    ctx: { statusIds },
                });
                if (isReasonMultiple) {
                    reasonField.set(
                        'value',
                        reasonField.value && reasonField.value.filter(({ statusId }) => (
                            statusIds.includes(statusId)
                        )),
                    );
                } else {
                    reasonField.set(
                        'value',
                        reasonField.value && statusIds.includes(reasonField.value.statusId)
                            ? reasonField.value
                            : undefined,
                    );
                }
            });
        }
    }
);

export default withStatusField;
