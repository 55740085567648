import { DIRECTORIES } from '../../constants/operator';

export default [{
    name: 'statusId',
    directory: DIRECTORIES.OBJECT_STATUS,
    type: 'directoryTagList',
    label: 'Статус',
    rules: 'required',
    listType: 'radio',
    size: 'md',
}, {
    name: 'reasonId',
    directory: DIRECTORIES.OBJECT_STATUS_REASON,
    type: 'directoryTagList',
    label: 'Причина',
    listType: 'radio',
    size: 'md',
}, {
    name: 'comment',
    type: 'textarea',
    label: 'Комментарий',
    rows: 2,
    size: 'sm',
    rules: 'string',
}];
