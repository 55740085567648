import { TABLE_CELL } from '../../../../constants/operator';

const tableHeaders = [
    TABLE_CELL.ROW_SELECT,
    {
        name: 'name',
        title: 'Наименование',
        textAlign: 'left',
    },
];

export default tableHeaders;
