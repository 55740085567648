import { PresetService } from '../../../services/operator';
import PresetList from '../../shared/PresetList';

class OperatorPresetList extends PresetList {
    constructor() {
        super(PresetService);
    }
}

export default OperatorPresetList;
