import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../../components/Label';

import styles from './ActivationKeyDetailed.module.scss';

const ActivationKeyDetailedField = ({
    label,
    id,
    hidden,
    children,
}) => {
    if (hidden) return null;
    return (
        <div
            className={`
                ${styles['activation-key__field']}
                ${hidden ? styles['activation-key__field--hidden'] : ''}
            `}
        >
            {
                label
                    ? (
                        <Label
                            className={styles['activation-key__label']}
                            htmlFor={id}
                        >
                            {label}
                        </Label>
                    )
                    : null
            }
            {children}
        </div>
    );
};

ActivationKeyDetailedField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    hidden: PropTypes.bool,
    children: PropTypes.element.isRequired,
};

ActivationKeyDetailedField.defaultProps = {
    label: '',
    hidden: false,
};

export default ActivationKeyDetailedField;
