import { format } from 'date-fns';
import { computed } from 'mobx';
import { ListItem } from '../../../shared/List';
import {
    DEAL_TYPE_NAME,
    OBJECT_TYPE_NAME,
    DEFAULT_DATE_FORMAT,
    DEFAULT_TIME_FORMAT,
} from '../../../../constants/shared';

class LogItem extends ListItem {
    @computed
    get operatorName() {
        const { $operator } = this;
        return $operator;
    }

    @computed
    get created() {
        const { $createdAt } = this;
        return $createdAt
            ? format(new Date($createdAt), DEFAULT_DATE_FORMAT)
            : null;
    }

    @computed
    get createdTime() {
        const { $createdAt } = this;
        return $createdAt
            ? format(new Date($createdAt), DEFAULT_TIME_FORMAT)
            : null;
    }

    @computed
    get objectAddress() {
        const { $object } = this;
        if (!$object) {
            return null;
        }
        return $object.info;
    }

    @computed
    get objectTypeName() {
        const { $object } = this;
        if (!$object) {
            return null;
        }
        return OBJECT_TYPE_NAME[$object.objectType];
    }

    @computed
    get dealTypeName() {
        const { $object } = this;
        if (!$object) {
            return null;
        }
        return DEAL_TYPE_NAME[$object.dealType];
    }

    @computed
    get objectType() {
        const { $object } = this;
        if (!$object) {
            return null;
        }
        return $object.objectType;
    }

    @computed
    get dealType() {
        const { $object } = this;
        if (!$object) {
            return null;
        }
        return $object.dealType;
    }

    @computed
    get objectId() {
        const { $object } = this;
        if (!$object) {
            return null;
        }
        return $object.id;
    }
}

export default LogItem;
