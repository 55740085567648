import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { EMPTY_DATA_STRING, POSITION } from '../../../constants/shared';

import styles from './Table.module.scss';

const CellDefaultWithTooltip = (props) => {
    const [ellisped, setEllipsed] = useState(false);
    const { value } = props;
    const str = value || EMPTY_DATA_STRING;
    const wrapperRef = useCallback((node) => {
        if (node !== null) {
            setEllipsed(node.offsetWidth < node.scrollWidth);
        }
    }, []);

    return (
        <div
            ref={wrapperRef}
            className={styles['table__cell-ellipsed']}
            data-tip={ellisped ? str : null}
            data-effect="solid"
            data-place={POSITION.RIGHT}
            data-class={styles.table__hint}
        >
            {str}
        </div>
    );
};

CellDefaultWithTooltip.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

CellDefaultWithTooltip.defaultProps = {
    value: '',
};

export default CellDefaultWithTooltip;
