import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import ActivationKeyList from '../../../stores/operator/ActivationKeyList';
import PageView from '../../shared/PageView';
import Filter from './ActivationKeyListFilter';

import styles from './ActivationKeyList.module.scss';

const ActivationKeyListPage = ({
    store,
    children,
    meta,
}) => (
    <PageView
        meta={{ ...meta, icon: false }}
    >
        {({ setCollapsed }) => (
            <>
                <Observer>
                    {() => (
                        store.filterForm
                            ? (
                                <Filter
                                    filterForm={store.filterForm}
                                    title="Поиск"
                                    onCollapse={setCollapsed}
                                />
                            )
                            : null
                    )}
                </Observer>
            </>
        )}
        <div className={styles['page-view__wrapper']}>
            {children}
        </div>
    </PageView>
);

ActivationKeyListPage.propTypes = {
    store: PropTypes.oneOfType([
        PropTypes.instanceOf(ActivationKeyList),
        PropTypes.shape({}),
    ]).isRequired,
    meta: PropTypes.shape({
        pageTitle: PropTypes.string,
        icon: PropTypes.func,
        sisterRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
        parentRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
    }),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]).isRequired,
};

ActivationKeyListPage.defaultProps = {
    meta: {
        title: null,
        icon: null,
        sisterRoutes: [],
        parentRoutes: [],
    },
};

export default ActivationKeyListPage;
