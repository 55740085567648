import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import Filter from '../Filter/Filter';
import { Button } from '../../../components/Button';
import BillingCommonFilterField from './BillingCommonFilterField';

import styles from './BillingCommon.module.scss';

const BillingCommonFilterFooter = ({ form }) => (
    <Observer>
        {() => (
            <div className={styles.filter__footer}>
                <Button
                    type="submit"
                    size={Button.SIZE.LARGE}
                    color={Button.COLOR.BLUE}
                    block
                    disabled={form.loading}
                >
                    Искать
                </Button>
            </div>
        )}
    </Observer>
);

BillingCommonFilterFooter.propTypes = {
    form: PropTypes.shape({
        loading: PropTypes.bool,
    }).isRequired,
};

const BillingCommonFilter = ({
    filterForm,
    title,
    customClassNames,
    onCollapse,
}) => (
    <Filter
        form={filterForm}
        title={title}
        fullHeight={false}
        components={{
            FilterField: BillingCommonFilterField,
            FilterFooter: BillingCommonFilterFooter,
        }}
        customClassNames={{
            fieldList: `${styles['filter__field-list']} ${customClassNames.fieldList || ''}`,
        }}
        onCollapse={onCollapse}
    />
);

BillingCommonFilter.propTypes = {
    filterForm: PropTypes.shape({}).isRequired,
    title: PropTypes.string,
    customClassNames: PropTypes.shape({
        fieldList: PropTypes.string,
    }),
    onCollapse: PropTypes.func,
};

BillingCommonFilter.defaultProps = {
    title: '',
    customClassNames: {},
    onCollapse: () => null,
};

export default BillingCommonFilter;
