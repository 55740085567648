import { computed, observable } from 'mobx';
import BaseItem from './BaseItem';

class CityItem extends BaseItem {
    @observable $region;

    @observable $area;

    @observable $cityType;

    @observable $suburbOfCity;

    @observable $suburbOfCityCheckbox;

    @computed
    get regionName() {
        const { $region: $val } = this;
        return $val
            ? `${$val.name} ${$val.regionType.shortname} `
            : null;
    }

    @computed
    get areaName() {
        const { $area: $val } = this;
        return $val
            ? `${$val.areaType.shortname} ${$val.name}`
            : null;
    }

    @computed
    get cityName() {
        return `${this.cityTypeName} ${this.$name}`;
    }

    @computed
    get cityTypeName() {
        const { $cityType: $val } = this;
        return $val && $val.shortname ? $val.shortname : null;
    }

    @computed
    get suburbOfCityName() {
        const { $suburbOfCity: $val } = this;
        return $val;
    }

    @computed
    get areaId() {
        const { $area: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }

    @computed
    get regionId() {
        const { $region: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }

    @computed
    get cityTypeId() {
        const { $cityType: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }

    @computed
    get suburbOfCityCheckbox() {
        const { $suburbOfCity: $val } = this;
        return !!$val;
    }

    initItemFormListeners() {
        const {
            parent,
            itemForm,
            id,
        } = this;
        itemForm.on('success', (values) => {
            if (values.suburbOfCityCheckbox === 0) {
                // eslint-disable-next-line no-param-reassign
                values.suburbOfCityId = null;
            }
            const { suburbOfCityCheckbox, suburbOfCity, ...restValues } = values;
            parent.update(id, itemForm.clearValuesExceptSuburb(restValues))
                .then((response) => {
                    this.setData(response);
                    return Promise.resolve(response);
                });
        });
    }
}

export default CityItem;
