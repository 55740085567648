/* eslint linebreak-style: 0 */

/**
 * Получить массив объектов, уникальных по полю
 * @param {Array} array - оригинальный массив
 * @param {string} objectFieldName - наименования поля
 * @returns новый массив, с объектами, которые уникальны по указанному полю
*/
// eslint-disable-next-line import/prefer-default-export
export const getDistinctedBy = (array, objectFieldName) => array.filter(
    (obj, index, self) => index === self
        .findIndex((t) => t[objectFieldName] === obj[objectFieldName]),
);
