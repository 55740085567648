/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { OPERATOR_REPORT, POSITION } from '../../../constants/operator';

import styles from './OperatorReport.module.scss';

const CellReport = ({ value: { total, actions } }) => (
    <div
        data-for={OPERATOR_REPORT}
        data-tip={JSON.stringify(actions)}
        data-effect="solid"
        data-place={POSITION.TOP}
        data-class={styles.hint}
    >
        {total}
    </div>
);

CellReport.defaultProps = {
    value: {
        total: 0,
        actions: [],
    },
};

CellReport.propTypes = {
    value: PropTypes.shape({
        total: PropTypes.string,
        actions: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

const CellDone = ({ value: { value, success } }) => (
    <div className={`${styles.done} ${success ? styles['done--success'] : ''}`}>
        {value}
    </div>
);

CellDone.defaultProps = {
    value: {
        value: '0',
        success: false,
    },
};

CellDone.propTypes = {
    value: PropTypes.shape({
        value: PropTypes.string,
        success: PropTypes.bool,
    }),
};

const CellPlan = ({ value }) => (
    <div className={styles.plan}>
        {value}
    </div>
);

CellPlan.defaultProps = {
    value: '0',
};

CellPlan.propTypes = {
    value: PropTypes.string,
};

export {
    CellReport,
    CellDone,
    CellPlan,
};
