import BaseStrategy from './BaseStrategy';
import { AgencyItem } from '../item';
import { DIRECTORIES } from '../../../../constants/operator';
import createForm from '../../../../helpers/Form';
import { directory as forms } from '../../../../forms/operator';

class AgencyStrategy extends BaseStrategy {
    constructor(options) {
        super(DIRECTORIES.AGENCY, options);
        this.Item = AgencyItem;
    }

    // eslint-disable-next-line consistent-return
    createFilterForm() {
        const { filterFormName, filterForm, options: { localeName } } = this;
        const form = forms[filterFormName];
        if (filterForm) return filterForm;
        this.filterForm = createForm(form);
        if (localeName && this.filterForm.has('query')) {
            this.filterForm.$('query').set('label', localeName);
        }
        return this.filterForm;
    }

    // eslint-disable-next-line class-methods-use-this
    prepareItemData(data) {
        const { agencyAliases: aliases, ...rest } = data;
        let preparedAliases;
        if (typeof aliases === 'string') {
            preparedAliases = aliases.split(';');
            preparedAliases.forEach((alias, i) => {
                preparedAliases[i] = alias.replace(/\s+/g, ' ').trim();
            });
        }
        return {
            aliases: preparedAliases || [],
            ...rest,
        };
    }
}

export default AgencyStrategy;
