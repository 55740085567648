import { format } from 'date-fns';
import { computed } from 'mobx';
import { ListItem } from '../../../shared/List';
import numberWithSpaces from '../../../../utils/number-with-spaces';
import { DEFAULT_DATE_FORMAT } from '../../../../constants/shared';

class CompanyItem extends ListItem {
    @computed
    get companyName() {
        const { $name } = this;
        return $name;
    }

    @computed
    get activeUntil() {
        const { $activeUntil } = this;
        return $activeUntil
            ? format(new Date($activeUntil), DEFAULT_DATE_FORMAT)
            : null;
    }

    @computed
    get companyAddress() {
        const { $address } = this;
        return $address;
    }

    @computed
    get instances() {
        const { $instances } = this;
        return $instances || null;
    }

    @computed
    get instancesChanged() {
        const { $instance } = this;
        return $instance?.changed || null;
    }

    @computed
    get price() {
        const { $price } = this;
        return $price !== null ? numberWithSpaces($price) : null;
    }

    @computed
    get contactPhone() {
        const { $phone } = this;
        return $phone;
    }

    @computed
    get statusName() {
        const { status } = this;
        if (!status) {
            return null;
        }
        return status.name;
    }

    @computed
    get statusId() {
        const { status } = this;
        if (!status) {
            return null;
        }
        return status.id;
    }
}

export default CompanyItem;
