import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import { ContextMenu, MenuItem, SubMenu } from '../../../components/ContextMenu';
import {
    HomeBluePlusIcon,
    NotepadNestIcon,
    NotepadPlusIcon,
} from '../../../components/Icons/icons';
import KeyboardHandler from '../../../components/KeyboardHandler';
import {
    SORT_ORDER,
    TAB_TYPE,
    TABLE_CELL,
} from '../../../constants/operator';
import useStores from '../../../hooks/operator/use-stores';
import useKeyPress from '../../../hooks/shared/use-key-press';
import { PagesRouteInfo } from '../../../routes/utils/pagesRouteInfo';
import NotepadList from '../../../stores/shared/NotepadList/NotepadList';
import { PopupTabsWithApproveStore } from '../../../stores/shared/PopupTabsStore';
import PresetList from '../../../stores/shared/PresetList';
import ObjectListStore from '../../../stores/shared/RefactoredObjectList/ObjectList';
import MessageDialog from '../../shared/Dialog/MessageDialog';
import ExportObjects from '../../shared/ExportObjects';
import { ItemStatusModes } from '../../shared/ItemStatus/constants';
import ItemStatusWithContactsModal from '../../shared/Modals/itemStatusWithContactsModal';
import NotepadForm from '../../shared/NotepadList/Form';
import { ObjectListPage } from '../../shared/ObjectListPage';
import { LoadMoreBadge } from '../../shared/ObjectPageView';
import { PagePopups } from '../../shared/PagePopups';
import PresetForm from '../../shared/PresetList/Form';
import SelectedObject from '../../shared/SelectedObject';
import { CellPhone } from '../../shared/Table';
import MergeContextMenuItem from '../ContextMenu/mergeContextMenuItem';
import Dialog from '../Dialog';
import { Table } from '../Table';
import { TableWithSelected } from '../TableWithSelected';

/**
 * @param {Object} props
 * @param {boolean} props.selectable Флаг необходимости отображения информации по объекту
 * @param {string} props.pageName Наименование текущей страницы
 *  внизу грида при одиночном клике по строке
 */
const ObjectListContent = ({
    selectable,
    store: {
        list,
        tabs,
        notepads,
        exportObjects,
        createDetailed,
        createRelated,
        createNotepad,
    },
    meta,
    pageName,
}) => {
    const listRef = useRef();
    const keyboardRef = useRef();
    const {
        headerActionsStore,
        headerToolsStore,
        directoriesStore,
        dialogStore,
        presetListStore,
        objectListPageStore,
    } = useStores();

    const isCtrlPressed = useKeyPress('Control');
    const isShiftPressed = useKeyPress('Shift');
    const [selectedHeight, setSelectedHeight] = useState('266px');
    const [preventKeyboardHandler, setPreventKeyboardHandler] = useState(false);
    const [selectedMergeItemInfo, setSelectedMergeItemInfo] = useState({
        item: null,
        key: 0,
    });

    const { paginationHandler } = list;
    let keyPressDelay;

    // Functions
    /**
     * Открыть форму детализации Item'а
     * @param {string} id - идентификатор Item'а
     * @param {any} isExclusive
     */
    const openDetailedItemForm = (id, isExclusive) => {
        const item = list.getItem(id);
        if (tabs.getTab(item.id)) {
            return;
        }

        const instance = createDetailed();
        instance.setData({ id: item.id, exclusive: isExclusive });
        const form = instance.createForm();
        form.on('done', (response) => {
            if (!response.filter((res) => Boolean(res)).length) return;
            tabs.removeTab(item.id);
            item.setData(instance.toItem());
        });
        list.setCursor(item.id);
        instance.load(item.id);
        tabs.addTab(
            instance,
            item.id,
            item.objectName,
            TAB_TYPE.EDIT_OBJECT,
        );
    };

    /**
     * Отобразить детализацию по Item'у
     * @param {string} id - идентификатор Item'а
     * @param {boolean} isExclusive
     */
    const showDetalization = (id, isExclusive) => {
        const instance = createDetailed();
        instance.setData({ id, exclusive: isExclusive });
        // eslint-disable-next-line no-use-before-define
        if (handleRowClick.callback) {
            instance.load(id, false);
            // eslint-disable-next-line no-use-before-define
            handleRowClick.callback(instance);
        }
    };

    // Interactions
    const handleLoadNewClick = () => {
        list.reload()
            .then(() => {
                listRef.current.scrollTo(0);
            });
    };

    const handleCreateObjectClick = () => {
        if (!tabs.getTab('new')) {
            const instance = createDetailed();
            const form = instance.createForm();
            form.on('done', () => {
                tabs.removeTab('new');
            });
            tabs.addTab(
                instance,
                'new',
                'Новый объект',
                TAB_TYPE.EDIT_OBJECT,
            );
        }
    };

    const handleRowClick = (row) => {
        const { id, isExclusive } = row.original;

        if (isShiftPressed()) {
            list.selectRange(list.cursor, id);
            return;
        }

        list.setCursor(id);

        if (isCtrlPressed()) {
            const item = list.getItem(id);
            item.toggle();
            return;
        }

        if (selectable) {
            showDetalization(id, isExclusive);
            return;
        }

        openDetailedItemForm(id, isExclusive);
    };

    const handleDoubleRowClick = (data) => {
        const {
            original: { id, isExclusive },
        } = data;
        openDetailedItemForm(id, isExclusive);
    };

    const handlePhoneClick = (id, phone) => {
        const item = list.getItem(id);
        const { objectName } = item;
        const relatedTabId = `${id}-related`;
        if (!tabs.getTab(relatedTabId)) {
            const related = createRelated(id, phone);
            related.load();
            tabs.addTab(
                {
                    related,
                    phone,
                    tabs,
                },
                relatedTabId,
                objectName || id,
                TAB_TYPE.RELATED_OBJECTS,
            );
        }
    };

    const handleNewNotepadClick = () => {
        const itemForm = notepads.createItemForm();
        const unsubscribeForm = itemForm.on('done', () => {
            dialogStore.hide('new-notepad-item');
        });
        const unsubscribeDialog = dialogStore.on('new-notepad-item:hidden', () => {
            unsubscribeForm();
            unsubscribeDialog();
        });
        dialogStore.show('new-notepad-item');
    };

    const handleNotepadAdd = (e, { id }) => {
        notepads.addObjectsToNotepad(
            id,
            list.selected && list.selected.length
                ? list.selected.map(({ id: objectId }) => objectId)
                : [list.cursor],
            list.objectType,
        )
            .then(() => {
                const tabStore = tabs.tabs.find(({ id: tabId }) => tabId === `${id}-notepad`);
                list.unselectAll();
                if (tabStore) {
                    const { list: currentNotepadList } = tabStore.store.notepad;
                    currentNotepadList.reload();
                } else {
                    dialogStore.show('message');
                }
            });
    };

    const handleNotepadClick = (id, name) => {
        const notepadTabId = `${id}-notepad`;
        if (!tabs.getTab(notepadTabId)) {
            const notepad = createNotepad(id);
            tabs.addTab(
                {
                    notepads,
                    notepad,
                    tabs,
                    name,
                },
                notepadTabId,
                name || id,
                TAB_TYPE.NOTEPAD_OBJECTS,
            );
        }
    };

    const handleNewPresetClick = () => {
        const itemForm = presetListStore.createItemForm();
        const unsubscribeFormDone = itemForm.on('done', () => {
            dialogStore.hide('new-preset-item');
        });
        const unsubscribeFormSuccess = itemForm.on('success', (values) => (
            list.getPresetFilterData().then((preset) => {
                presetListStore.create({ ...preset, ...values });
            })
        ));
        const unsubscribeDialog = dialogStore.on('new-preset-item:hidden', () => {
            unsubscribeFormDone();
            unsubscribeDialog();
            unsubscribeFormSuccess();
        });
        dialogStore.show('new-preset-item');
    };

    const handleSortChange = ({ sortName, sortDirection }) => {
        let nextDirection;
        switch (sortDirection) {
            case SORT_ORDER.ASC:
                nextDirection = SORT_ORDER.DESC;
                break;
            case SORT_ORDER.DESC:
                nextDirection = SORT_ORDER.ASC;
                break;
            default:
                nextDirection = (
                    sortName === TABLE_CELL.SHORT_DISTRICT_NAME.sortName
                    || sortName === TABLE_CELL.DISTRICT_NAME.sortName
                )
                    ? SORT_ORDER.DESC
                    : SORT_ORDER.ASC;
        }
        list.setSort(sortName, nextDirection);
        list.reload();
    };

    const handleArrowKey = (direction) => {
        if (direction === -1) {
            list.setPrevCursor();
        } else {
            list.setNextCursor();
        }
        const currentId = list.cursor;
        const index = list.tableData.findIndex(({ id }) => id === currentId);
        listRef.current?.scrollToItem(index);
        if (selectable) {
            if (keyPressDelay) {
                clearTimeout(keyPressDelay);
            }
            keyPressDelay = setTimeout(() => {
                handleRowClick({ original: { id: currentId } });
            }, 300);
        }
    };

    const handleDownKey = () => {
        handleArrowKey(1);
    };

    const handleUpKey = () => {
        handleArrowKey(-1);
    };

    const handleEnterKey = () => {
        const id = list.cursor;
        const { isExclusive } = list.getItem(id);
        openDetailedItemForm(id, isExclusive);
    };

    const handleSpaceKey = () => {
        list
            ?.getItem(list.cursor)
            ?.toggle();
    };

    const handleObjectClose = () => {
        keyboardRef.current.focus();
    };

    const handleContextMenuShow = () => {
        if (notepads) {
            notepads.load();
        }
    };

    const handleModalOpen = (isOpen) => setPreventKeyboardHandler(isOpen);

    const onMergeContextMenuClick = () => {
        const item = list.getItem(list.cursor);
        setSelectedMergeItemInfo((prevInfo) => ({
            item,
            key: prevInfo.key + 1,
        }));
    };

    // Table headers
    const modifyHeaders = (headers) => headers.map((header) => {
        switch (header.name) {
            case 'contactPhone':
                return {
                    ...header,
                    Cell: (props) => (
                        <CellPhone
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...props}
                            onClick={handlePhoneClick}
                        />
                    ),
                };
            default:
                return header;
        }
    });

    // Effects
    useEffect(() => {
        if (!list.load) {
            return;
        }
        if (objectListPageStore?.selected) {
            const presetFilter = list.convertPresetFilterData(objectListPageStore.selected.filter);
            list.applyFilter(presetFilter.filter);
            list.filterForm.createSnapshot('preset');
        } else {
            list.filterForm.removeSnapshot('preset');
        }
        if (directoriesStore.defaultsLoaded) {
            list.filterForm.submit();
        } else {
            list.preload();
            directoriesStore.on('defaults:loaded', () => {
                list.filterForm.submit();
            });
        }
        keyboardRef.current.focus();
        headerToolsStore.setTarget(null);
        headerActionsStore.setActions([
            ...headerActionsStore.actions,
            {
                actionText: 'Добавить объект',
                icon: HomeBluePlusIcon,
                actionFunction: handleCreateObjectClick,
            },
        ]);
    }, [list]);

    useEffect(() => {
        headerActionsStore.setActions();
    }, []);

    // Component
    return (
        <div className="object-list-content">
            <ObjectListPage
                meta={meta}
                store={list}
                notepads={notepads}
                presets={presetListStore}
                tabsStore={tabs}
                onNotepadOpen={notepads && handleNotepadClick}
                onPresetAdd={presetListStore && handleNewPresetClick}
                useStores={useStores}
            >
                <Observer>
                    {() => (
                        <LoadMoreBadge
                            onClick={handleLoadNewClick}
                            isShown={Boolean(paginationHandler.data.newItems)}
                        >
                            Посмотреть
                            &nbsp;
                            {paginationHandler.data.newItems}
                            &nbsp;
                            новых объявления
                        </LoadMoreBadge>
                    )}
                </Observer>
                <ContextMenuTrigger id="objects-context-menu">
                    <KeyboardHandler
                        ref={keyboardRef}
                        onDown={handleDownKey}
                        onUp={handleUpKey}
                        onEnter={handleEnterKey}
                        onSpace={handleSpaceKey}
                        preventHandler={() => preventKeyboardHandler || dialogStore.isOpen === 'new-agency-item'}
                    >
                        {
                            selectable
                                ? (
                                    <Observer>
                                        {() => (
                                            <TableWithSelected
                                                ref={listRef}
                                                headers={modifyHeaders(list.tableHeaders)}
                                                store={list}
                                                selectedContentHeight={selectedHeight}
                                                Detailed={createDetailed()}
                                                onRowClick={handleRowClick}
                                                onDoubleRowClick={handleDoubleRowClick}
                                                onTableHeaderClick={handleSortChange}
                                                onStatusTooltipToggle={handleModalOpen}
                                                components={{
                                                    /* eslint-disable react/prop-types */
                                                    SelectedWrapper: (props) => (
                                                        <SelectedObject
                                                            setSelectedHeight={setSelectedHeight}
                                                            onPhoneClick={handlePhoneClick}
                                                            // eslint-disable-next-line max-len
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...props}
                                                        />
                                                    ),
                                                    /* eslint-enable react/prop-types */
                                                }}
                                            />
                                        )}
                                    </Observer>
                                )
                                : (
                                    <Observer>
                                        {() => (
                                            <Table
                                                ref={listRef}
                                                headers={modifyHeaders(list.tableHeaders)}
                                                store={list}
                                                onRowClick={handleRowClick}
                                                onTableHeaderClick={handleSortChange}
                                                onStatusTooltipToggle={handleModalOpen}
                                            />
                                        )}
                                    </Observer>
                                )
                        }
                    </KeyboardHandler>
                </ContextMenuTrigger>
                <Observer>
                    {() => (
                        <ContextMenu id="objects-context-menu" onShow={handleContextMenuShow}>
                            <MenuItem icon={HomeBluePlusIcon} onClick={handleCreateObjectClick}>
                                Добавить объект
                            </MenuItem>
                            {
                                notepads
                                    ? (
                                        <>
                                            <MenuItem
                                                icon={NotepadPlusIcon}
                                                onClick={handleNewNotepadClick}
                                            >
                                                Создать блокнот
                                            </MenuItem>
                                            <SubMenu
                                                icon={NotepadNestIcon}
                                                title="Добавить в блокнот"
                                                disabled={
                                                    !notepads.list.length
                                                    || (!list.selected.length && !list.cursor)
                                                }
                                            >
                                                {notepads.list.map((item) => (
                                                    <MenuItem
                                                        key={`notepad-menu-${item.id}`}
                                                        data={{ id: item.id }}
                                                        onClick={handleNotepadAdd}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </SubMenu>
                                        </>
                                    ) : null
                            }
                            <MergeContextMenuItem
                                primaryObjectId={list.cursor}
                                selected={list.selected}
                                onClick={onMergeContextMenuClick}
                                visible={
                                    [
                                        PagesRouteInfo.Duplicates.PageTitle,
                                        PagesRouteInfo.Parser.PageTitle,
                                    ].includes(pageName)
                                }
                            />
                        </ContextMenu>
                    )}
                </Observer>
            </ObjectListPage>

            <ItemStatusWithContactsModal
                item={selectedMergeItemInfo.item}
                mode={ItemStatusModes.Merge}
                openKey={selectedMergeItemInfo.key}
                onOpenChange={handleModalOpen}
                useStores={useStores}
            />

            <PagePopups
                useStores={useStores}
                tabsStore={tabs}
                onClose={handleObjectClose}
                createRelated={createRelated}
                createDetailed={createDetailed}
            />
            {
                notepads
                    ? (
                        <>
                            <Dialog
                                name="new-notepad-item"
                                size="sm"
                                footer={null}
                                header={<>Создать блокнот</>}
                            >
                                <Observer>
                                    {() => (
                                        <NotepadForm
                                            useStores={useStores}
                                            button="Добавить"
                                            form={notepads.createItemForm()}
                                        />
                                    )}
                                </Observer>
                            </Dialog>
                            <Dialog
                                useStores={useStores}
                                name="preset-item-save"
                                size="xs"
                                contentHeight="auto"
                                footer={null}
                                header={null}
                            >
                                {() => (
                                    <p>Подборка сохранена</p>
                                )}
                            </Dialog>
                            <Dialog
                                name="new-preset-item"
                                size="sm"
                                footer={null}
                                header={<>Создать подборку</>}
                            >
                                <Observer>
                                    {() => (
                                        <PresetForm
                                            useStores={useStores}
                                            button="Создать"
                                            form={presetListStore.createItemForm()}
                                        />
                                    )}
                                </Observer>
                            </Dialog>
                            <MessageDialog
                                useStores={useStores}
                                name="message"
                                header={<>Сообщение</>}
                            >
                                <Observer>
                                    {() => (
                                        <>
                                            {notepads.message}
                                        </>
                                    )}
                                </Observer>
                            </MessageDialog>
                        </>
                    ) : null
            }
            {
                exportObjects
                    ? (
                        <ExportObjects
                            useStores={useStores}
                            exportObjects={exportObjects}
                        />
                    )
                    : null
            }
        </div>
    );
};

ObjectListContent.propTypes = {
    selectable: PropTypes.bool,
    store: PropTypes.shape({
        list: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(ObjectListStore),
        ]),
        tabs: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(PopupTabsWithApproveStore),
        ]),
        createDetailed: PropTypes.func,
        createRelated: PropTypes.func,
        createNotepad: PropTypes.func,
        notepads: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(NotepadList),
        ]),
        presets: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(PresetList),
        ]),
        exportObjects: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.instanceOf(ExportObjects),
        ]),
    }),
    meta: PropTypes.shape({}),
    pageName: PropTypes.string,
};

ObjectListContent.defaultProps = {
    selectable: false,
    store: {
        list: {
            tableData: [],
            tableHeaders: [],
            paginationHandler: {
                data: {
                    totalItems: 0,
                },
            },
        },
        tabs: {
            tabs: [],
        },
        notepads: null,
        exportObjects: {},
        createDetailed: () => null,
        createRelated: () => null,
        createNotepad: () => null,
    },
    meta: {},
    pageName: undefined,
};

export default ObjectListContent;
