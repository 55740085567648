/* global alert */
import { computed, observable } from 'mobx';
import createForm from '../../../helpers/Form';
import { BillingService } from '../../../services/operator';
import DataSource from '../../shared/DataSource';
import { debt } from '../../../forms/operator';

class BillingDebt {
    id;

    @observable
    form;

    constructor(id) {
        this.id = id;
        this.dataSource = new DataSource(BillingService);
        this.createForm();
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    createForm() {
        this.form = createForm(debt);
        this.initFormListeners();
    }

    initFormListeners() {
        const { form } = this;
        form.on('success', this.setDebt.bind(this));
    }

    setDebt(values) {
        const { id } = this;
        return this.dataSource.setDebt(id, values)
            .catch((e) => {
                const { response } = e;
                alert(response?.data?.error?.message || e.message);
            });
    }

    cancelDebt() {
        const { id } = this;
        return this.dataSource.deleteDebt(id)
            .catch((e) => {
                const { response } = e;
                alert(response?.data?.error?.message || e.message);
            });
    }
}

export default BillingDebt;
