import React from 'react';
import PropTypes from 'prop-types';
import findField from '../../../forms/utils/find-field';
import BillingUsersListStore from '../../../stores/operator/RefactoredBillingUsersList';
import PageView, { PageViewExtra } from '../../shared/PageView';
import { BillingCommonStatusFilter, BillingCommonFilter } from '../BillingCommon';
import BillingStats from '../BillingStats/BillingStats';

import styles from './BillingUsersList.module.scss';

const BillingUsersListPage = ({
    store,
    children,
    meta,
}) => (
    <PageView
        meta={meta}
        classList={{
            main: styles['page-view__main'],
            mainColllapsed: styles['page-view__main--collapsed'],
        }}
    >
        {({ setCollapsed, collapsed }) => (
            store.filterForm
                ? (
                    <>
                        <BillingCommonFilter
                            filterForm={store.filterForm}
                            title="Найти аккаунт"
                            onCollapse={setCollapsed}
                        />
                        {
                            collapsed
                                ? null
                                : <BillingStats />
                        }
                    </>
                )
                : null
        )}
        <div className={styles['page-view__wrapper']}>
            <PageViewExtra>
                <BillingCommonStatusFilter
                    title="Статус"
                    filterForm={store.filterForm}
                    statusField={store.filterForm && findField(store.filterForm.render, 'status')}
                    className={styles['filter-groups']}
                />
            </PageViewExtra>
            {children}
        </div>
    </PageView>
);

BillingUsersListPage.propTypes = {
    store: PropTypes.oneOfType([
        PropTypes.instanceOf(BillingUsersListStore),
        PropTypes.shape({}),
    ]).isRequired,
    meta: PropTypes.shape({
        pageTitle: PropTypes.string,
        icon: PropTypes.func,
        sisterRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
        parentRoutes: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
            }),
        ),
    }),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ])),
    ]).isRequired,
};

BillingUsersListPage.defaultProps = {
    meta: {
        title: null,
        icon: null,
        sisterRoutes: [],
        parentRoutes: [],
    },
};

export default BillingUsersListPage;
