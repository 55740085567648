/* global alert */
import { action, computed, observable } from 'mobx';
import { BillingService } from '../../../services/operator';
import DataSource from '../../shared/DataSource';
import DynamicStructure from '../../shared/DynamicStructure';

class BillingStats extends DynamicStructure {
    @observable $active;

    @observable $expiring;

    @observable $unpaid;

    @observable selected = null;

    constructor() {
        super();
        this.dataSource = new DataSource(BillingService);
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    @computed
    get active() {
        return this.$active;
    }

    @computed
    get expiring() {
        return this.$expiring;
    }

    @computed
    get unpaid() {
        return this.$unpaid;
    }

    load() {
        const { dataSource } = this;
        return dataSource.getStats()
            .then((data) => {
                this.setData(data);
                return Promise.resolve();
            })
            .catch((e) => {
                console.error(e);
                alert(e.message);
            });
    }

    @action
    select(type) {
        this.selected = type;
    }

    @action
    reset() {
        this.selected = null;
    }
}

export default BillingStats;
