import {
    computed,
    extendObservable,
    observable,
    toJS,
} from 'mobx';
import { ListItem } from '../../../shared/List';

class OperatorItem extends ListItem {
    @observable
    $hours;

    constructor(data, parent) {
        super(data, parent);
        for (let i = 9; i < 21; i += 1) {
            extendObservable(this, {
                get [`hour${i}`]() {
                    return this.getHour(i);
                },
            });
        }
    }

    @computed
    get operatorName() {
        const { $operator } = this;
        return $operator;
    }

    @computed
    get plan() {
        const { $plan } = this;
        return $plan || '0';
    }

    @computed
    get done() {
        const { $total, plan } = this;
        const total = $total || 0;
        return {
            value: total.toString(),
            success: total >= plan,
        };
    }

    @computed
    get hourTill9() {
        return this.mergeHours(0, 9);
    }

    @computed
    get hourAfter20() {
        return this.mergeHours(21);
    }

    mergeHours(start, end) {
        const { $hours } = this;
        if (!$hours) {
            return { total: 0, actions: [] };
        }
        const hours = $hours.slice(start, end);
        return hours.reduce((carry, summary) => ({
            total: carry.total + summary.total,
            actions: summary.actions.reduce((prev, { name, count }) => {
                const exists = prev.findIndex(({ name: existingName }) => (
                    existingName === name
                ));
                if (exists > -1) {
                    // eslint-disable-next-line no-param-reassign
                    prev[exists].count += count;
                } else {
                    prev.push({ name, count });
                }
                return prev;
            }, carry.actions),
        }), { total: 0, actions: [] });
    }

    getHour(hour) {
        const { $hours } = this;
        if (!$hours) {
            return { total: 0, actions: [] };
        }
        return toJS($hours[hour]);
    }
}

export default OperatorItem;
