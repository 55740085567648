export default [{
    type: 'fieldset',
    name: 'location',
    fields: [{
        icon: 'search',
        name: 'query',
        type: 'text',
        label: 'Номер или имя',
    }, {
        name: 'isAgent',
        type: 'checkbox',
        size: 'md',
        label: 'Агент',
    }],
}];
