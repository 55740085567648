import { format } from 'date-fns';
import { computed } from 'mobx';
import { ListItem } from '../../../shared/List';
import { DEFAULT_DATE_FORMAT } from '../../../../constants/shared';

class UserItem extends ListItem {
    @computed
    get hardwareName() {
        const { $hardwareName } = this;
        return $hardwareName;
    }

    @computed
    get registeredAt() {
        const { $registeredAt } = this;
        return $registeredAt
            ? format(new Date($registeredAt), `${DEFAULT_DATE_FORMAT}, HH:mm`)
            : null;
    }

    @computed
    get lastLoginAt() {
        const { $lastLoginAt } = this;
        return $lastLoginAt
            ? format(new Date($lastLoginAt), `${DEFAULT_DATE_FORMAT}, HH:mm`)
            : null;
    }

    @computed
    get activationKey() {
        const { $activationKey } = this;
        return $activationKey;
    }

    @computed
    get statusName() {
        const { status } = this;
        if (!status) {
            return null;
        }
        return status.name;
    }
}

export default UserItem;
