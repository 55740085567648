/* global */
import { observable, computed, action } from 'mobx';
import DynamicStructure from '../../shared/DynamicStructure';
import DataSource, { STATES } from '../../shared/DataSource';
import { ObjectService } from '../../../services/operator';
import NotepadObjectList from './NotepadObjectList';
import {
    FlatStrategy,
    HouseStrategy,
    SteadStrategy,
    CommercialStrategy,
} from '../../shared/RefactoredObjectList/strategy';
import {
    withRentStrategy,
    withSaleStrategy,
} from '../../shared/RefactoredObjectList/strategy/mixins';
import {
    CommercialDetailed,
    FlatDetailed,
    HouseDetailed,
    SteadDetailed,
} from '../RefactoredObjectDetailed';
import { withSaleDetailed, withRentDetailed } from '../../shared/RefactoredObjectDetailed/mixins';
import { withBaseDetailed } from '../RefactoredObjectDetailed/mixins';
import { DEAL_TYPE, OBJECT_TYPE } from '../../../constants/shared';

class Notepad extends DynamicStructure {
    @observable $id;

    @observable $activeObjectType = OBJECT_TYPE.FLAT;

    @observable $activeDealType = DEAL_TYPE.SALE;

    @observable $list;

    constructor(id) {
        super();
        this.dataSource = new DataSource(ObjectService, STATES.INITIALLY_LOADING);
        this.setData({ id });
        this.loadList();
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    @computed
    get activeObjectType() {
        const { $activeObjectType } = this;
        return $activeObjectType;
    }

    @computed
    get activeDealType() {
        const { $activeDealType } = this;
        return $activeDealType;
    }

    @computed
    get list() {
        const { $list } = this;
        return $list;
    }

    @action
    setActiveObjectTypeTab(type) {
        this.$activeObjectType = type;
        this.loadList();
    }

    @action
    setActiveDealTypeTab(type) {
        this.$activeDealType = type;
        this.loadList();
    }

    @action
    loadList() {
        const { activeObjectType, activeDealType, id } = this;
        let ObjectTypeStrategy;
        let withDealType;
        switch (activeObjectType) {
            case OBJECT_TYPE.FLAT:
                ObjectTypeStrategy = FlatStrategy;
                break;
            case OBJECT_TYPE.HOUSE:
                ObjectTypeStrategy = HouseStrategy;
                break;
            case OBJECT_TYPE.STEAD:
                ObjectTypeStrategy = SteadStrategy;
                break;
            case OBJECT_TYPE.COMMERCIAL:
                ObjectTypeStrategy = CommercialStrategy;
                break;
            default:
                throw new Error(`Unexpected object type: ${activeObjectType}`);
        }
        switch (activeDealType) {
            case DEAL_TYPE.SALE:
                withDealType = withSaleStrategy;
                break;
            case DEAL_TYPE.RENT:
                withDealType = withRentStrategy;
                break;
            default:
                throw new Error(`Unexpected deal type: ${activeDealType}`);
        }
        const Strategy = withDealType(ObjectTypeStrategy);
        this.$list = new NotepadObjectList(new Strategy(), id);
        this.$list.load();
    }

    createDetailed() {
        const { activeObjectType: objectType, activeDealType: dealType } = this;
        let DetailedObjectType;
        let withDealType;
        switch (objectType) {
            case OBJECT_TYPE.FLAT:
                DetailedObjectType = FlatDetailed;
                break;
            case OBJECT_TYPE.HOUSE:
                DetailedObjectType = HouseDetailed;
                break;
            case OBJECT_TYPE.STEAD:
                DetailedObjectType = SteadDetailed;
                break;
            case OBJECT_TYPE.COMMERCIAL:
                DetailedObjectType = CommercialDetailed;
                break;
            default:
                throw new Error(`Unexpected object type: ${objectType}`);
        }
        switch (dealType) {
            case DEAL_TYPE.RENT:
                withDealType = withRentDetailed;
                break;
            case DEAL_TYPE.SALE:
                withDealType = withSaleDetailed;
                break;
            default:
                throw new Error(`Unexpected deal type: ${dealType}`);
        }
        const Detailed = withBaseDetailed(withDealType(DetailedObjectType));
        return new Detailed();
    }
}

export default Notepad;
