import { TABLE_CELL } from '../../../../constants/operator';

export default [{
    ...TABLE_CELL.OBJECT_TYPE,
    width: 3,
    sortName: null,
    fields: [{
        name: 'ownerTypeIds',
    }],
}, {
    ...TABLE_CELL.STEAD_TYPE_NAME,
    width: 12,
    fields: [{
        name: 'objectSteadTypeIds',
    }],
}, {
    ...TABLE_CELL.SHORT_DISTRICT_NAME,
    width: 5,
    fields: [{
        name: 'cityDistrictIds',
    }],
}, {
    ...TABLE_CELL.STREET_NAME,
    textAlign: 'left',
    width: 14,
    fields: [{
        name: 'streetIds',
    }],
}, {
    ...TABLE_CELL.DISTRICT_LANDMARK_NAME,
    textAlign: 'left',
    width: 10,
    fields: [{
        name: 'cityDistrictLandmarkIds',
    }],
}, {
    ...TABLE_CELL.SQUARE_TOTAL,
    width: 8,
    fields: [{
        name: 'totalAreaFrom',
    }, {
        name: 'totalAreaTo',
    }],
    sortName: null,
}, {
    ...TABLE_CELL.PRICE_DELIMITED,
    width: 6,
}, {
    ...TABLE_CELL.IMAGES,
    width: 4,
    sortName: null,
}, {
    ...TABLE_CELL.CREATED,
    width: 9,
    fields: [{
        name: 'createdAtFrom',
    }, {
        name: 'createdAtTo',
    }],
    sortName: 'createdAt',
}, {
    ...TABLE_CELL.CONTACT_PHONE,
    width: 9,
    sortName: null,
}, {
    ...TABLE_CELL.SOURCE,
    textAlign: 'left',
    width: 7,
    sortName: null,
}, {
    ...TABLE_CELL.OBJECT_STATUS_UPDATE_WITH_CONTACT,
    textAlign: 'left',
    width: 7,
    fields: [{
        name: 'objectStatusIds',
    }],
    sortName: null,
}, {
    ...TABLE_CELL.ROW_SELECT,
    width: 3,
    sortName: null,
}];
