/* global document, alert */
import { computed } from 'mobx';
import editAgency from '../../../../../forms/operator/directory/edit-agency';
import createForm from '../../../../../helpers/Form';
import statusUpdateFields from '../../../../../forms/operator/object-status-update';
import objectUpdateFields from '../../../../../forms/operator/object-update';
import objectMergeFields from '../../../../../forms/operator/object-merge';
import agencySelectorFields from '../../../../../forms/operator/agency-selector';
import { withStatusField } from '../../../../../helpers/Form/mixins';
import { DirectoryService, ObjectService } from '../../../../../services/operator';
import withStatusAndAgencySelector from '../../../../../helpers/Form/mixins/withStatusAndAgencySelector';
import { getDistinctedBy } from '../../../../../utils/arrayUtils';

const withParserItem = (Item) => (
    class extends Item {
        agencyForm;

        agencySelectorForm;

        statusForm;

        objectsEditForm;

        objectsMergeForm;

        @computed
        get objectStatusUpdate() {
            return this.objectStatusValue;
        }

        @computed
        get objectStatusUpdateWithContact() {
            return this.objectStatusValue;
        }

        @computed
        get objectStatusValue() {
            const { $status } = this;
            return {
                value: $status.id,
                text: $status.name,
            };
        }

        @computed
        get objectStatusReasonValue() {
            const { $statusReason } = this;
            if (!$statusReason) return '';
            return {
                value: $statusReason.id,
                text: $statusReason.name,
            };
        }

        @computed
        get objectStatusCommentValue() {
            const { $objectStatusComment } = this;
            return $objectStatusComment || '';
        }

        @computed
        get objectPhones() {
            const { $phones } = this;
            return $phones || '';
        }

        @computed
        get operatorNote() {
            const { $operatorNote } = this;
            return $operatorNote || '';
        }

        /**
         * Получить выбранные объекты, включая текущий
         * @returns {Array<Object>} Все выбранные объекты
         */
        getSelectedItemsWithCurrent() {
            const items = [...(this.parent.selected || [])];
            if (!items.some((item) => item.id === this.id)) {
                items.push(this);
            }

            return items;
        }

        /**
         * Получить выбранные объекты, исключая текущий
         * @returns {Array<Object>} Все выбранные объекты
         */
        getSelectedItemsWithoutCurrent() {
            return [...(this.parent.selected || [])].filter((item) => item.id !== this.id);
        }

        /** Создать форму добавления нового агенства */
        createAgencyCreateForm() {
            const { agencyForm: createdAgencyForm } = this;

            if (createdAgencyForm) {
                return createdAgencyForm;
            }

            this.agencyForm = createForm(editAgency);
            this.initAgencyCreateFormListeners();
            return this.agencyForm;
        }

        /** Создать форму выбора агенства из справочника */
        createAgencySelectorForm() {
            const { agencySelectorForm: createdAgencySelectorForm } = this;

            if (createdAgencySelectorForm) {
                return createdAgencySelectorForm;
            }

            this.agencySelectorForm = createForm(agencySelectorFields);
            return this.agencySelectorForm;
        }

        createStatusUpdateForm() {
            const { statusForm: createdStatusForm } = this;
            if (createdStatusForm) {
                return createdStatusForm;
            }
            this.statusForm = createForm(
                statusUpdateFields,
                [withStatusField({
                    status: 'statusId',
                    reason: 'reasonId',
                })],
            );
            this.setStatusUpdateFormValues();
            this.initStatusUpdateFormListeners();
            return this.statusForm;
        }

        createObjectsEditForm() {
            this.createAgencySelectorForm();

            const {
                objectsEditForm: createdObjectsEditFrom,
                objectStatusValue: { value: statusValue },
                agencySelectorForm,
            } = this;
            if (createdObjectsEditFrom) {
                this.setObjectsEditFormValues();
                return createdObjectsEditFrom;
            }

            this.objectsEditForm = createForm(objectUpdateFields, [
                withStatusAndAgencySelector({
                    fallbackStatusValue: statusValue,
                    agencySelectorForm,
                }),
            ]);
            this.setObjectsEditFormValues();
            this.initObjectsEditFormListeners();

            return this.objectsEditForm;
        }

        createObjectsMergeForm() {
            this.createAgencySelectorForm();

            const {
                objectsMergeForm: createdObjectsMergeFrom,
                objectStatusValue: { value: statusValue },
                agencySelectorForm,
            } = this;
            if (createdObjectsMergeFrom) {
                this.setObjectsMergeFormValues();
                return createdObjectsMergeFrom;
            }

            this.objectsMergeForm = createForm(objectMergeFields, [
                withStatusAndAgencySelector({
                    fallbackStatusValue: statusValue,
                    agencySelectorForm,
                }),
            ]);
            this.setObjectsMergeFormValues();
            this.initObjectsMergeFormListeners();

            return this.objectsMergeForm;
        }

        setStatusUpdateFormValues() {
            const {
                statusForm,
                objectStatusValue: { value: statusValue },
                objectStatusReasonValue: { value: reasonValue },
                objectStatusCommentValue: commentValue,
            } = this;
            statusForm.$('statusId').set(statusValue);
            statusForm.$('reasonId').set(reasonValue);
            statusForm.$('comment').set(commentValue);
        }

        setObjectsEditFormValues() {
            const {
                objectsEditForm,
                objectStatusValue: { value: statusValue },
                objectStatusReasonValue: { value: reasonValue },
                objectStatusCommentValue: commentValue,
                objectPhones,
            } = this;

            objectsEditForm.$('phones').set(objectPhones);
            objectsEditForm.$('phones').isPhonesChanged = false;
            objectsEditForm.$('statusId').set(statusValue);
            objectsEditForm.$('reasonId').set(reasonValue);
            objectsEditForm.$('comment').set(commentValue);
        }

        setObjectsMergeFormValues() {
            const allMergingItemsPhones = getDistinctedBy(
                this.getSelectedItemsWithCurrent()
                    .map((item) => item.objectPhones)
                    .flat(),
                'id',
            );

            const {
                objectsMergeForm,
                objectStatusValue: { value: statusValue },
                objectStatusReasonValue: { value: reasonValue },
                objectStatusCommentValue: commentValue,
            } = this;

            objectsMergeForm.$('phones').set(allMergingItemsPhones);
            objectsMergeForm.$('statusId').set(statusValue);
            objectsMergeForm.$('reasonId').set(reasonValue);
            objectsMergeForm.$('comment').set(commentValue);
        }

        initStatusUpdateFormListeners() {
            const { statusForm, parent } = this;
            statusForm.on('success', async (values) => {
                // Обновление статуса объекта
                const payload = {
                    status: {
                        id: values.statusId,
                        name: '',
                    },
                };

                const itemsToUpdate = this.getSelectedItemsWithCurrent();
                /** Колбэки для выполнения после завершения всех промисов */
                const finalCallbacks = [];
                /* eslint-disable no-await-in-loop */
                /* eslint-disable no-plusplus */
                for (let index = 0; index < itemsToUpdate.length; index++) {
                    try {
                        const item = itemsToUpdate[index];
                        await parent.updateStatus(item.id, statusForm.nullValues(values))
                            .then((response) => finalCallbacks.push(() => item.setData({
                                ...payload,
                                highlight: response.highlight,
                            })));
                    } catch {
                        // Игнорируем ошибки,
                        // чтобы все последующие запросы тоже попробовали выполниться
                    }
                }

                finalCallbacks.forEach((callback) => callback());
                // Очистить выбор стора
                parent.unselectAll();

                // DIRTY UGLY HACK
                document
                    .querySelectorAll('[data-id=tooltip].show')
                    .forEach((tooltip) => {
                        tooltip
                            .classList
                            .remove('show');
                    });
                document
                    .querySelector('header')
                    .click();
                // END OF DIRTY UGLY HACK
                this.statusForm.reset();
            });
        }

        initObjectsEditFormListeners() {
            const { objectsEditForm, parent } = this;

            objectsEditForm.$('phones').observe(() => {
                objectsEditForm.$('phones').isPhonesChanged = true;
            });

            objectsEditForm.on('success', async (values) => {
                const itemsToUpdate = this.getSelectedItemsWithCurrent();
                /** Колбэки для выполнения после завершения всех промисов */
                const finalCallbacks = [];

                if (objectsEditForm.$('phones').isPhonesChanged) {
                    const phonesPayload = {
                        phones: [...values.phones],
                    };
                    /* eslint-disable no-await-in-loop */
                    /* eslint-disable no-plusplus */
                    for (let index = 0; index < itemsToUpdate.length; index++) {
                        try {
                            const item = itemsToUpdate[index];
                            const response = await parent.updatePhones(item.id, phonesPayload);
                            finalCallbacks.push(() => {
                                const { highlight, phones } = response;
                                item.setData({ highlight, phones });
                            });
                        } catch {
                            // Игнорируем ошибки,
                            // чтобы все последующие запросы тоже попробовали выполниться
                        }
                    }
                }

                // Обновление статуса объекта
                const statusPayload = {
                    comment: values.comment,
                    reasonId: values.reasonId,
                    statusId: values.statusId,
                };
                /* eslint-disable no-await-in-loop */
                /* eslint-disable no-plusplus */
                for (let index = 0; index < itemsToUpdate.length; index++) {
                    try {
                        const item = itemsToUpdate[index];
                        const response = await parent
                            .updateStatus(item.id, this.objectsEditForm.nullValues(statusPayload));
                        finalCallbacks.push(() => item.setData({
                            status: {
                                id: values.statusId,
                                name: '',
                            },
                            highlight: response.highlight,
                        }));
                    } catch {
                        // Игнорируем ошибки,
                        // чтобы все последующие запросы тоже попробовали выполниться
                    }
                }

                finalCallbacks.forEach((callback) => callback());
                // Очистить выбор стора
                parent.unselectAll();

                // DIRTY UGLY HACK
                document
                    .querySelectorAll('[data-id=tooltip].show')
                    .forEach((tooltip) => {
                        tooltip
                            .classList
                            .remove('show');
                    });
                document
                    .querySelector('header')
                    .click();
                // END OF DIRTY UGLY HACK

                this.objectsEditForm.reset();
                this.agencySelectorForm.reset();
            });
        }

        initObjectsMergeFormListeners() {
            const { objectsMergeForm, parent } = this;
            objectsMergeForm.on('success', async (values) => {
                // Объединение массива объектов в один
                const itemsIdsToMerge = this
                    .getSelectedItemsWithoutCurrent()
                    .map((item) => item.id);

                const phones = [...values.phones].map((phone) => ({
                    isPrimary: 0,
                    phone: phone.phone,
                    isAgent: phone.isAgent,
                    contact: phone.contact,
                    hide: phone.hide,
                }));
                if (phones.at(0)) {
                    phones.at(0).isPrimary = 1;
                }

                const payload = {
                    sources: itemsIdsToMerge,
                    phones,
                    status: {
                        statusId: values.statusId,
                        reasonId: values.reasonId ? values.reasonId : null,
                        comment: values.comment === ''
                            ? null
                            : values.comment,
                    },
                };

                await parent.merge(this.id, payload);

                // Обновить строки грида
                const allItems = this.getSelectedItemsWithCurrent();
                const allItemIds = allItems.map((item) => item.id);
                const updatePromises = [];
                const getPromises = allItemIds.map((id) => ObjectService.get(id, { objectType: 'flats' }).then((result) => {
                    const preparedResult = {
                        ...result,
                        images: result.images?.map((image) => image.location),
                        cityDistrict: result.cityDistrict?.name,
                        cityDistrictLandmark: result.cityDistrictLandmark?.name,
                        streetName: result.street,
                        house: result.house
                            ? `${result.house.houseType?.shortname} ${result.house.number}`
                            : null,
                    };
                    delete preparedResult.street;

                    const itemToUpdate = allItems.find((item) => item.id === preparedResult.id);
                    updatePromises.push(() => itemToUpdate.setData(preparedResult));
                }));

                Promise.all(getPromises).then(() => {
                    updatePromises.forEach((updateFunction) => updateFunction());

                    // Очистить выбор стора
                    parent.unselectAll();

                    // DIRTY UGLY HACK
                    document
                        .querySelectorAll('[data-id=tooltip].show')
                        .forEach((tooltip) => {
                            tooltip
                                .classList
                                .remove('show');
                        });
                    document
                        .querySelector('header')
                        .click();
                    // END OF DIRTY UGLY HACK

                    this.objectsMergeForm.reset();
                    this.agencySelectorForm.reset();
                });
            });
        }

        initAgencyCreateFormListeners() {
            const { agencyForm } = this;
            agencyForm.on('success', (values) => {
                const { agencyAliases: aliases, ...rest } = values;
                let preparedAliases;
                if (typeof aliases === 'string') {
                    preparedAliases = aliases.split(';');
                    preparedAliases.forEach((alias, i) => {
                        preparedAliases[i] = alias.replace(/\s+/g, ' ').trim();
                    });
                }
                const payload = {
                    aliases: preparedAliases || [],
                    ...rest,
                };

                DirectoryService.create('agency', payload).then((result) => {
                    const newPhone = {
                        contact: result.aliases.map((a) => a.name).join('; '),
                        phone: result.phone,
                        hide: 0,
                        isAgent: 1,
                    };
                    if (this.objectsEditForm) {
                        const objectsEditFormPhones = this.objectsEditForm.$('phones').value;
                        if (!objectsEditFormPhones.some((phone) => phone.phone === result.phone)) {
                            this.objectsEditForm?.$('phones').set(objectsEditFormPhones.concat(newPhone));
                        }
                    }
                    if (this.objectsMergeForm) {
                        const objectsMergeFormPhones = this.objectsMergeForm.$('phones').value;
                        if (!objectsMergeFormPhones.some((phone) => phone.phone === result.phone)) {
                            this.objectsMergeForm?.$('phones').set(objectsMergeFormPhones.concat(newPhone));
                        }
                    }
                }).catch((e) => alert(e));
            });
        }
    }
);

export default withParserItem;
