import React from 'react';
import PropTypes from 'prop-types';
import spawnNotification from '../../../utils/spawn-notification';
import CopyToClipboard from '../../../components/CopyToClipboard';

const ActivationKeyStatusCell = ({
    value,
}) => {
    const notify = () => {
        spawnNotification('Ключ скопирован');
    };

    return (
        <CopyToClipboard
            text={value}
            onCopy={notify}
        >
            <div>{value}</div>
        </CopyToClipboard>
    );
};

ActivationKeyStatusCell.propTypes = {
    value: PropTypes.string.isRequired,
};

export default ActivationKeyStatusCell;
