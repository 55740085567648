import { DIRECTORIES } from '../../../constants/operator';

export default [{
    type: 'fieldset',
    name: 'ownerType',
    fields: [{
        label: 'Тип владельца',
        name: 'ownerTypeIds',
        type: 'directorySelect',
        directory: DIRECTORIES.OWNER_TYPE,
        multiple: true,
        clearable: true,
        isDropdown: true,
    }],
}];
