/* eslint linebreak-style: 0 */
import { DIRECTORIES } from '../../constants/operator';

export default [{
    name: 'agency',
    type: 'directorySearch',
    size: 'md',
    directory: DIRECTORIES.AGENCY,
    label: 'Агенство',
    multiple: false,
    clearable: true,
    searchable: true,
}];
