/* global document */
import React from 'react';
import PropTypes from 'prop-types';
import SVGIcon from '../../../components/Icons/SVGIcon';
import ButtonMenu from '../../../components/Button/ButtonMenu';

import styles from './HeaderTools.module.scss';

const HeaderActions = ({
    actions,
}) => {
    const handleClick = (f) => (e) => {
        document.body.click();
        f(e);
    };
    return (
        <>
            {
                actions.map((action) => (
                    <ButtonMenu
                        className={styles.header__button}
                        onClick={handleClick(action.actionFunction)}
                        key={`HeaderActionsButton--${action.actionText}`}
                        disabled={action.disabled}
                        icon={(
                            <span>
                                <SVGIcon
                                    SVGElement={action.icon}
                                    height={14}
                                />
                            </span>
                        )}
                    >
                        {action.actionText}
                    </ButtonMenu>
                ))
            }
        </>
    );
};

HeaderActions.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            actionFunction: PropTypes.func,
            actionText: PropTypes.string,
        }),
    ),
};

HeaderActions.defaultProps = {
    actions: [],
};

export default HeaderActions;
