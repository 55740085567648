import { computed } from 'mobx';
import BaseItem from './BaseItem';

class ObjectStatusReasonItem extends BaseItem {
    @computed
    get objectStatusName() {
        const { $status: $val } = this;
        return $val
            ? $val.name
            : null;
    }

    @computed
    get objectStatusId() {
        const { $status: $val } = this;
        return $val
            ? { label: $val.name, value: $val.id }
            : '';
    }
}

export default ObjectStatusReasonItem;
