import cloneDeep from 'lodash.clonedeep';
import { ObjectService } from '../../../services/operator';
import { FlatDetailed } from '../../shared/RefactoredObjectDetailed';
import forms from '../../../forms/operator/objects-detailed';
import withSimilarObjects from './mixins/withSimilarObjects';
import { withOperator } from './mixins';

class OperatorFlatDetailed extends FlatDetailed {
    constructor() {
        super(ObjectService);
        const { formName } = this;
        this.fields = cloneDeep(forms[formName]);
    }
}

export default withSimilarObjects(withOperator(OperatorFlatDetailed));
