/* global alert */
import {
    action,
    computed,
    observable,
} from 'mobx';
import { format } from 'date-fns';
import DynamicStructure from '../../shared/DynamicStructure';
import DataSource from '../../shared/DataSource';
import createForm from '../../../helpers/Form';
import { OperatorService } from '../../../services/operator';
import { operatorDetailed as operatorDetailedFields } from '../../../forms/operator';
import forEachField from '../../../forms/utils/for-each-field';
import reduceFields from '../../../forms/utils/reduce-fields';
import isEmpty from '../../../utils/is-empty';
import { DEFAULT_DATE_FORMAT } from '../../../constants/shared';
import { OPERATOR_STATUS } from '../../../constants/operator';

class OperatorDetailed extends DynamicStructure {
    form;

    @observable $approving = false;

    @observable $createdAt;

    @observable $status;

    @observable $rights;

    @observable $attachedCompanies;

    constructor() {
        super();
        this.dataSource = new DataSource(OperatorService);
    }

    @computed
    get loading() {
        const { dataSource } = this;
        return dataSource.loading;
    }

    @computed
    get approving() {
        const { $approving } = this;
        return $approving;
    }

    @computed
    get createdAtDate() {
        const { $createdAt } = this;
        return $createdAt
            ? format(new Date($createdAt), DEFAULT_DATE_FORMAT)
            : null;
    }

    @computed
    get createdAtTime() {
        const { $createdAt } = this;
        return $createdAt
            ? format(new Date($createdAt), 'HH:mm')
            : null;
    }

    @computed
    get status() {
        const { $status, form } = this;
        const { options } = form.$('status').get('extra');
        return options.find(({ value }) => value === $status);
    }

    @computed
    get statusName() {
        const { $status } = this;
        return $status || null;
    }

    @computed
    get rights() {
        const { $rights, $isAdmin } = this;
        if (!$rights) {
            return {};
        }
        return $rights.reduce((carry, ability) => ({
            ...carry,
            [ability.subject]: {
                label: ability.subjectReadable,
                ...Object.keys(ability.rights).reduce((prev, key) => ({
                    ...prev,
                    [key]: $isAdmin ? 1 : Number(ability.rights[key]),
                }), {}),
            },
        }), {});
    }

    @computed
    get attachedCompanies() {
        const { $attachedCompanies = [] } = this;
        return $attachedCompanies.map(({ id }) => id);
    }

    load(id) {
        let promise;
        this.createForm(!id);
        if (id) {
            this.setData({ id });
            promise = this.dataSource.getOperator(id)
                .then((response) => {
                    this.setData(response);
                    return Promise.resolve();
                });
        } else {
            promise = this.dataSource.getRightsScheme()
                .then((response) => {
                    this.setData({ rights: response });
                    return Promise.resolve();
                });
        }
        return promise
            .then(() => (
                this.dataSource.getFreeCompanies()
            ))
            .then((freeCompanies) => {
                const field = this.form.$('attachedCompanies');
                field.set(
                    'extra',
                    {
                        ...field.get('extra'),
                        options: [
                            ...this.$attachedCompanies || [],
                            ...freeCompanies,
                        ].map(({ id: value, name }) => ({
                            value,
                            label: name,
                        })),
                    },
                );
                return Promise.resolve();
            })
            .then(() => {
                this.setFormValues();
                this.setData({ loaded: true });
                return Promise.resolve();
            })
            .catch((e) => {
                console.error(e);
                alert(e.message);
            });
    }

    createForm(isNew, mixins = []) {
        const { form } = this;
        if (form) {
            return form;
        }
        this.form = createForm(
            isNew
                ? reduceFields(operatorDetailedFields, (field) => ({
                    ...field,
                    rules: `${field.rules || ''}|required`,
                }))
                : operatorDetailedFields,
            mixins,
        );
        this.initFormListeners();
        return this.form;
    }

    setFormValues() {
        const { form } = this;
        forEachField(operatorDetailedFields, (field) => {
            if (Object.getOwnPropertyDescriptor(this, field.name)) {
                // eslint-disable-next-line no-param-reassign
                if (!isEmpty(this[field.name])) {
                    form.$(field.name).set('value', this[field.name]);
                }
            }
        });
        form.makeSnapshot();
    }

    initFormListeners() {
        const {
            form,
        } = this;
        form.on('success', (values) => {
            const { id } = this;
            const request = this.prepareRequest(values);
            let promise;
            if (id) {
                promise = this.dataSource.updateOperator(id, request);
            } else {
                promise = this.dataSource.createOperator(request);
            }
            return promise
                .then((response) => {
                    this.setData(response);
                    form.makeSnapshot();
                    return Promise.resolve(response);
                })
                .catch((e) => alert(e));
        });
    }

    prepareRequest({
        password,
        rights,
        status,
        attachedCompanies,
        ...values
    }) {
        const { form } = this;
        const prepared = {
            ...form.nullValues(values),
            status: status.toLowerCase(),
            rights: Object.keys(rights).reduce((carry, key) => {
                const { label, ...ability } = rights[key];
                return {
                    ...carry,
                    [key]: ability,
                };
            }, {}),
            attachedCompaniesIds: attachedCompanies || [],
        };
        if (password) {
            prepared.password = password;
        }
        return prepared;
    }

    @action
    setApprovingState(state = true) {
        const { form } = this;
        if (state === true && form.snapshotIsDiffer) {
            this.$approving = true;
        } else {
            this.$approving = false;
        }
        return !this.$approving;
    }

    setBan() {
        const { form } = this;
        this.setData({ status: OPERATOR_STATUS.BLOCKED });
        form.$('status').set('value', this.status);
        form.makeSnapshot();
    }

    toItem() {
        const {
            $firstName,
            $lastName,
            $patronymic,
            $phone,
            $email,
            $plan,
            $status,
            $access,
        } = this;
        return {
            operator: `${$lastName} ${$firstName} ${$patronymic}`,
            phone: $phone,
            email: $email,
            plan: $plan,
            status: $status,
            access: $access,
        };
    }
}

export default OperatorDetailed;
