import React from 'react';
import { useHistory } from 'react-router-dom';
import { Observer } from 'mobx-react';
import useStores from '../../../hooks/operator/use-stores';
import BillingStatsItem from './BillingStatsItem';

import styles from './BillingStats.module.scss';

const BillingStats = () => {
    const { billingStatsStore } = useStores();
    const history = useHistory();
    const types = Object.entries(BillingStatsItem.TYPE);
    const handleClick = (type) => (e) => {
        if (e) {
            e.preventDefault();
        }
        billingStatsStore.select(type);
        history.push('/billing/companies/list');
    };

    return (
        <div className={styles['billing-stats']}>
            <Observer>
                {() => (
                    types.map(([key, type]) => (
                        <React.Fragment key={`billing-stats-item-${key}`}>
                            <BillingStatsItem
                                type={type}
                                value={billingStatsStore[type.key]}
                                loading={billingStatsStore.loading}
                                onClick={handleClick(type.key)}
                            />
                        </React.Fragment>
                    ))
                )}
            </Observer>
        </div>
    );
};

export default BillingStats;
