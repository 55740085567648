/* global alert */
import { action, computed, observable } from 'mobx';
import { ListWithDataSource } from '../../shared/List';
import DataSource from '../../shared/DataSource';
import Pagination from '../../shared/DataSource/entities/Pagination';
import { DirectoryService } from '../../../services/operator';
import listWithSelect from '../../shared/List/mixins/listWithSelect';
import tableHeaders from './table';

class Directory extends ListWithDataSource {
    @observable editItem;

    constructor(strategy) {
        super(
            undefined,
            new DataSource(DirectoryService),
            new Pagination(),
        );
        this.strategy = strategy;
        this.Item = strategy.Item;
        this.strategy.setParent(this);
        this.createFilterForm();
    }

    @computed
    get tableHeaders() {
        const { strategy: { directoryName } } = this;
        return tableHeaders[directoryName]
            ? tableHeaders[directoryName]
            : tableHeaders.base;
    }

    @computed
    get tableData() {
        return this.list;
    }

    get filterForm() {
        const { strategy } = this;
        return strategy.filterForm;
    }

    get itemForm() {
        const { strategy } = this;
        return strategy.itemForm;
    }

    get directoryName() {
        const { strategy } = this;
        return strategy.directoryName;
    }

    getList(page = 1, ctx) {
        const { directoryName } = this;
        return this.dataSource.getAll(directoryName, page, ctx);
    }

    load(page = 1) {
        const { strategy: { filterData } } = this;
        return super.load(page, filterData)
            .then((list) => {
                this.setList(list);
            })
            .catch((e) => alert(e));
    }

    remove() {
        const {
            directoryName,
            dataSource,
            selected,
            paginationHandler: { currentPage },
        } = this;
        const promises = selected.map(({ id }) => dataSource.remove(directoryName, id));
        return Promise.allSettled(promises)
            .then(() => this.load(currentPage))
            .catch((e) => alert(e));
    }

    create(data) {
        const {
            directoryName,
            dataSource,
            paginationHandler: { currentPage },
        } = this;
        return dataSource.create(directoryName, this.prepareItemData(data))
            .then(() => this.load(currentPage))
            .catch((e) => {
                console.log('e', e);
                if (directoryName === 'street' && e.response?.status === 409) {
                    alert(e.response.data);
                } else {
                    alert(e);
                }
            });
    }

    update(id, data) {
        const {
            directoryName,
            dataSource,
        } = this;
        return dataSource.update(directoryName, id, this.prepareItemData(data))
            .catch((e) => {
                console.log('e', e);
                if (directoryName === 'street' && e.response?.status === 409) {
                    alert(e.response.data);
                } else {
                    alert(e);
                }
            });
    }

    prepareItemData(data) {
        const { strategy } = this;
        return strategy.prepareItemData(data);
    }

    toItem(data) {
        const { strategy } = this;
        return strategy.toItem(data);
    }

    createFilterForm() {
        const { strategy } = this;
        const filterForm = strategy.createFilterForm();
        this.initFilterFormListeners();
        return filterForm;
    }

    createItemForm() {
        const { strategy } = this;
        let { itemForm } = strategy;
        if (!itemForm) {
            itemForm = strategy.createItemForm();
            this.initItemFormListeners();
        }
        return itemForm;
    }

    initFilterFormListeners() {
        const { strategy, filterForm } = this;
        if (!filterForm) return;
        filterForm.on('success', (values) => {
            strategy.setFilterData(filterForm.clearValues(values));
            return this.load();
        });
    }

    initItemFormListeners() {
        const { strategy: { itemForm } } = this;
        if (!itemForm) return;
        itemForm.on('success', (values) => (
            this.create(itemForm.clearValues(values))
        ));
    }

    @action
    setEditItem(item) {
        this.editItem = item;
    }
}

export default listWithSelect(Directory);
